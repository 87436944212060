import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import {
  MainTitle,
  TitleDescription,
} from "../../assets/styles/pages/general/GeneralStyles";

import ModalShare from "../../Components/ModalMessage/ModalShare";
import TacticHome from "../../Components/tactic/TacticHome";
import { getCurrentUser } from "../../utils/auth";
import api from "../../utils/axiosUsage";

import ImgBackgroundPlanning from "../../assets/images/tactics/Background.png";
import ImgBackgroundCulture from "../../assets/images/tactics/Background3.png";
import ImgBackgroundMarketing from "../../assets/images/tactics/Background4.png";
import ImgBackgroundAdmi from "../../assets/images/tactics/Background5.png";
import ImgBackgroundTech from "../../assets/images/tactics/Background2.png";
import Steps from "../../Components/tactic/Steps";
import ModalTacticGeneral from "../../Components/tactic/ModalTacticGeneral";
import "../../assets/styles/pages/tactics/newView-tactic.scss";

const ViewTactic = () => {
  const { id } = useParams();

  const [tacticData, setTacticData] = useState({});
  const [loggedUser, setLoggedUser] = useState({});
  const [pageNotFound, setPageNotFound] = useState(false);
  const [contentLoaded, setContentLoaded] = useState(false);

  const [activeStep, setActiveStep] = useState({
    index: 0,
    info: {},
  });
  // const [showModalShare, setShowModalShare] = useState(false);
  const [showModalShare, setShowModalShare] = useState({
    active: false,
    info: {
      id: "",
      title: "",
      slugUrl: "",
    },
    type: "tactic",
  });

  const [showModalTacticGeneral, setShowModalTacticGeneral] = useState(false);
  const [showSidebarTactic, setShowSidebarTactic] = useState(false);
  const [ratingAndCompleted, setRatingAndCompleted] = useState({
    rating: 0,
    completed: 0,
  });

  useEffect(() => {
    (async () => {
      const userData = await getCurrentUser();
      setLoggedUser(userData);

      const tactic = await api.axiosAuthGet(
        `/cms/api/v2/tactic-and-steps/${id}/`,
        userData.token
      );

      const { data } = await api.axiosAuthGet(
        `/api/v1/profile-crud/${userData.id}/`,
        userData.token
      );

      if (tactic.response && tactic.response.status === 404) {
        setPageNotFound(true);
        setContentLoaded(true);
      } else if (tactic.status === 200) {
        setTacticData(tactic.data);
        setActiveStep({
          index: 0,
          info: tactic.data.steps ? tactic.data.steps[0] : {},
        });
        setPageNotFound(false);
        setContentLoaded(true);
        setLoggedUser({
          ...userData,
          imgUser: data.profile_photo,
          jobPosition: data.job_position,
        });
        getRatingAndCompleted(tactic.data.id);
      }

      // console.log('in view ', tacticData)
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRatingAndCompleted = async (id) => {
    const { data } = await api.axiosPost("/cms/api/v2/tactic-rating", {
      tactic: id,
    });
    setRatingAndCompleted({
      rating: data.rating,
      completed: data.count,
    });
  };

  const imgBackground = (category) => {
    switch (category) {
      case "Estrategia":
        return ImgBackgroundPlanning;
      case "Equipo":
        return ImgBackgroundCulture;
      case "Marketing":
        return ImgBackgroundMarketing;
      case "Finanzas":
        return ImgBackgroundAdmi;
      case "Tecnología":
        return ImgBackgroundTech;
      case "Ventas":
        return ImgBackgroundMarketing;
      default:
        return;
    }
  };

  const renderTacticSteps = () => {
    if (tacticData.steps) {
      const steps = tacticData.steps.map((step, i) => {
        return (
          <Steps
            key={`step-${i}`}
            title={step.title}
            step={i + 1}
            setActiveStep={setActiveStep}
            stepInfo={step}
            showSidebarTactic={showSidebarTactic}
            activeStep={activeStep}
          />
        );
      });
      return steps;
    } else {
      return "";
    }
  };

  return (
    <>
      {pageNotFound ? (
        <Row className="rowLimit rowWidth rowDashboard">
          <Col lg={6}>
            <MainTitle style={{ marginBottom: 50 }}>
              Error
              <TitleDescription>Táctica no encontrada</TitleDescription>
            </MainTitle>
          </Col>
          <Col lg={6}></Col>
        </Row>
      ) : (
        contentLoaded && (
          <>
            <TacticHome
              background={imgBackground(tacticData.category)}
              setShowModalTacticGeneral={setShowModalTacticGeneral}
              tactic={tacticData}
              expert={loggedUser}
              renderTacticSteps={renderTacticSteps}
              activeStep={activeStep}
              showSidebarTactic={showSidebarTactic}
              setShowSidebarTactic={setShowSidebarTactic}
            />
            <ModalTacticGeneral
              background={imgBackground(tacticData.category)}
              tactic={tacticData}
              showModalTacticGeneral={showModalTacticGeneral}
              setShowModalTacticGeneral={setShowModalTacticGeneral}
              setShowModalShare={setShowModalShare}
              expert={loggedUser}
              ratingAndCompleted={ratingAndCompleted}
            />
            <ModalShare
              show={showModalShare.active}
              onHide={() =>
                setShowModalShare({
                  active: false,
                  info: {
                    id: "",
                    title: "",
                    slugUrl: "",
                  },
                })
              }
              title={showModalShare.info.title}
              slug={showModalShare.info.slugUrl}
              type={showModalShare.type}
            />
          </>
        )
      )}
    </>
  );
};

export default ViewTactic;
