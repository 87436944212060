import React from "react";
import { Card, Row, Col } from "react-bootstrap";

import "./steps-styles.css";

const Steps = ({
  title,
  step,
  // status,
  setActiveStep,
  stepInfo,
  showSidebarTactic,
  activeStep,
}) => {
  return (
    <Card
      style={{
        padding: "10px 20px",
        border: activeStep.index + 1 === step && "1px solid #7A4199",
      }}
      onClick={() => setActiveStep({ index: step - 1, info: stepInfo })}
    >
      <Card.Header>
        <Row>
          <Col className="col-11 px-3">
            <div
              className="d-flex align-items-center"
              style={{ height: "100%"}}
            >
              <span className="numberStep">{showSidebarTactic ? `Paso ${step}` : `Paso ${step} - `}</span>
              <span className="numberStep title">{!showSidebarTactic && title}</span>
            </div>
          </Col>
        </Row>
      </Card.Header>
    </Card>
  );
};

export default Steps;
