import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Lodash from "lodash";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import mockData from "../../assets/content/onboardingMock";

import api from "../../utils/axiosUsage";
import logoBgAdd from "../../assets/images/onboarding/logo-bg-add.png";
import { getCurrentUser, setCurrentUser } from "../../utils/auth";

import { BoxOption, TagOption } from "./oboardingStyles";
import { AuthContext } from "../../context/auth/AuthContext";

const FrmMoreExperience = (props) => {
  const history = useHistory();
  const { login } = useContext(AuthContext);
  const {
    institutionName,
    changeStep,
    step,
    editMode,
    profileData,
    setProfileData,
    setAlertMessage,
    setShowAlert,
  } = props;
  const [imgPreview, setImgPreview] = useState(null);
  const [pictureData, setPictureData] = useState(null);
  const [loggedUser, setLoggedUser] = useState({});
  const [currentTagOptions, setCurrentTagOptions] = useState({
    segmentationType: mockData.segmentationType,
    comunicationType: mockData.comunicationType,
  });

  const [isDisabled, setIsDisabled] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  async function getFileFromUrl(url, name, defaultType = "image/jpeg") {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch the file from the URL.");
      }
      const data = await response.blob();
      return new File([data], name, {
        type: data.type || defaultType,
      });
    } catch (error) {
      console.error("An error occurred while fetching the file:", error);
      throw error;
    }
  }

  useEffect(() => {
    (async () => {
      const userData = await getCurrentUser();
      setLoggedUser(userData);

      if (editMode && profileData) {
        const fileName = profileData?.profile_photo
          ? profileData?.profile_photo.split("media/")
          : "";

        if (fileName) {
          const file = await getFileFromUrl(
            profileData?.profile_photo,
            fileName[1]
          );
          setPictureData(file);
        }

        setImgPreview(profileData.profile_photo);
      }
    })();
  }, []);

  const updateProfile = (form) => {
    const job_position = Lodash.get(form, "jobPosition");
    const fileUpload = document.getElementById("file-upload");
    const expert_business_agency_name = Lodash.get(form, "companyName");
    const expert_location = Lodash.get(form, "expertlocation");
    const expert_linkedin = Lodash.get(form, "linkedInProfile");
    const expert_mini_bio = Lodash.get(form, "miniBio");
    const expert_web_page = Lodash.get(form, "webSite");
    const nickname = Lodash.get(form, "nickname");
    const profileDataToUpdate = {
      job_position,
      expert_business_agency_name,
      expert_location,
      expert_linkedin,
      expert_mini_bio,
      expert_web_page,
      profile_photo: pictureData,
      nickname,
      user_url_slug: nickname,
    };

    const profileDataToUpdateWithoutPicture = {
      job_position,
      expert_business_agency_name,
      expert_location,
      expert_linkedin,
      expert_mini_bio,
      expert_web_page,
      nickname,
      user_url_slug: nickname,
    };

    //changeStep(step + 1);

    // const configs = { "content-type": "multipart/form-data" };

    const headers = {
      "Content-Type":
        "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
    };

    let formData = new FormData();
    for (let data in profileDataToUpdate) {
      formData.append(data, profileDataToUpdate[data]);
    }

    if (editMode) {
      setIsProcessing(true);
      setIsDisabled(true);
      api
        .axiosAuthPatch(
          `/api/v1/profile-crud/${loggedUser.id}/`,
          loggedUser.token,
          !fileUpload.files[0] ? profileDataToUpdateWithoutPicture : formData,
          fileUpload.files[0] && headers
        )
        .then((response) => {
          setShowAlert(true);
          if (response.status === 200) {
            const newProfileData = { ...profileData };

            newProfileData.expert_business_agency_name =
              response.data.expert_business_agency_name;
            newProfileData.job_position = response.data.job_position;
            newProfileData.expert_location = response.data.expert_location;
            newProfileData.expert_linkedin = response.data.expert_linkedin;
            newProfileData.expert_mini_bio = response.data.expert_mini_bio;
            newProfileData.expert_web_page = response.data.expert_web_page;
            newProfileData.nickname = response.data.nickname;
            newProfileData.profile_photo = response.data.profile_photo;

            setProfileData(newProfileData);
            loggedUser.userUrlSlug = response.data.user_url_slug;
            setCurrentUser(loggedUser);
            setAlertMessage(
              "✅ La Personalización de cuenta se actualizó correctamente"
            );
            setTimeout(() => {
              setShowAlert(false);
              setAlertMessage("");
              setIsProcessing(false);
              setIsDisabled(false);
            }, 2500);
          } else {
            setAlertMessage(
              "❌ La Personalización de cuenta NO pudo ser actualizado"
            );
            setTimeout(() => {
              setShowAlert(false);
              setAlertMessage("");
              setIsProcessing(false);
              setIsDisabled(false);
            }, 2500);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      api
        .axiosAuthPatch(
          `/api/v1/profile-crud/${loggedUser.id}/`,
          loggedUser.token,
          !fileUpload.files[0] ? profileDataToUpdateWithoutPicture : formData,
          headers
        )
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
            loggedUser.nickname = nickname;
            loggedUser.jobPosition = job_position;
            loggedUser.userUrlSlug = response.data.user_url_slug;
            setCurrentUser(loggedUser);
            history.replace("/home");
            login({
              name: loggedUser.name,
              token: loggedUser.token,
            });
          } else {
            console.log("El perfil no pudo ser actualizado");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      jobPosition: profileData ? profileData.job_position : "",
      companyName: profileData ? profileData.expert_business_agency_name : "",
      expertlocation: profileData ? profileData.expert_location : "",
      linkedInProfile: profileData ? profileData.expert_linkedin : "",
      miniBio: profileData ? profileData.expert_mini_bio : "",
      webSite: profileData ? profileData.expert_web_page : "",
      nickname: profileData ? profileData.nickname : "",
    },
    onSubmit: (values) => {
      updateProfile(values);
    },
    validationSchema: Yup.object({
      miniBio: Yup.string()
        .required("Este campo es obligatorio")
        .max(160, "No debe contener más de 160 caracteres"),
    }),
  });

  const renderDropdown = (formik, data, inputName, label) => {
    const inputOptions = data.map((element, i) => {
      return (
        <option key={`${inputName}_${i}`} value={element.value}>
          {element.description}
        </option>
      );
    });

    return (
      <Form.Group>
        <label htmlFor={inputName}>{label}</label>
        <Form.Control
          as="select"
          className="inputNewBrand main-btn-align"
          id={inputName}
          name={inputName}
          onChange={formik.handleChange}
          value={formik.values[inputName]}
        >
          <option value="">Selecciona</option>
          {inputOptions}
        </Form.Control>
      </Form.Group>
    );
  };

  const changeStatus = (keyData, element, status) => () => {
    const newTagOptions = { ...currentTagOptions };
    newTagOptions[keyData][element].value = status;
    setCurrentTagOptions(newTagOptions);
  };

  const renderTagOptions = (keyData) => {
    const tagOptions = Object.keys(currentTagOptions[keyData]).map(
      (element, i) => {
        return (
          <TagOption
            key={`tag-option-${element}`}
            className={
              currentTagOptions[keyData][element].value ? "selected" : ""
            }
            onClick={changeStatus(
              keyData,
              element,
              !currentTagOptions[keyData][element].value
            )}
          >
            {currentTagOptions[keyData][element].description}
          </TagOption>
        );
      }
    );

    return <>{tagOptions}</>;
  };

  const createImage = (file) => {
    let reader = new FileReader();

    reader.onload = (e) => {
      const preview = e.target.result;
      setImgPreview(preview);
    };

    reader.readAsDataURL(file);
  };

  const onFileChange = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      // return;
      console.log("first");
    }

    const picture = files[0];
    setPictureData(picture);
    createImage(files[0]);
  };

  return (
    <>
      {editMode ? (
        <>
          <h1 className="onTitle">Personalización de cuenta</h1>
        </>
      ) : (
        <>
          <h2 className="onSubTitle">Personaliza tu perfil ✅</h2>
          <h1 className="onTitle">
            Con esta info crearemos tu página pública.
          </h1>
        </>
      )}

      <div className="stepBoxForm">
        <form onSubmit={formik.handleSubmit}>
          <Container fluid className="stepBoxContainer">
            <Row>
              <Col md={6} style={{ paddingLeft: 8, paddingRight: 15 }}>
                <Form.Group>
                  <label>¿Cómo defines tu puesto?</label>
                  <Form.Control
                    id="jobPosition"
                    name="jobPosition"
                    className="inputNewBrand main-btn-align"
                    type="text"
                    placeholder="Puesto"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.jobPosition}
                  />
                </Form.Group>
              </Col>
              <Col md={6} style={{ paddingLeft: 8, paddingRight: 0 }}>
                <Form.Group>
                  <label>Empresa</label>
                  <Form.Control
                    id="companyName"
                    name="companyName"
                    className="inputNewBrand main-btn-align"
                    type="text"
                    placeholder="Nombre Empresa"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyName}
                  />
                </Form.Group>
              </Col>
              {/*
              <Col md={6} style={{ paddingLeft: 8, paddingRight: 0 }}>
                {renderDropdown(
                  formik,
                  mockData.companyArea,
                  "companyArea",
                  "Area en la empresa"
                )}
              </Col>
              */}
            </Row>
            <Row style={{ marginTop: 20, marginBottom: 20 }}>
              <Col md={6} style={{ paddingLeft: 8, paddingRight: 15 }}>
                <Form.Group>
                  <label>Ubicación</label>
                  <Form.Control
                    id="expertlocation"
                    name="expertlocation"
                    className="inputNewBrand main-btn-align"
                    type="text"
                    placeholder="Ubicación Geográfica"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.expertlocation}
                  />
                </Form.Group>
              </Col>
              <Col md={6} style={{ paddingLeft: 8, paddingRight: 0 }}>
                <Form.Group>
                  <label>Perfil de LinkedIn</label>
                  <Form.Control
                    id="linkedInProfile"
                    name="linkedInProfile"
                    className="inputNewBrand main-btn-align"
                    type="text"
                    placeholder="linkedin.com/username"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.linkedInProfile}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col
                md={12}
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Form.Group>
                  <label style={{ width: 165, marginLeft: 15 }}>
                    Foto de perfil
                  </label>
                  <div
                    style={{
                      margin: 15,
                      width: 100,
                      height: 100,
                      display: "inline-block",
                      borderRadius: "50%",
                      border: "dashed 1px #979797",
                      backgroundImage: `url(${
                        imgPreview ? imgPreview : logoBgAdd
                      })`,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                </Form.Group>

                <label
                  htmlFor="file-upload"
                  className="btn-style main-btn-align"
                  style={{
                    width: 200,
                    backgroundColor: "#ECECEC",
                    color: "#232323",
                    justifyContent: "center",
                  }}
                >
                  Seleccionar Archivo
                </label>
                <input
                  id="file-upload"
                  type="file"
                  name="file"
                  onChange={onFileChange}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 20, marginBottom: 20 }}>
              <Col md={6} style={{ paddingLeft: 8, paddingRight: 15 }}>
                <Form.Group>
                  <label>
                    Biografía corta <span>(Máximo 150 caracteres) </span>
                  </label>
                  <Form.Control
                    id="miniBio"
                    name="miniBio"
                    className="inputNewBrand main-btn-align"
                    type="text"
                    placeholder="Ej. Tengo 28 años, trabajo en..."
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.miniBio}
                    maxLength={150}
                  />
                  {formik.touched.miniBio && formik.errors.miniBio && (
                    <div className="frmErrorCategory">
                      {formik.errors.miniBio}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} style={{ paddingLeft: 8, paddingRight: 0 }}>
                <Form.Group>
                  <label>Sitio Web</label>
                  <Form.Control
                    id="webSite"
                    name="webSite"
                    className="inputNewBrand main-btn-align"
                    type="text"
                    placeholder="http://www.miwebsite.com"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.webSite}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
                <Form.Group>
                  <label>Elija un nombre de usuario</label>
                  <Form.Control
                    id="nickname"
                    name="nickname"
                    className="inputNewBrand main-btn-align"
                    type="text"
                    placeholder="Tu nombre de usuario en Logra"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nickname}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>
              <Col md={12} style={{ padding: "30px 0px", paddingLeft: 8 }}>
                <button
                  type="submit"
                  className={isDisabled ? "frmButtonDisabled" : "frmButton"}
                  disabled={isDisabled}
                >
                  {isProcessing ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : !editMode ? (
                    "¡Listo! Terminamos"
                  ) : (
                    "Guardar"
                  )}
                </button>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
    </>
  );
};

export default FrmMoreExperience;
