import React from "react";
import { Modal, Form } from "react-bootstrap";
import { Formik, Field, ErrorMessage, Form as FormFormik } from "formik";
import * as Yup from "yup";
import Close from "../../assets/images/icons/Close.svg";
import "../../assets/styles/pages/tactics/modal-step.scss";
import TooltipCustom from "../Atoms/TooltipCustom";

const ModalStep = ({
  showModalTool,
  setShowModalTool,
  setTools,
  tools,
  isEditingTool,
  totalTools,
  setTotalTools,
  setIsEditingTool,
}) => {
  const createTool = (values) => {
    if (isEditingTool.active) {
      filterId(values);
    } else {
      const dataTool = {
        title: values.titleTool,
        tool_type: values.typeTool,
        url: values.urlTool,
        idFront: totalTools,
        // file: values.fileTool,
      };
      setTools([...tools, dataTool]);
    }
    setShowModalTool(false);
    setTotalTools((prev) => prev + 1);
  };

  const filterId = (values) => {
    if (values.id !== null) {
      tools.map((tool) => {
        if (tool.id === values.id) {
          tool.title = values.titleTool;
          tool.tool_type = values.typeTool;
          tool.url = values.urlTool;
          // tool.file = values.fileTool;
        }
        return tool;
      });
    } else {
      tools.map((tool) => {
        if (tool.idFront === values.idFront) {
          tool.title = values.titleTool;
          tool.tool_type = values.typeTool;
          tool.idFront = values.idFront;
          tool.url = values.urlTool;
          // tool.file = values.fileTool;
        }
        return tool;
      });
    }
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModalTool}
    >
      <Modal.Body
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "40px",
        }}
      >
        <img
          src={Close}
          alt="Cerrar"
          className="modalStep-close"
          onClick={() => {
            setShowModalTool(false);
            setIsEditingTool({ active: false, tool: {} });
          }}
        />
        <Formik
          initialValues={{
            titleTool: isEditingTool.tool.title || "",
            typeTool: isEditingTool.tool.tool_type || "",
            urlTool: isEditingTool.tool.url || "",
            fileTool: isEditingTool.tool.file || "",
            idFront: isEditingTool.tool.idFront || 1000,
            id: isEditingTool.tool.id || null,
          }}
          onSubmit={(values) => {
            createTool(values);
          }}
          validationSchema={Yup.object({
            titleTool: Yup.string().required("Este campo es obligatorio"),
            typeTool: Yup.string().required("Este campo es obligatorio"),
            urlTool: Yup.string().max(
              255,
              "Máximo 255 caracteres, te sugerimos utilizar un acortador de URL."
            ),
          })}
        >
          {(formik) => (
            <FormFormik className="modalStep-form">
              <p className="modalStep-title">Agregar video, archivo o url</p>
              <Form.Group>
                <label>Escoge un título</label>
                <Field
                  name="titleTool"
                  type="text"
                  className="inputNewBrand main-btn-align form-control"
                  placeholder="Cómo lo defines"
                />
                <ErrorMessage
                  name="titleTool"
                  component="div"
                  className="frmErrorCategory"
                />
              </Form.Group>
              <Form.Group>
                <label>¿Cuál es el tipo?</label>
                <Field
                  name="typeTool"
                  as="select"
                  className="inputNewBrand main-btn-align form-control"
                >
                  <option value="">Selecciona</option>
                  <option value="Herramienta">Herramienta</option>
                  <option value="Árticulo">Árticulo</option>
                  <option value="Playbook">Playbook</option>
                  <option value="Otro">Otro</option>
                </Field>
                <ErrorMessage
                  name="typeTool"
                  component="div"
                  className="frmErrorCategory"
                />
              </Form.Group>
              <hr />
              <Form.Group>
                <label>Si es un url</label>
                <Field
                  name="urlTool"
                  type="text"
                  className="inputNewBrand main-btn-align form-control"
                  placeholder="Copia la liga aquí"
                />
                <ErrorMessage
                  name="urlTool"
                  component="div"
                  className="frmErrorCategory"
                />
              </Form.Group>
              <Form.Group>
                <label>Si es un archivo</label>
                {/* until the patch works */}
                {/* {renderHaveFile()} */}
                <TooltipCustom>
                  <div>
                    <input
                      type="file"
                      name="fileTool"
                      className="main-btn-align form-control modalStep-file"
                      onChange={(e) =>
                        formik.setFieldValue("fileTool", e.target.files[0])
                      }
                      disabled
                    />
                  </div>
                </TooltipCustom>

                <ErrorMessage
                  name="fileTool"
                  component="div"
                  className="frmErrorCategory"
                />
              </Form.Group>
              <button type="submit" className="btn-addStep-style">
                Guardar
              </button>
            </FormFormik>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ModalStep;
