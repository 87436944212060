import React from "react";

export const MenuResource = ({ stage, formik }) => {
  const renderTitle = () => {
    if (stage === 1) {
      return (
        <>
          <p className="resource-part">Parte 1.</p>
          <p className="resource-partMsg">
            Datos generales del recurso que estás indexando.
          </p>
        </>
      );
    } else {
      return (
        <>
          <p className="resource-part">Parte 2.</p>
          <p className="resource-partMsg">
            Organiza tu recurso por área y reto de crecimiento.
          </p>
        </>
      );
    }
  };

  const actions = () => {
    formik.setFieldValue("action", "save");
  };
  return (
    <div className="container-resourceMenu-main">
      {renderTitle()}
      <div className="resource-section">
        <div className={`resource-sectionPart ${stage === 1 && "active"}`}>
          <p>Agrega un recurso</p>
        </div>
        <div className={`resource-sectionPart ${stage === 2 && "active"}`}>
          <p>Organízalo por área y reto</p>
        </div>
      </div>
      <div className="resource-buttons">
        <button type="submit" className="resource-btnNext" onClick={actions}>
          {stage === 1 ? "Siguiente" : "¡Listo!"}
        </button>
      </div>
    </div>
  );
};
