import React from "react";

export const LogradorLocation = ({ locationLogrador }) => {
  return (
    <div className="logrador-location">
      <h6>Cómo te vas a volver un Logrador 💪</h6>
      <hr />
      <div
        className={`logrador-eachSection ${locationLogrador === 1 && "active"}`}
      >
        <p>Nuestra misión común</p>
      </div>
      <div
        className={`logrador-eachSection ${locationLogrador === 2 && "active"}`}
      >
        <p>Resolver retos de negocio</p>
      </div>
      <div
        className={`logrador-eachSection ${locationLogrador === 3 && "active"}`}
      >
        <p>Medir tu impacto</p>
      </div>
      <div
        className={`logrador-eachSection ${locationLogrador === 4 && "active"}`}
      >
        <p>Niveles de Logrador</p>
      </div>
    </div>
  );
};
