import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Col } from "react-bootstrap";

import "../../assets/styles/pages/design-app.scss";
import "../../assets/styles/pages/index.scss";

import step1 from "../../assets/images/onboarding/step-1.svg";
import step2 from "../../assets/images/onboarding/step-2.svg";
import step3 from "../../assets/images/onboarding/step-3.svg";
import checked from "../../assets/images/onboarding/checked.svg";
import tick from "../../assets/images/onboarding/tick.svg";
import reverse_tick from "../../assets/images/onboarding/reversetick.svg";
import helper_logo from "../../assets/images/onboarding/helper_logo.svg";

import FrmAccount from "./FrmAccount";
import FrmAboutExperience from "./FrmAboutExperience";
import FrmMoreExperience from "./FrmMoreExperience";
import HelperBox from "../../Components/HelperModal/HelperBox";

const step_two_list = [
  "Mejor empieza con pocas y crece",
  "Cada categoría incluye retos de negocio",
  "Tu meta es ser el mejor experto en cada reto",
];
const step_three_list = [
  "Sé claro, directo al punto, con cifras",
  "Incluye palabras claves impactantes",
  "Habilidades únicas que te diferencian",
];
const example_list_one = [
  "• +10 años de experiencia en fusión y acquisición.",
  "• Ayudé +200 PyMEs en su transformación cultural.",
];

const secondHelperProps = {
  helperTitle: "Elige sólo las categorías que dominas",
  state: false,
  titleExample: "Algunos ejemplos:",
  list: step_two_list,
  listExample: example_list_one,
  dropdown_close: tick,
  dropdown_open: reverse_tick,
  BulbTitle: helper_logo,
  checklist: checked,
};

const thirdHelperProps = {
  helperTitle: "Haz una buena frase de presentación",
  state: true,
  titleExample: "Algunos ejemplos:",
  list: step_three_list,
  listExample: example_list_one,
  dropdown_close: tick,
  dropdown_open: reverse_tick,
  BulbTitle: helper_logo,
  checklist: checked,
};

const OnboardingSteps = () => {
  const [step, setStep] = useState(1);
  const location = useLocation();
  const history = useHistory();

  const [userEmail = ""] = useState(location.state?.userEmail);
  const [personName, setPersonName] = useState("");
  const [institutionName, setInstitutionName] = useState("");

  useEffect(() => {
    if (!userEmail) {
      history.replace("/start");
    }
  }, []);

  const changeStep = (val) => {
    setStep(val);
  };

  const changePersonName = (val) => {
    setPersonName(val);
  };

  const changeInstitutionName = (val) => {
    setInstitutionName(val);
  };

  const steps = [
    {
      id: 1,
      description: "Creación de cuenta",
      img: step1,
      frm: (
        <FrmAccount
          userEmail={userEmail}
          step={step}
          changeStep={changeStep}
          changePersonName={changePersonName}
        />
      ),
    },
    {
      id: 2,
      description: "Perfil de experto",
      img: step2,
      frm: (
        <FrmAboutExperience
          personName={personName}
          step={step}
          changeStep={changeStep}
          changeInstitutionName={changeInstitutionName}
        />
      ),
    },
    {
      id: 3,
      description: "Personalización básica",
      img: step3,
      frm: (
        <FrmMoreExperience
          institutionName={institutionName}
          step={step}
          changeStep={changeStep}
        />
      ),
    },
  ];

  const renderMenuOptions = () => {
    const menu = Object.values(steps).map((opt) => {
      return (
        <li key={`menu${opt.id}`} className={step === opt.id ? "active" : ""}>
          {opt.description}
        </li>
      );
    });
    return menu;
  };

  function renderSwitch(step) {
    switch (step) {
      case 1:
        return null;
      case 2:
        return <HelperBox {...secondHelperProps} />;
      case 3:
        return <HelperBox {...thirdHelperProps} />;
      default:
        return undefined;
    }
  }
  return (
    <Container fluid className="stepPage-container">
      <Col lg={12} className="stepPage-content">
        <img
          src="/images/logos/logo.svg"
          alt="logo"
          style={{ marginLeft: "30px", marginBottom: "100px" }}
        />
        <div>
          <img
            src={steps[step - 1].img}
            alt="step"
            style={{ marginLeft: "30px" }}
          />
        </div>
        {steps[step - 1].frm}
      </Col>
      <div className="colStepsDescription">
        <h3>Crea tu cuenta en 3 pasos:</h3>
        <p>Nos ayudará a crear la mejor experiencia ✨</p>
        <ul>{renderMenuOptions()}</ul>
        <div style={{ paddingTop: "60px" }}>{renderSwitch(step)}</div>
      </div>
    </Container>
  );
};

export default OnboardingSteps;
