import styled from "styled-components";

export const BoxOption = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 24px;
  .selected {
    background: #8945ad;
    color: #fff;
  }
`;

export const BoxRadioOptions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-bottom: 35px;
  justify-content: space-between;
  .selected {
    background: #2c5999;
    color: #fff;
  }
`;

export const BoxRadioOptions2 = styled.div`
  display: flex;
  flex-direction: column;
  /*flex-wrap: wrap;*/
  margin-top: 24px;
  margin-bottom: 35px;
  /*justify-content: space-between;*/
  .optExpert {
    margin-bottom: 20px;
  }
  .selected {
    background: #2c5999;
    color: #fff;
  }
`;

export const TagOption = styled.a`
  background: #fff;
  height: 42px;
  color: #000;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  padding: 13px 20px;
  text-align: center;
  border: solid 1px #d8dbe6;
  border-radius: 20px;
  margin-right: 15px;
  margin-bottom: 5px;
  :hover {
    background: #8945ad;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
`;

export const RadioOption = styled.a`
  background: #d6d8e7;
  height: 42px;
  color: #000;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  padding: 10px 12px;
  text-align: center;
  border: solid 1px #d8dbe6;
  border-radius: 20px;
  margin-right: 15px;
  margin-bottom: 15px;
  :hover {
    background: #2c5999;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
`;

export const RadioOption2 = styled.a`
  background: #d6d8e7;
  height: 42px;
  color: #000;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  padding: 3px 4px;
  text-align: center;
  border: solid 1px #d8dbe6;
  border-radius: 20px;
  margin-right: 15px;
  margin-bottom: 15px;
  :hover {
    background: #2c5999;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
`;

export const Box = styled.div`
  background-color: #ffff;
`;
