const isBrowser = typeof window !== `undefined`;

const getUser = () =>
  window.localStorage.userLogged
    ? JSON.parse(window.localStorage.userLogged)
    : {};

const setUser = (user) =>
  (window.localStorage.userLogged = JSON.stringify(user));

export const getCompany = () =>
  window.localStorage.company ? JSON.parse(window.localStorage.company) : {};

export const setCompany = (company) =>
  (window.localStorage.company = JSON.stringify(company));

export const handleLogin = (userData) => {
  if (!isBrowser) return false;

  if (
    userData !== undefined &&
    userData !== null &&
    userData.data !== undefined
  ) {
    return setUser({
      id: userData.data.id,
      name: userData.data.name,
      lastname: userData.data.first_lastname,
      fullName: userData.data.name + " " + userData.data.first_lastname,
      email: userData.data.email,
      token: userData.data.user_token,
      companyId: userData.data.companyId,
      nickname: userData.data.nickname,
      jobPosition: userData.data.jobPosition,
      businessArea: userData.data.businessArea,
      userUrlSlug: userData.data.userUrlSlug,
      /* new */
      refreshToken: userData.data.refreshToken,
    });
  }

  return false;
};

export const isLoggedIn = () => {
  if (!isBrowser) return false;
  const user = getUser();
  return !!user.email;
};

export const getCurrentUser = () => isBrowser && getUser();
export const setCurrentUser = (user) => isBrowser && setUser(user);

export const logout = (callback) => {
  if (!isBrowser) return;

  console.log(`Ensuring the \`userLogged\` property exists.`);
  localStorage.removeItem("userLogged");
  localStorage.removeItem("company");
  localStorage.removeItem("isAuthenticaded");
};
