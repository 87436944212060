import React from "react";
import "../../assets/styles/slider/startSlider.scss";
import Slider from "infinite-react-carousel";
import sliderImage1 from "../../assets/images/slider/slider-img-1.png";
import sliderImage2 from "../../assets/images/slider/slider-img-2.png";
import sliderImage3 from "../../assets/images/slider/slider-img-3.png";

const StartSlider = () => {
  const settings = {
    arrows: false,
    dots: true,
    duration: 10,
    shift: 100,
    autoplaySpeed: 4000,
    dotsClass: "custom-dots",
    autoplay: true,
    className: "mainSliderContainer",
  };
  return (
    <div>
      <Slider {...settings}>
        <div>
          <div className="boxSliderImage">
            <img src={sliderImage1} alt="slider-img-1" className="responsive" />
          </div>
          <div className="boxSliderParagraph">
            <p className="slider-paragraph-1">
              Conviertete en un asesor virtual
            </p>
            <p className="slider-paragraph-2">
              Guia a emprendedores a acelerar el crecimiento de su empresa.
            </p>
            <p className="slider-paragraph-3">
              Ayudarás a implementar acciones que logren que sus negocios
              obtengan resultados rápidos
            </p>
          </div>
        </div>
        <div>
          <div className="boxSliderImage">
            <img src={sliderImage2} alt="slider-img-2" />
          </div>
          <div className="boxSliderParagraph">
            <p className="slider-paragraph-1">
              Conviertete en un asesor virtual
            </p>
            <p className="slider-paragraph-2">
              Guia a emprendedores a acelerar el crecimiento de su empresa.
            </p>
            <p className="slider-paragraph-3">
              Ayudarás a implementar acciones que logren que sus negocios
              obtengan resultados rápidos
            </p>
          </div>
        </div>
        <div>
          <div className="boxSliderImage">
            <img src={sliderImage3} alt="slider-img-3" />
          </div>
          <div className="boxSliderParagraph">
            <p className="slider-paragraph-1">
              Conviertete en un asesor virtual
            </p>
            <p className="slider-paragraph-2">
              Guia a emprendedores a acelerar el crecimiento de su empresa.
            </p>
            <p className="slider-paragraph-3">
              Ayudarás a implementar acciones que logren que sus negocios
              obtengan resultados rápidos
            </p>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default StartSlider;
