import mixpanel from "mixpanel-browser";
// import LogRocket from "logrocket";
// import setupLogRocketReact from "logrocket-react";
import { useEffect } from "react";
// import Layout from "./Components/Layout/Layout";
import { gapi } from "gapi-script";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RouterMain } from "./Routes/RouterMain";
import { AuthProvider } from "./context/auth/AuthProvider";

// LogRocket.init("rjj0nv/expertos-mtqd7");
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });
// setupLogRocketReact(LogRocket);

function App() {
  useEffect(() => {
    const start = () => {
      gapi.auth2.init({
        clientId: process.env.REACT_APP_CLIENT_ID,
        scope: "",
      });
    };
    gapi.load("client:auth2", start);
  }, []);
  return (
    <>
      <AuthProvider>
        <RouterMain />
        <ToastContainer />
      </AuthProvider>
    </>
  );
}

export default App;
