import React from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { Main } from "../Components/Atoms/Main";
import SideNavMenu from "../Components/SideNavMenu/SideNavMenu";
import { useEffect } from "react";
import { AuthContext } from "../context/auth/AuthContext";
import { useContext } from "react";
import { getCurrentUser, logout } from "../utils/auth";
import { validTokenTime } from "../utils/token";

export const PrivateRoutes = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => {
  const history = useHistory();
  const { logout: logoutContext } = useContext(AuthContext);

  useEffect(() => {
    const unlisten = history.listen(() => {
      const user = getCurrentUser();
      if (!user.token || !user.refreshToken) {
        logout();
        return;
      } else {
        verifyToken();
      }
    });

    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const verifyToken = async () => {
    const user = getCurrentUser();

    const isValidToken = await validTokenTime(user.token, user.refreshToken);
    if (!isValidToken) {
      logoutContext();
      logout();
      window.localStorage.isAuthenticaded = JSON.stringify(false);
      localStorage.clear();
    }
  };

  return (
    <Route
      {...rest}
      component={(props) =>
        isAuthenticated ? (
          <div className="main-content">
            <Main expanded={true} style={{ marginLeft: 0, height: "100vh" }}>
              <SideNavMenu />
              <Component {...props} />
            </Main>
          </div>
        ) : (
          <Redirect to="/start" />
        )
      }
    />
  );
};
