import React from "react";

const stages = [
  {
    id: 1,
    title: "Parte 1.",
    slugMenu: "Datos generales",
    description: "Datos generales de tu táctica.",
  },
  {
    id: 2,
    title: "Parte 2.",
    slugMenu: "Paso a paso",
    description:
      "Crea el paso a paso para llevar a cabo tu táctica con las acciones necesarias.",
  },
  // {
  //   id: 3,
  //   title: "Parte 3.",
  //   slugMenu: "Match ideal para tu táctica",
  //   description:
  //     "Configura el perfil del usuario ideal a quien crees que debemos recomendar esta táctica.",
  // },
];

const StepsContainer = ({ stage, formik, setOtherStep }) => {
  const renderMenuOptions = () => {
    const menu = Object.values(stages).map((opt) => {
      return (
        <li key={`menu${opt.id}`} className={stage === opt.id ? "active" : ""}>
          {opt.slugMenu}
        </li>
      );
    });
    return menu;
  };

  const messageBtn = (stage) => {
    switch (stage) {
      case 1:
        return "Continuar";
      case 2:
        return "Completa y publica tu táctica";
      // case 3:
      //   return "Completa y publica tu táctica";
      default:
        return "";
    }
  };

  return (
    <div className="colStepsTactics">
      <h2>{stages[stage - 1].title}</h2>
      <p>{stages[stage - 1].description}</p>
      <ul style={{ marginBottom: "30px" }}>{renderMenuOptions()}</ul>
      <button
        type="submit"
        className="btn-tactic-style"
        style={{ border: "none" }}
        name="saveComplete"
        onClick={() => {
          if (setOtherStep) {
            setOtherStep(false);
          }
          formik.setFieldValue("action", "saveComplete");
        }}
      >
        {messageBtn(stage)}
      </button>
    </div>
  );
};

export default StepsContainer;
