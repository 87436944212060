import React, { useState } from "react";
import {
  Formik,
  Field,
  ErrorMessage,
  Form as FormFormik,
  useFormikContext,
} from "formik";
import { Row, Col, Form } from "react-bootstrap";
import * as Yup from "yup";
import { TypeResource } from "./TypeResource";
import { OwnResource } from "./OwnResource";
import { MenuResource } from "./MenuResource";

import api from "../../utils/axiosUsage";
import { useEffect } from "react";
import { Button, Loading } from "@logra/component-library";
import "../Styles/LogoPickerModal.scss";
import CloseIcon from "../../assets/images/icons/Close.svg";

import ArgentinaFlag from "../../assets/images/countries/argentina.png";
import BoliviaFlag from "../../assets/images/countries/bolivia.png";
import ChileFlag from "../../assets/images/countries/chile.png";
import ColombiaFlag from "../../assets/images/countries/colombia.png";
import CostaRicaFlag from "../../assets/images/countries/costaRica.png";
import EcuadorFlag from "../../assets/images/countries/ecuador.png";
import SalvadorFlag from "../../assets/images/countries/salvador.png";
import GuatemalaFlag from "../../assets/images/countries/guatemala.png";
import HondurasFlag from "../../assets/images/countries/honduras.png";
import MexicoFlag from "../../assets/images/countries/mexico.png";
import NicaraguaFlag from "../../assets/images/countries/nicaragua.png";
import ParaguayFlag from "../../assets/images/countries/paraguay.png";
import PanamaFlag from "../../assets/images/countries/panama.png";
import PeruFlag from "../../assets/images/countries/peru.png";
import DominicanaFlag from "../../assets/images/countries/dominicana.png";
import UruguayFlag from "../../assets/images/countries/uruguay.png";
import VenezuelaFlag from "../../assets/images/countries/venezuela.png";
import UsaFlag from "../../assets/images/countries/usa.png";
import CanadaFlag from "../../assets/images/countries/canada.png";
import ImageIcon from "../../assets/images/icons/image.png";
import LogoPlaceholder from "../../assets/images/icons/tool_logo_placeholder.svg";
//missing usa flag and canada one
import { TextRich } from "../tactic/TextRich";
//tinymce
import { Editor } from "@tinymce/tinymce-react";
import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/lists";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/autoresize";
import { useRef } from "react";

const Stage1Resource = ({
  loggedUser,
  stage,
  setStage,
  setIdContent,
  contentResource,
  isEditing,
}) => {
  const [type, setType] = useState(
    contentResource?.resource_type || "Post de Linkedin"
  );

  const [ownResource, setOwnResource] = useState(
    contentResource?.is_my_own_content || false
  );
  const selectedLogoRef = useRef(null);

  const [isTool, setIsTool] = useState(false);
  const [showLogoPicker, setShowLogoPicker] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState(
    contentResource.tool_logo || ""
  );
  const [selectedLogoFile, setSelectedLogoFile] = useState("");
  const [logoArr, setLogoArr] = useState([]);
  const [logoUrl, setLogoUrl] = useState("");
  const [resourceUrl, setResourceUrl] = useState();
  const [isAllCountries, setIsAllCountries] = useState(false);
  const [showErrorCountry, setShowErrorCountry] = useState(false);
  const [licensePrice, setLicensePrice] = useState(
    contentResource.license_price || ""
  );
  const [hasToolUrl, setHasToolUrl] = useState("");
  const [logoResults, setLogoResults] = useState(true);
  const [logoImageData, setLogoImageData] = useState("");
  const [imgPreview, setImgPreview] = useState(null);

  const [countryArg, setCountryArg] = useState(
    contentResource.country_region_argentina || false
  );
  const [countryBol, setCountryBol] = useState(
    contentResource.country_region_bolivia || false
  );
  const [countryChile, setCountryChile] = useState(
    contentResource.country_region_chile || false
  );
  const [countryColombia, setCountryColombia] = useState(
    contentResource.country_region_colombia || false
  );
  const [countryCRica, setCountryCRica] = useState(
    contentResource.country_region_costa_rica || false
  );
  const [countryEcua, setCountryEcua] = useState(
    contentResource.country_region_ecuador || false
  );
  const [countrySalv, setCountrySalv] = useState(
    contentResource.country_region_el_salvador || false
  );
  const [countryGuate, setCountryGuate] = useState(
    contentResource.country_region_guatemala || false
  );
  const [countryHond, setCountryHond] = useState(
    contentResource.country_region_honduras || false
  );
  const [countryMex, setCountryMex] = useState(
    contentResource.country_region_mexico || false
  );
  const [countryNica, setCountryNica] = useState(
    contentResource.country_region_nicaragua || false
  );
  const [countryParag, setCountryParag] = useState(
    contentResource.country_region_paraguay || false
  );
  const [countryPanama, setCountryPanama] = useState(
    contentResource.country_region_panama || false
  );
  const [countryPeru, setCountryPeru] = useState(
    contentResource.country_region_peru || false
  );
  const [countryRepDom, setCountryRepDom] = useState(
    contentResource.country_region_rep_dominicana || false
  );
  const [countryUrug, setCountryUrug] = useState(
    contentResource.country_region_uruguay || false
  );
  const [countryVenez, setCountryVenez] = useState(
    contentResource.country_region_venezuela || false
  );
  const [countryUsa, setCountryUsa] = useState(
    contentResource.country_region_usa || false
  );
  const [countryCanada, setCountryCanada] = useState(
    contentResource.country_region_canada || false
  );

  const allCountries = () => {
    if (!isAllCountries) {
      setIsAllCountries(true);
      setCountryArg(true);
      setCountryBol(true);
      setCountryChile(true);
      setCountryColombia(true);
      setCountryCRica(true);
      setCountryEcua(true);
      setCountrySalv(true);
      setCountryGuate(true);
      setCountryHond(true);
      setCountryMex(true);
      setCountryNica(true);
      setCountryParag(true);
      setCountryPanama(true);
      setCountryPeru(true);
      setCountryRepDom(true);
      setCountryUrug(true);
      setCountryVenez(true);
    } else {
      setIsAllCountries(false);
      setCountryArg(false);
      setCountryBol(false);
      setCountryChile(false);
      setCountryColombia(false);
      setCountryCRica(false);
      setCountryEcua(false);
      setCountrySalv(false);
      setCountryGuate(false);
      setCountryHond(false);
      setCountryMex(false);
      setCountryNica(false);
      setCountryParag(false);
      setCountryPanama(false);
      setCountryPeru(false);
      setCountryRepDom(false);
      setCountryUrug(false);
      setCountryVenez(false);
    }
  };

  useEffect(() => {
    if (type === "Website/Herramienta") {
      setIsTool(true);
    } else {
      setIsTool(false);
    }
  }, [type]);

  const onCreateContent = async (formValues, resetForm) => {
    const formData = {
      description: formValues.description,
      is_active: false,
      is_draft: true,
      is_my_own_content: ownResource,
      language: formValues.language,
      objective: formValues.objective,
      owner: loggedUser.id,
      //tool stuff
      resource_type: type,
      title: formValues.title,
      url: formValues.link,
      //tool_logo_url: selectedLogo,
      //in case a logo cant be found by using the url crawler
      //was logoImageData before
      tool_logo: selectedLogo,
      //tool_logo: formValues.tool_logo,
      tool_creator: formValues.tool_creator,
      license_price: licensePrice,
      //license_price: formValues.license_price,
      country_region_argentina: countryArg,
      country_region_bolivia: countryBol,
      country_region_chile: countryChile,
      country_region_colombia: countryColombia,
      country_region_costa_rica: countryCRica,
      country_region_ecuador: countryEcua,
      country_region_el_salvador: countrySalv,
      country_region_guatemala: countryGuate,
      country_region_honduras: countryHond,
      country_region_mexico: countryMex,
      country_region_nicaragua: countryNica,
      country_region_paraguay: countryParag,
      country_region_panama: countryPanama,
      country_region_peru: countryPeru,
      country_region_rep_dominicana: countryRepDom,
      country_region_uruguay: countryUrug,
      country_region_venezuela: countryVenez,
      country_region_usa: countryUsa,
      country_region_canada: countryCanada,
    };
    if (!isEditing) {
      try {
        //const configs = { "content-type": "multipart/form-data" };

        //console.log('before sending the fdata', logoImageData, 'has something and this does too', formData)
        //fr = new FileReader()
        //fr

        //let fd = new FormData();
        //console.log('what is this now?', logoImageData)
        //const picture = files[0];
        let oldData = formData;

        console.log("prev form data", oldData);

        //fd.append('tool_logo', logoImageData)

        // for (let data in formData) {
        //   console.log('form data', formData[data])
        //   fd.append(data, formData[data]);
        // }

        const { data } = await api.axiosAuthPost(
          "/cms/api/v2/indexed-content-crud/",
          `${loggedUser.token}`,
          formData
        );
        //fd,
        //configs,
        console.log("form data? ", formData);
        console.log("data id? ", data);
        setIdContent(data.id);
        setStage(2);
        resetForm();
      } catch (error) {
        console.log(error);
      }
    } else {
      const { tool_logo, ...resourceRest } = formData;
      try {
        const { data } = await api.axiosAuthPatch(
          `/cms/api/v2/indexed-content-crud/${contentResource.id}/`,
          loggedUser.token,
          resourceRest
        );

        console.log("data.id?", data);
        setIdContent(data.id);
        setStage(2);
        resetForm();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const toBase64 = (file, cb) => {};

  const validateUrlForUtm = (value) => {
    let error;
    if (value.includes("utm")) {
      error = "url no valido, contiene etiquetas UTM";
    }
    return error;
  };

  //this

  useEffect(() => {
    console.log("unim is dis wekring", selectedLogoRef.current.values.link);
  }, [selectedLogoRef]);

  useEffect(() => {
    console.log("found something?`", logoResults);
  }, [logoResults]);

  const logoFileUpload = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      return;
    }
    //i have to fix this, here lies the issue
    const logo = files[0];
    setLogoImageData(logo);
    createLogoFile(files[0]);
    console.log("image", files[0]);
  };

  const createLogoFile = (file) => {
    console.log("notino", file, LogoPlaceholder);
    let reader = new FileReader();
    reader.onload = (e) => {
      const preview = e.target.result;
      setImgPreview(preview);
    };

    reader.readAsDataURL(file);
  };

  const renderLogoModal = () => {
    console.log("recieces logo arr as ", logoArr.length, logoArr);
    console.log("has something?", logoResults);
    if (logoArr.length > 0) {
      //TEST upload file modal change to 1000
      return logoArr.map((item, i) => {
        return (
          <div key={i}>
            <div
              className={`toolModalHeader-ImageResult ${
                selectedLogo[0] === item[0] ? "tmh-ImageSelected" : ""
              }`}
              onClick={() => setSelectedLogo(item)}
            >
              <img className="tmh-logoRender" src={item[0]} />
            </div>
            <p>
              {item[1]}x{item[2]}px
            </p>
          </div>
        );
      });
    } else {
      if (logoResults) {
        //TEST upload file modal - add negation
        return (
          <div className="tmh-Loader">
            <Loading></Loading>
          </div>
        );
      } else {
        return (
          <>
            <div className="toolModalContainer-noResults">
              <p className="tmc-nr-head">
                No se encontraron logos a partir del URL proporcionado
              </p>
              <p className="tmc-nr-sub-head">
                Puedes saltar este paso por el momento
              </p>
            </div>

            {/* <div className="toolModalContainer-noResults">
            <p className="tmc-nr-head">No se encontraron logos a partir del URL proporcionado</p>
            <p className="tmc-nr-sub-head">Prueba cargando un logotipo desde tu ordenador</p>

            <div className="tmc-nr-actions">
              <div className="tmc-nr-image">
                {
                  imgPreview ? (
                    <img style={{ width: '100%', height: 'auto' }} src={imgPreview} alt="" />
                  ) : (
                    <div className="tmc-nr-image-placeholder" >
                      <div style={{ width: '4rem', height: '4rem' }}>
                        <img src={ImageIcon} alt="" />
                      </div>
                    </div>
                  )
                }
              </div>
              <div style={{ display: "flex", flexDirection: 'column' }}>
                <label htmlFor="logo-upload" className="btn-style main-btn-align tmc-nr-button" >
                  Cargar logotipo
                </label>
                <p className="tmc-nr-sub-head" style={{ marginTop: '0rem', marginBottom: '0rem' }}>Dimensiones optimas:</p>
                <p className="tmc-nr-sub-head" style={{ marginTop: '0rem' }}>&gt; 64px &lt; 256px</p>
              </div>
            </div>
            <div>
              <input id="logo-upload" type="file" name="file" onChange={logoFileUpload} accept='image/*' />
            </div>
          </div> */}
          </>
        );
      }
    }
  };

  const getLogoFromUrl = async () => {
    setLogoResults(true);
    if (resourceUrl !== "" || selectedLogo !== "" || selectedLogo.length > 5) {
      console.log("unim not empty", resourceUrl);
      let logoEditingSwitch;

      if (resourceUrl) {
        console.log("unim 1", resourceUrl);
        logoEditingSwitch = resourceUrl;
      } else {
        console.log("unim 2", selectedLogoRef.current.values.link);
        logoEditingSwitch = selectedLogoRef.current.values.link;
      }

      try {
        setLogoArr([]);
        const res = await api.axiosAuthPost(
          `/cms/api/v2/resources-crawl-images`,
          `${loggedUser.token}`,
          { url: `${logoEditingSwitch}` }
        );

        console.log("unim ", res);
        setLogoArr(res.data.images);
      } catch (error) {
        setLogoResults(false);
        console.log(error);
      }
    }
  };

  const handleLogoSelector = () => {
    if (logoImageData) {
      setShowLogoPicker(true);
    } else {
      getLogoFromUrl();
      setShowLogoPicker(true);
    }
  };

  const handleCloseLogoModal = (logo) => {
    // if (logoImageData) {
    //   setShowLogoPicker(false)
    //   console.log('uploaded logo ', logoImageData)
    // }
    console.log("logooo", logo);
    if (logo === "" && logo.includes("nologo")) {
      console.log("no logo skip section");
      setShowLogoPicker(false);
      setSelectedLogo("null");
    } else {
      setShowLogoPicker(false);
      console.log("selected logo2 ", logo);

      //getFileFromUrl(logo)
      //setSelectedLogoFile(getFileFromUrl(logo[0]))
      setSelectedLogo(logo);
    }
  };

  const renderLogoButton = () => {
    if (
      hasToolUrl !== "" ||
      hasToolUrl.length > 5 ||
      selectedLogo.length > 5 ||
      selectedLogo !== ""
    ) {
      return (
        <Button
          label={"Obtener logotipo"}
          onClick={() => handleLogoSelector()}
          style={{
            color: "#7a4199",
            border: "1px solid #7a4199",
            width: "60%",
          }}
        />
      );
    } else {
      return (
        <Button
          label={"Obtener logotipo"}
          style={{
            color: "#7a4199",
            border: "1px solid #7a4199",
            width: "60%",
            opacity: "0.3",
          }}
        />
      );
    }
  };

  const cleanUpUrlString = (url) => {
    console.log("post malone", url);
    return url;
  };

  const renderToolLogoSaveBtn = () => {
    //TEST uncomment this and remove above code block
    console.log("selectedLogo", selectedLogo);
    if (logoResults && (selectedLogo || selectedLogo.length > 5)) {
      return (
        <Button
          label={"Guardar Seleccion"}
          onClick={() => handleCloseLogoModal(selectedLogo[0])}
          style={{ color: "#7a4199", border: "1px solid #7a4199" }}
        />
      );
    } else if (!logoResults) {
      return (
        <Button
          //label={'Subir logotipo'}
          label={"Continuar sin logotipo"}
          //onClick={() => handleCloseLogoModal(logoImageData)}
          onClick={() => handleCloseLogoModal("nologo")}
          style={{ color: "#7a4199", border: "1px solid #7a4199" }}
        />
      );
    } else {
      return (
        <Button
          label={"Guardar Seleccion"}
          style={{
            color: "#7a4199",
            border: "1px solid #7a4199",
            opacity: " 0.3 ",
          }}
        />
      );
    }
    // } else if (logoResults) {
    //   if (!logoImageData) {
    //     // return <Button
    //     //   label={'Subir logotipo'}
    //     //   onClick={() => handleCloseLogoModal(logoImageData)}
    //     //   style={{ color: '#7a4199', border: '1px solid #7a4199' }}
    //     // />
    //   } else {
    //     return <Button
    //       label={'Subir logotipo'}
    //       style={{ color: '#7a4199', border: '1px solid #7a4199', opacity: ' 0.3 ' }}
    //     />
    //   }
    // } else {
    //   return <Button
    //     label={'Guardar Seleccion'}
    //     style={{ color: '#7a4199', border: '1px solid #7a4199', opacity: ' 0.3 ' }}
    //   />
    // }
  };
  return (
    <>
      <Formik
        innerRef={selectedLogoRef}
        initialValues={{
          link: contentResource?.url || "",
          language: contentResource?.language || "",
          objective: contentResource?.objective || "",
          title: contentResource?.title || "",
          description: contentResource?.description || "",
          tool_creator: contentResource?.tool_creator || "",
          //license_price: licensePrice || "",
          license_price: contentResource?.license_price || "",
          country_region_usa: contentResource?.country_region_usa || false,
          country_region_canada:
            contentResource?.country_region_canada || false,
          country_region_argentina:
            contentResource?.country_region_argentina || false,
          country_region_bolivia:
            contentResource?.country_region_bolivia || false,
          country_region_chile: contentResource?.country_region_chile || false,
          country_region_colombia:
            contentResource?.country_region_colombia || false,
          country_region_costa_rica:
            contentResource?.country_region_costa_rica || false,
          country_region_ecuador:
            contentResource?.country_region_ecuador || false,
          country_region_el_salvador:
            contentResource?.country_region_el_salvador || false,
          country_region_guatemala:
            contentResource?.country_region_guatemala || false,
          country_region_honduras:
            contentResource?.country_region_honduras || false,
          country_region_mexico:
            contentResource?.country_region_mexico || false,
          country_region_nicaragua:
            contentResource?.country_region_nicaragua || false,
          country_region_paraguay:
            contentResource?.country_region_paraguay || false,
          country_region_panama:
            contentResource?.country_region_panama || false,
          country_region_peru: contentResource?.country_region_peru || false,
          country_region_rep_dominicana:
            contentResource?.country_region_rep_dominicana || false,
          country_region_uruguay:
            contentResource?.country_region_uruguay || false,
          country_region_venezuela:
            contentResource?.country_region_venezuela || false,
        }}
        onSubmit={(values, { resetForm }) => {
          //log something on submit
          onCreateContent(values, resetForm);
        }}
        validationSchema={Yup.object({
          link: Yup.string()
            .required("Este campo es obligatorio")
            .max(255, "Máximo 255 caracteres")
            .url("No es un formato válido"),
          language: Yup.string()
            .required("Este campo es obligatorio")
            .max(255, "Máximo 255 caracteres"),
          objective: Yup.string()
            .required("Este campo es obligatorio")
            .max(255, "Máximo 255 caracteres"),
          title: Yup.string()
            .required("Este campo es obligatorio")
            .max(255, "Máximo 255 caracteres"),
          //toolCreator: Yup.string().required("Este campo es obligatorio").max(255, "Máximo 255 caracteres"),
        })}
      >
        {(formik) => (
          <FormFormik className="container-resource">
            <div className="container-resourceForm">
              <Row className="rowLimit rowWidth rowDashboard">
                <Col lg={10}>
                  <h1>
                    {loggedUser.name}, estás indexando{" "}
                    {isTool ? "una herramienta" : "un recurso"} 👌
                  </h1>
                </Col>
              </Row>
              <Row className="rowLimit rowWidth rowDashboard">
                <Col md={10}>
                  <Form.Group className="resource-field">
                    <label>¿Qué tipo de recurso es? </label>
                    <TypeResource setType={setType} type={type} />
                  </Form.Group>
                  <Form.Group
                    className="resource-field"
                    onChange={(e) => setResourceUrl(e.target.value)}
                  >
                    <label className="resource-link">
                      Pega aquí el url{" "}
                      {isTool ? "la herramienta" : "del recurso"}:{" "}
                    </label>
                    <Field
                      name="link"
                      type="text"
                      validate={validateUrlForUtm}
                      className="inputNewBrand main-btn-align form-control"
                      placeholder="https://urlDelRecurso.com"
                      onBlur={(e) => setHasToolUrl(e.target.value)}
                    />
                    <ErrorMessage
                      name="link"
                      component="div"
                      className="frmErrorCategory"
                    />
                  </Form.Group>
                  {/* move this into its own component */}
                  {showLogoPicker ? (
                    <div className="toolModalContainer">
                      <div className="toolModalLogoPicker">
                        <div className="toolModalHeader">
                          <h4>Selecciona el logo mas acorde tu herramienta</h4>
                          <div onClick={() => setShowLogoPicker(false)}>
                            <img src={CloseIcon} alt="close-modal" />
                          </div>
                        </div>
                        <hr />
                        {false ? (
                          <>
                            <label className="resource-link">
                              Pega aquí el url{" "}
                              {isTool ? "la herramienta" : "del recurso"}:{" "}
                            </label>
                            <Field
                              name="link"
                              type="text"
                              className="inputNewBrand main-btn-align form-control"
                              placeholder="https://urlDelRecurso.com"
                            />
                          </>
                        ) : null}

                        <div className="toolModalContainer-imageResultscontainer">
                          {renderLogoModal()}
                        </div>
                        {renderToolLogoSaveBtn()}
                      </div>
                    </div>
                  ) : null}

                  {isTool ? (
                    <>
                      <Form.Group className="resource-field">
                        <label>Creador de la herramienta</label>
                        <Field
                          name="tool_creator"
                          type="text"
                          className="inputNewBrand main-btn-align form-control"
                          placeholder="Creador inc"
                        />
                        <ErrorMessage
                          name="tool_creator"
                          component="div"
                          className="frmErrorCategory"
                        />
                      </Form.Group>

                      <Form.Group className="resource-field">
                        <label>Obtener logotipo de la herramienta</label>
                        <div style={{ width: "100%" }}>
                          {selectedLogo && selectedLogo.length > 0 ? (
                            <div className="toolModalHeader-ImageResult tmh-ImageSelected">
                              {selectedLogo.includes("nologo") ? (
                                <img
                                  className="tmh-logoRender"
                                  src={LogoPlaceholder}
                                />
                              ) : (
                                <img
                                  className="tmh-logoRender"
                                  src={selectedLogo}
                                />
                              )}
                            </div>
                          ) : null}

                          {imgPreview ? (
                            <div className="toolModalHeader-ImageResult tmh-ImageSelected">
                              <img
                                className="tmh-logoRender"
                                src={imgPreview}
                              />
                            </div>
                          ) : null}
                          <br />
                          {renderLogoButton()}
                        </div>
                        <ErrorMessage
                          name="link"
                          component="div"
                          className="frmErrorCategory"
                        />
                      </Form.Group>

                      <Form.Group>
                        <label>Precio o descripción de licencia</label>
                        <Editor
                          textareaName="description"
                          value={licensePrice}
                          onEditorChange={(e) => {
                            setLicensePrice(e);
                          }}
                          init={{
                            resize: true,
                            menubar: true,
                            statusbar: false,
                            plugins: [
                              "image",
                              "lists",
                              "fullscreen",
                              "table",
                              "link",
                              "autoresize",
                            ],
                            toolbar:
                              "undo redo | formatselect | " +
                              "bold italic backcolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                        />
                        <ErrorMessage
                          name="license_price"
                          component="div"
                          className="frmErrorCategory"
                        />
                      </Form.Group>

                      <Form.Group className="resource-field">
                        <label htmlFor="businessStage">
                          ¿En que país esta disponible la herramienta?
                        </label>
                        <div className="container-countries">
                          <div className="container-countries-first">
                            <div className="tactic-checkbox">
                              <div
                                onClick={allCountries}
                                className={
                                  isAllCountries
                                    ? "tactic-containerCheckbox"
                                    : "tactic-containerCheckboxInactive"
                                }
                              >
                                {isAllCountries && (
                                  <img
                                    src={require("../../assets/images/tactics/Check.png")}
                                    alt="check"
                                  />
                                )}
                              </div>
                              <p>Todos 💪</p>
                            </div>

                            <div className="tactic-checkbox">
                              <div
                                onClick={() => setCountryArg((prev) => !prev)}
                                className={
                                  countryArg
                                    ? "tactic-containerCheckbox"
                                    : "tactic-containerCheckboxInactive"
                                }
                              >
                                {countryArg && (
                                  <img
                                    src={require("../../assets/images/tactics/Check.png")}
                                    alt="check"
                                  />
                                )}
                              </div>
                              <p>Argentina</p>
                              <img src={ArgentinaFlag} alt="Colombia" />
                            </div>

                            <div className="tactic-checkbox">
                              <div
                                onClick={() => setCountryBol((prev) => !prev)}
                                className={
                                  countryBol
                                    ? "tactic-containerCheckbox"
                                    : "tactic-containerCheckboxInactive"
                                }
                              >
                                {countryBol && (
                                  <img
                                    src={require("../../assets/images/tactics/Check.png")}
                                    alt="check"
                                  />
                                )}
                              </div>
                              <p>Bolivia</p>
                              <img src={BoliviaFlag} alt="Bolivia" />
                            </div>
                            <div className="tactic-checkbox">
                              <div
                                onClick={() => setCountryChile((prev) => !prev)}
                                className={
                                  countryChile
                                    ? "tactic-containerCheckbox"
                                    : "tactic-containerCheckboxInactive"
                                }
                              >
                                {countryChile && (
                                  <img
                                    src={require("../../assets/images/tactics/Check.png")}
                                    alt="check"
                                  />
                                )}
                              </div>
                              <p>Chile</p>
                              <img src={ChileFlag} alt="Chile" />
                            </div>
                            <div className="tactic-checkbox">
                              <div
                                onClick={() =>
                                  setCountryColombia((prev) => !prev)
                                }
                                className={
                                  countryColombia
                                    ? "tactic-containerCheckbox"
                                    : "tactic-containerCheckboxInactive"
                                }
                              >
                                {countryColombia && (
                                  <img
                                    src={require("../../assets/images/tactics/Check.png")}
                                    alt="check"
                                  />
                                )}
                              </div>
                              <p>Colombia</p>
                              <img src={ColombiaFlag} alt="Colombia" />
                            </div>

                            <div className="tactic-checkbox">
                              <div
                                onClick={() => setCountryVenez((prev) => !prev)}
                                className={
                                  countryVenez
                                    ? "tactic-containerCheckbox"
                                    : "tactic-containerCheckboxInactive"
                                }
                              >
                                {countryVenez && (
                                  <img
                                    src={require("../../assets/images/tactics/Check.png")}
                                    alt="check"
                                  />
                                )}
                              </div>
                              <p>Venezuela</p>
                              <img src={VenezuelaFlag} alt="Venezuela" />
                            </div>
                          </div>
                          <div className="container-countries-second">
                            <div className="tactic-checkbox">
                              <div
                                onClick={() => setCountryEcua((prev) => !prev)}
                                className={
                                  countryEcua
                                    ? "tactic-containerCheckbox"
                                    : "tactic-containerCheckboxInactive"
                                }
                              >
                                {countryEcua && (
                                  <img
                                    src={require("../../assets/images/tactics/Check.png")}
                                    alt="check"
                                  />
                                )}
                              </div>
                              <p>Ecuador</p>
                              <img src={EcuadorFlag} alt="Ecuador" />
                            </div>
                            <div className="tactic-checkbox">
                              <div
                                onClick={() => setCountrySalv((prev) => !prev)}
                                className={
                                  countrySalv
                                    ? "tactic-containerCheckbox"
                                    : "tactic-containerCheckboxInactive"
                                }
                              >
                                {countrySalv && (
                                  <img
                                    src={require("../../assets/images/tactics/Check.png")}
                                    alt="check"
                                  />
                                )}
                              </div>
                              <p>El salvador</p>
                              <img src={SalvadorFlag} alt="El Salvador" />
                            </div>
                            <div className="tactic-checkbox">
                              <div
                                onClick={() => setCountryGuate((prev) => !prev)}
                                className={
                                  countryGuate
                                    ? "tactic-containerCheckbox"
                                    : "tactic-containerCheckboxInactive"
                                }
                              >
                                {countryGuate && (
                                  <img
                                    src={require("../../assets/images/tactics/Check.png")}
                                    alt="check"
                                  />
                                )}
                              </div>
                              <p>Guatemala</p>
                              <img src={GuatemalaFlag} alt="Guatemala" />
                            </div>
                            <div className="tactic-checkbox">
                              <div
                                onClick={() => setCountryHond((prev) => !prev)}
                                className={
                                  countryHond
                                    ? "tactic-containerCheckbox"
                                    : "tactic-containerCheckboxInactive"
                                }
                              >
                                {countryHond && (
                                  <img
                                    src={require("../../assets/images/tactics/Check.png")}
                                    alt="check"
                                  />
                                )}
                              </div>
                              <p>Honduras</p>
                              <img src={HondurasFlag} alt="Honduras" />
                            </div>
                            <div className="tactic-checkbox">
                              <div
                                onClick={() => setCountryMex((prev) => !prev)}
                                className={
                                  countryMex
                                    ? "tactic-containerCheckbox"
                                    : "tactic-containerCheckboxInactive"
                                }
                              >
                                {countryMex && (
                                  <img
                                    src={require("../../assets/images/tactics/Check.png")}
                                    alt="check"
                                  />
                                )}
                              </div>
                              <p>México</p>
                              <img src={MexicoFlag} alt="México" />
                            </div>
                            <div className="tactic-checkbox">
                              <div
                                onClick={() => setCountryNica((prev) => !prev)}
                                className={
                                  countryNica
                                    ? "tactic-containerCheckbox"
                                    : "tactic-containerCheckboxInactive"
                                }
                              >
                                {countryNica && (
                                  <img
                                    src={require("../../assets/images/tactics/Check.png")}
                                    alt="check"
                                  />
                                )}
                              </div>
                              <p>Nicaragua</p>
                              <img src={NicaraguaFlag} alt="Nicaragua" />
                            </div>
                          </div>
                          <div className="container-countries-third">
                            <div className="tactic-checkbox">
                              <div
                                onClick={() => setCountryParag((prev) => !prev)}
                                className={
                                  countryParag
                                    ? "tactic-containerCheckbox"
                                    : "tactic-containerCheckboxInactive"
                                }
                              >
                                {countryParag && (
                                  <img
                                    src={require("../../assets/images/tactics/Check.png")}
                                    alt="check"
                                  />
                                )}
                              </div>
                              <p>Paraguay</p>
                              <img src={ParaguayFlag} alt="Paraguay" />
                            </div>
                            <div className="tactic-checkbox">
                              <div
                                onClick={() =>
                                  setCountryPanama((prev) => !prev)
                                }
                                className={
                                  countryPanama
                                    ? "tactic-containerCheckbox"
                                    : "tactic-containerCheckboxInactive"
                                }
                              >
                                {countryPanama && (
                                  <img
                                    src={require("../../assets/images/tactics/Check.png")}
                                    alt="check"
                                  />
                                )}
                              </div>
                              <p>Panamá</p>
                              <img src={PanamaFlag} alt="Panamá" />
                            </div>
                            <div className="tactic-checkbox">
                              <div
                                onClick={() => setCountryPeru((prev) => !prev)}
                                className={
                                  countryPeru
                                    ? "tactic-containerCheckbox"
                                    : "tactic-containerCheckboxInactive"
                                }
                              >
                                {countryPeru && (
                                  <img
                                    src={require("../../assets/images/tactics/Check.png")}
                                    alt="check"
                                  />
                                )}
                              </div>
                              <p>Perú</p>
                              <img src={PeruFlag} alt="Perú" />
                            </div>
                            <div className="tactic-checkbox">
                              <div
                                onClick={() =>
                                  setCountryRepDom((prev) => !prev)
                                }
                                className={
                                  countryRepDom
                                    ? "tactic-containerCheckbox"
                                    : "tactic-containerCheckboxInactive"
                                }
                              >
                                {countryRepDom && (
                                  <img
                                    src={require("../../assets/images/tactics/Check.png")}
                                    alt="check"
                                  />
                                )}
                              </div>
                              <p>Rep. Dominicana</p>
                              <img
                                src={DominicanaFlag}
                                alt="República Dominicana"
                              />
                            </div>

                            <div className="tactic-checkbox">
                              <div
                                onClick={() => setCountryUrug((prev) => !prev)}
                                className={
                                  countryUrug
                                    ? "tactic-containerCheckbox"
                                    : "tactic-containerCheckboxInactive"
                                }
                              >
                                {countryUrug && (
                                  <img
                                    src={require("../../assets/images/tactics/Check.png")}
                                    alt="check"
                                  />
                                )}
                              </div>
                              <p>Uruguay</p>
                              <img src={UruguayFlag} alt="Uruguay" />
                            </div>

                            <div className="tactic-checkbox">
                              <div
                                onClick={() => setCountryCRica((prev) => !prev)}
                                className={
                                  countryCRica
                                    ? "tactic-containerCheckbox"
                                    : "tactic-containerCheckboxInactive"
                                }
                              >
                                {countryCRica && (
                                  <img
                                    src={require("../../assets/images/tactics/Check.png")}
                                    alt="check"
                                  />
                                )}
                              </div>
                              <p>Costa Rica</p>
                              <img src={CostaRicaFlag} alt="Canada" />
                            </div>

                            {/* <div className="tactic-checkbox">
                                <div
                                  onClick={() => setCountryCanada((prev) => !prev)}
                                  className={
                                    countryCanada
                                      ? "tactic-containerCheckbox"
                                      : "tactic-containerCheckboxInactive"
                                  }
                                >
                                  {countryCanada && (
                                    <img
                                      src={require("../../assets/images/tactics/Check.png")}
                                      alt="check"
                                    />
                                  )}
                                </div>
                                <p>Canada</p>
                                <img src={CanadaFlag} alt="Canada" style={{ width: '20px', height: '15px' }} />
                              </div> */}
                          </div>
                        </div>
                        {showErrorCountry && (
                          <div className="frmError">
                            Selecciona al menos una opción
                          </div>
                        )}
                      </Form.Group>
                    </>
                  ) : null}

                  {!isTool ? (
                    <Form.Group className="resource-field">
                      <div className="resource-own">
                        <label>¿Es recurso propio?</label>
                        <OwnResource
                          setOwnResource={setOwnResource}
                          ownResource={ownResource}
                        />
                      </div>
                    </Form.Group>
                  ) : null}

                  <Row>
                    <Form.Group className="resource-field" as={Col}>
                      <label>¿En qué idioma es?</label>
                      <Field
                        name="language"
                        as="select"
                        className="inputNewBrand main-btn-align form-control"
                      >
                        <option value="">Selecciona</option>
                        <option value="Español">Español</option>
                        <option value="Francés">Francés</option>
                        <option value="Inglés">Inglés</option>
                        <option value="Portugués">Portugués</option>
                        <option value="Todos">Todos</option>
                      </Field>
                      <ErrorMessage
                        name="language"
                        component="div"
                        className="frmErrorCategory"
                      />
                    </Form.Group>
                    <Form.Group className="resource-field" as={Col}>
                      <label>¿Objetivo del recurso?</label>
                      <Field
                        name="objective"
                        as="select"
                        className="inputNewBrand main-btn-align form-control"
                      >
                        <option value="">Selecciona</option>
                        <option value="Descubrimiento">Descubrimiento</option>
                        <option value="Aprendizaje">Aprendizaje</option>
                        <option value="Accionable">Accionable</option>
                      </Field>
                      <ErrorMessage
                        name="objective"
                        component="div"
                        className="frmErrorCategory"
                      />
                    </Form.Group>
                  </Row>
                  <Form.Group className="resource-field">
                    <label>
                      ¿Cuál es el título{" "}
                      {isTool ? "de la herramienta?" : "de tu recurso?"}
                    </label>
                    <Field
                      name="title"
                      type="text"
                      className="inputNewBrand main-btn-align form-control"
                      placeholder="Elige un título que llama la atención y deja claro el valor 😉"
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="frmErrorCategory"
                    />
                  </Form.Group>
                  <Form.Group className="resource-field">
                    <div className="resource-containerDescription">
                      <label>Puedes agregar una descripción</label>
                      <p>Es opcional</p>
                    </div>
                    <Field
                      name="description"
                      as="textarea"
                      className="inputTextarea main-btn-align form-control"
                      placeholder="Describe tu recurso en uno o dos párrafos"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <div className="container-resourceMenu">
              <MenuResource stage={stage} formik={formik} setStage={setStage} />
            </div>
          </FormFormik>
        )}
      </Formik>
    </>
  );
};

export default Stage1Resource;
