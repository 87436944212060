import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { GlobalStyle } from "../Components/Styles/GlobalStyle";
import Success from "../Components/Success/Success";
import Companies from "../Pages/Companies/Companies";
import Configuration from "../Pages/Configuration/Configuration";
import Dashboard from "../Pages/Dashboard/Dashboard";
import { GrowthPlan } from "../Pages/GrowthPlan/GrowthPlan";
import Library from "../Pages/Library/Library";
import { Logrador } from "../Pages/Logrador/Logrador";
import Referal from "../Pages/Referal/Referal";
import Referred from "../Pages/Referred/Referred";
import CreateResource from "../Pages/Resources/CreateResource";
import { ViewResource } from "../Pages/Resources/ViewResource";
import CreateAdv from "../Pages/Tactics/CreateAdv";
import CreateStep from "../Pages/Tactics/CreateStep";
import CreateTactic from "../Pages/Tactics/CreateTactic";
import Tactic from "../Pages/Tactics/Tactic";
import ViewTactic from "../Pages/Tactics/ViewTactic";
import NewHomePage from "../Pages/Home/new-home";

const routesList = [
  {
    auth: true,
    component: NewHomePage,
    exact: true,
    name: "home",
    path: "/home",
  },
  {
    auth: true,
    component: Dashboard,
    exact: true,
    name: "dashboard",
    path: "/dashboard",
  },
  {
    auth: true,
    component: GrowthPlan,
    exact: true,
    name: "growthplan",
    path: "/growthplan",
  },
  {
    auth: true,
    component: Referred,
    exact: true,
    name: "referred",
    path: "/referred",
  },

  {
    auth: true,
    component: Configuration,
    exact: true,
    name: "configuration",
    path: "/configuration",
  },

  {
    auth: true,
    component: CreateTactic,
    exact: true,
    name: "createTactic",
    path: "/tactic/new",
  },

  {
    auth: true,
    component: ViewTactic,
    exact: true,
    name: "viewTactic",
    path: "/tactic/view/:id",
  },
  {
    auth: true,
    component: CreateTactic,
    exact: true,
    name: "editTactic",
    path: "/tactic/edit/:id",
  },
  {
    auth: true,
    component: CreateAdv,
    exact: true,
    name: "create",
    path: "/create-adv",
  },
  {
    auth: true,
    component: CreateStep,
    exact: true,
    name: "create",
    path: "/create-step",
  },
  {
    auth: true,
    component: Companies,
    exact: true,
    name: "companies",
    path: "/companies",
  },
  {
    auth: true,
    component: Referal,
    exact: true,
    name: "referal",
    path: "/companies/referal",
  },
  {
    auth: true,
    component: Tactic,
    exact: true,
    name: "tactic",
    path: "/tactic",
  },
  {
    auth: true,
    component: Success,
    exact: true,
    name: "comercialOfferSuccess",
    path: "/offer/success",
  },
  {
    auth: true,
    component: CreateResource,
    exact: true,
    name: "createResource",
    path: "/resource/new",
  },
  {
    auth: true,
    component: CreateResource,
    exact: true,
    name: "editResource",
    path: "/resource/edit/:id",
  },
  {
    auth: true,
    component: ViewResource,
    exact: true,
    name: "viewResource",
    path: "/resources",
  },
  {
    auth: true,
    component: Library,
    exact: true,
    name: "library",
    path: "/library",
  },
  {
    auth: true,
    component: Logrador,
    exact: true,
    name: "logrador",
    path: "/logrador",
  },
];

export const RouterApp = () => {
  return (
    <div>
      <GlobalStyle />
      <Switch>
        {routesList.map((route, index) => (
          <Route
            exact={route.exact}
            path={route.path}
            component={route.component}
            key={index}
          />
        ))}
        <Redirect to="/home" />
      </Switch>
    </div>
  );
};
