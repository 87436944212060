import { Button } from "@logra/component-library";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { GrowthPlanCard } from "../../Components/GrowthPlanCard/GrowthPlanCard";
import { ModalTemp } from "../../Components/ModalTemp/ModalTemp";
import "./table.css";

export const GrowthPlan = () => {
  const history = useHistory();
  const [tab, setTab] = useState("Resumen");
  const tabs = [
    "Resumen",
    "Planeación",
    "Equipo",
    "Marketing",
    "Administración",
    "Tecnología",
  ];
  const [showModal, setShowModal] = useState();

  useEffect(() => {
    let ls;
    ls = localStorage.getItem("startWithModalGrowth");
    if (ls === null || ls === undefined) {
      localStorage.setItem("startWithModalGrowth", true);
    } else {
      setShowModal(false);
    }
  }, []);

  const renderTab = (tab) => {
    switch (tab) {
      case "Resumen":
        return resumen;
      case "Marketing":
        return marketing;
      default:
        return <h1>{tab}</h1>;
    }
  };

  const resumen = (
    <div
      className="resourceCardContainer resourceGrid"
      style={{ gap: "1rem", marginTop: "3rem" }}
    >
      <GrowthPlanCard
        category={"Estrategia"}
        challenges={["", ""]}
        status={false}
      />
      <GrowthPlanCard
        category={"Marketing"}
        challenges={["", "", ""]}
        status={true}
      />
      <GrowthPlanCard
        category={"Finanzas"}
        challenges={["", "", "", "", ""]}
        status={false}
      />
      <GrowthPlanCard
        category={"Equipo"}
        challenges={["", "", ""]}
        status={false}
      />
      <GrowthPlanCard
        category={"Tecnología"}
        challenges={["", "", ""]}
        status={true}
      />
    </div>
  );

  let contents = [true, false, false, true, true];

  const marketing = (
    <table className="tb">
      <thead className="tb-hh">
        <tr className="tb-r">
          <th className="tb-h hh">
            <h4>Retos de crecimiento</h4>
          </th>
          <th className="tb-h">
            <h4>Tácticas</h4>
          </th>
          <th className="tb-h">
            <h4>Recursos</h4>
          </th>
          <th className="tb-h">
            <h4>Expertos</h4>
          </th>
          <th className="tb-h">
            <h4>Estado</h4>
          </th>
          <th className="tb-h hh"></th>
        </tr>
      </thead>
      <tbody className="tb-b">
        {contents.map((content, i) => {
          return (
            <tr className="tb-r" key={i}>
              <td className="tb-c hh">
                <p
                  style={{
                    marginLeft: "0",
                    padding: ".25rem",
                    paddingLeft: "1.5rem",
                    paddingRight: "1.5rem",
                    borderRadius: "5px",
                    backgroundColor: "#F9DEBE",
                    color: "#00000030",
                    fontWeight: "500",
                  }}
                >
                  Etapas de conversión de ventas
                </p>
              </td>
              <td className="tb-c">
                <p>{content ? "1" : "-"}</p>
              </td>
              <td className="tb-c">
                <p>{content ? "1" : "-"}</p>
              </td>
              <td className="tb-c">
                <p>{content ? "1" : "-"}</p>
              </td>
              <td className="tb-c">
                <p style={{ color: content ? "#EFA54D" : "#E26963" }}>
                  {content ? "En progreso" : "por iniciar"}
                </p>
              </td>
              <td className="tb-c tb-c-btn">
                {content ? (
                  <Button
                    label="Ejecutar mi plan"
                    primary
                    size="large"
                    className="tb-btn-overrides"
                  />
                ) : (
                  <Button
                    label="Por iniciar"
                    size="large"
                    className="tb-btn-overrides btn-sec"
                  />
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  return (
    <div className="container-home">
      <ModalTemp
        slides={[
          {
            title: "Bienvenido a tu plan de crecimiento 📈",
            content: (
              <>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "1.5vw",
                    lineHeight: "120%",
                    marginBottom: "3rem",
                  }}
                  className={"responsiveOverrides"}
                >
                  Puedes organizar{" "}
                  <strong>tu conocimiento y contenido de valor</strong> en un
                  solo lugar.
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "1.5vw",
                    lineHeight: "120%",
                    color: "#2c5999",
                  }}
                  className={"responsiveOverrides"}
                >
                  Y compartir a cualquiera <strong>tu librería pública!</strong>
                </p>
              </>
            ),
          },
        ]}
        showModal={showModal}
        setShowModal={setShowModal}
        onClose={() => localStorage.setItem("startWithModalGrowth", false)}
      />
      <div className="container-homeMain">
        <div>
          <div className="head-nav">
            <div className="stack-stack-head">
              <div className="stack-head">
                <h3
                  className="stack-title"
                  style={{ fontWeight: "bold", color: "#212529" }}
                >
                  Tu plan de crecimiento
                </h3>
                <h5 className="stack-subtitle">
                  Encuentra e implementa acciones y expertos para lograr
                  resultados rápidos 🎯
                </h5>
              </div>
            </div>

            <div
              className="stack-control-tab"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="stack-tabs" style={{ width: "fit-content" }}>
                {tabs.map((title, i) => {
                  return (
                    <button
                      onClick={() => setTab(title)}
                      className={`${tab === title && "activeTab"} tabButton`}
                      style={{ marginRight: i >= 1 ? "1rem" : "0rem" }}
                      key={i}
                    >
                      <h5 className="tabLabel">{title}</h5>
                    </button>
                  );
                })}

                {renderTab(tab)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
