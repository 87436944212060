import React from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import CopyIcon from "../../assets/images/icons/CopyIcon.svg";
import CloseIcon from "../../assets/images/icons/Close.svg";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";

import { createSlugName } from "../../utils/common";

const ModalShare = (props) => {
  const changeSharePath = (type) => {
    switch (type) {
      case "expert":
        // return "app/experto";
        return "expert/publico";
      case "tactic":
        return "tactica/publica";
      case "offer":
        return "oferta/publica";
      default:
        return "publica";
    }
  };
  const sharePath = changeSharePath(props?.type);
  const copySlugToClipboard = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_DOMAIN_PYMES_APP.substring(
        0,
        process.env.REACT_APP_DOMAIN_PYMES_APP.length - "/app".length
      )}/${sharePath}/${createSlugName(props?.slug)}`
    );
  };

  const renderTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        Copiar
      </Tooltip>
    );
  };

  const shareUrl = `${process.env.REACT_APP_DOMAIN_PYMES_APP.substring(
    0,
    process.env.REACT_APP_DOMAIN_PYMES_APP.length - "/app".length
  )}/${sharePath}/${createSlugName(props?.slug)}`;

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 30,
        }}
      >
        <div
          style={{
            position: "absolute",
            right: 20,
            top: 10,
            cursor: "pointer",
          }}
          onClick={props.onHide}
        >
          <img src={CloseIcon} alt="Cerrar" />
        </div>
        <h4
          style={{
            fontWeight: 600,
            fontSize: "2em",
          }}
        >
          {props?.title}
        </h4>
        <div
          style={{
            width: "100%",
            marginTop: "30px",
            borderBottom: "1px solid #D2D7DE",
          }}
        >
          <div className="landingLink">
            {process.env.REACT_APP_DOMAIN_PYMES_APP.substring(
              0,
              process.env.REACT_APP_DOMAIN_PYMES_APP.length - "/app".length
            )}
            /{sharePath}/{createSlugName(props?.slug)}
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <img
                src={CopyIcon}
                alt="FileCopy"
                className="fileCopyImg"
                onClick={() => copySlugToClipboard()}
              />
            </OverlayTrigger>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            margin: "20px 0",
            width: "100%",
          }}
        >
          <FacebookShareButton
            url={shareUrl}
            quote={props.title}
            style={{ marginRight: 15 }}
          >
            <FacebookIcon size={32} round={true} url={shareUrl} />
          </FacebookShareButton>

          <TwitterShareButton
            url={shareUrl}
            title={props.title}
            style={{ marginRight: 15 }}
          >
            <TwitterIcon size={32} round={true} url={shareUrl} />
          </TwitterShareButton>

          <WhatsappShareButton
            url={shareUrl}
            title={props.title}
            style={{ marginRight: 15 }}
          >
            <WhatsappIcon size={32} round={true} url={shareUrl} />
          </WhatsappShareButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalShare;
