import React from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import ShareIcon from "../../assets/images/icons/ShareWhite.svg";
import NoImageProfile from "../../assets/images/tactics/NoImageNew.svg";
import NoImage from "../../assets/images/tactics/NoImage.png";
import TimeIcon from "../../assets/images/tactics/TimePurple.png";
import Star from "../../assets/images/icons/star.svg";
import Close from "../../assets/images/icons/closeWhite.png";
import "../../assets/styles/pages/tactics/modal-tacticGeneral.scss";

const ModalTacticGeneral = ({
  background,
  tactic,
  showModalTacticGeneral,
  setShowModalTacticGeneral,
  setShowModalShare,
  expert,
  ratingAndCompleted,
}) => {
  const history = useHistory();
  const splitDesc = () => {
    const descSplit = tactic.overview?.split("\n");
    return descSplit;
  };

  const assignedHrsString = (hrs) => {
    if (hrs === 0) {
      return "0 horas";
    } else if (hrs === 1) {
      return "1 hora";
    } else {
      return `${hrs} horas`;
    }
  };

  const sumHrs = () => {
    if (tactic.steps?.length === 0) {
      return assignedHrsString(0);
    } else if (tactic.steps?.length === 1) {
      return assignedHrsString(tactic.steps[0].hours_to_implement);
    } else {
      const tacticHrs = tactic.steps?.map(
        (tactic) => tactic.hours_to_implement
      );
      const total = tacticHrs?.reduce((prev, curr) => prev + curr);
      return assignedHrsString(total);
    }
  };

  const openModalShare = () => {
    setShowModalTacticGeneral(false);
    setShowModalShare({
      active: true,
      info: {
        id: "id",
        title: "Compartir Tactica",
        slugUrl: `${tactic?.slug_url}`,
      },
      type: "tactic",
    });
  };

  return (
    <Modal
      size="lg"
      centered
      show={showModalTacticGeneral}
      dialogClassName="modal-tacticGeneral"
    >
      <Modal.Body className="container-modalGeneral">
        <div
          className="container-generalFirst"
          style={{ backgroundImage: `url(${background})` }}
        >
          <div className="container-generalActions">
            {!tactic.is_draft && (
              <div className="container-generalShare" onClick={openModalShare}>
                <img src={ShareIcon} alt="Compartir" />
                <p>Compartir</p>
              </div>
            )}
            <div className="container-generalPlan">
              <button
                className="general-btnEdit"
                onClick={() =>
                  history.push(`/tactic/edit/${tactic.id}`, {
                    tactic: tactic,
                  })
                }
              >
                Editar
              </button>
              <img
                src={Close}
                alt="Cerrar"
                className="general-close"
                onClick={() => setShowModalTacticGeneral(false)}
              />
            </div>
          </div>
          <div className="container-categoryInfo">
            <div className="tacticCategory">
              <p>{tactic.category}</p>
            </div>
            <div className="subCategory">
              <p>{tactic.subcategory}</p>
            </div>
          </div>
          <p className="general-tacticTitle">{tactic.title}</p>
        </div>
        <div className="container-generalSecond">
          <div className="container-generalMoreDetails">
            <div className="container-generalProfileAndInfoTactic">
              {/* slug/id */}
              {expert !== "noInfo" && (
                <div className="container-generalExpert">
                  <img
                    src={expert.imgUser ? expert.imgUser : NoImageProfile}
                    alt="Experto"
                  />
                  <p>
                    {expert?.name} {expert?.first_lastname}
                  </p>
                </div>
              )}
              <div className="container-generalInfoTactic">
                <div className="container-generalInfoEach completed">
                  <img src={NoImage} alt="Usuario" />
                  <p>{ratingAndCompleted.completed}</p>
                </div>
                <div className="container-generalInfoEach time">
                  <img src={TimeIcon} alt="Usuario" />
                  <p>{sumHrs()}</p>
                </div>
                <div className="container-generalInfoEach star">
                  <img src={Star} alt="Calificación" />
                  <p>{ratingAndCompleted.rating}</p>
                </div>
              </div>
              <div className="container-generalResults">
                <p className="general-resultTitle">Resultado clave:</p>
                <p>{tactic.outcome}</p>
              </div>
            </div>
          </div>
          <div className="general-separator" />
          <div className="container-generalStepDescription">
            <p className="general-aboutTactic">Descripción de la táctica:</p>
            {splitDesc()?.map((line, i) => (
              <p key={i}>{line}</p>
            ))}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalTacticGeneral;
