import React from "react";

export const OwnResource = ({ ownResource, setOwnResource }) => {
  return (
    <>
      <div className="resource-ownEach" onClick={() => setOwnResource(true)}>
        <div className={`resource-ownOption ${ownResource && "active"}`}>
          {ownResource && <p>✔</p>}
        </div>
        <p className="resource-ownTxt">SI</p>
      </div>
      <div className="resource-ownEach" onClick={() => setOwnResource(false)}>
        <div className={`resource-ownOption ${!ownResource && "active"}`}>
          {!ownResource && <p>✔</p>}
        </div>
        <p className="resource-ownTxt">No</p>
      </div>
    </>
  );
};
