import React, { useState } from "react";
import { Field, ErrorMessage, Form as FormFormik, Formik } from "formik";
import * as Yup from "yup";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Row, Col, Form } from "react-bootstrap";
import { MainTitle } from "../../assets/styles/pages/general/GeneralStyles";
import StepsContainer from "../../Pages/Tactics/StepsContainer";
import api from "../../utils/axiosUsage";
import { TextRich } from "./TextRich";
import ModalStep from "./ModalStep";
import ToolIcon from "../../assets/images/icons/ToolStep.png";
import LinkIcon from "../../assets/images/icons/LinkStep.png";
import TooltipFile from "../Atoms/TooltipFile";

const FormEditStep = ({
  children,
  stage,
  isEditingStep,
  setIsEditingStep,
  nextStage,
  setTacticData,
  tacticInfo,
  loggedUser,
}) => {
  const [backToList, setBackToList] = useState(false);
  const [showModalTool, setShowModalTool] = useState(false);
  const [tools, setTools] = useState(isEditingStep.step.step_tools || []);
  const [totalTools, setTotalTools] = useState(1000);
  const [error, setError] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [isEditingTool, setIsEditingTool] = useState({
    active: false,
    tool: {},
  });

  const updateStep = async (values) => {
    const dataWithoutFile = tools.map((tool) => {
      const { file, step, ...rest } = tool;
      return { ...rest };
    });

    const formData = {
      title: values.title,
      description: values.description,
      hours_to_implement: values.timeApproximate || 0,
      objective: values.objective,
      step_tools: dataWithoutFile,

      tactic: tacticInfo.id,
    };
    if (isEditingStep?.newStep) {
      try {
        const { data } = await api.axiosAuthPost(
          "/cms/api/v2/steps/",
          loggedUser.token,
          formData
        );
        // {/* until the patch works */}
        // filterToolWithFiles(data.step_tools);
        // data.step_tools = tools;
        setTacticData({ ...tacticInfo, steps: [...tacticInfo.steps, data] });
      } catch (error) {
        console.log("error add new step");
      }
    } else {
      const newData = await api.axiosAuthPatch(
        `/cms/api/v2/steps/${isEditingStep.step.id}/`,
        loggedUser.token,
        formData
      );
      // {/* until the patch works */}
      // filterToolWithFiles(newData.data.step_tools);
      // newData.data.step_tools = tools;
      const newArray = tacticInfo.steps.map((step) => {
        if (step.id === isEditingStep.step.id) {
          return newData.data;
        } else {
          return step;
        }
      });
      setTacticData({ ...tacticInfo, steps: newArray });
    }
  };

  const editTool = (tool) => {
    setShowModalTool(true);
    setIsEditingTool({
      active: true,
      tool,
    });
  };

  // {/* until the patch works */}
  // const filterToolWithFiles = async (toolsDB) => {
  //   const filterFun = (tool) => {
  //     const existToolFile =
  //       typeof tool.file === "string" && tool.file.includes("https");
  //     if (tool.file !== "" && tool.file !== null && existToolFile === false) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   };
  //   const filterBefore = tools.filter(filterFun);
  //   const compareDB = filterBefore.map((toolFront) => {
  //     const res = toolsDB.filter(
  //       (toolWithID) => toolFront.title === toolWithID.title
  //     );

  //     return {
  //       id: res[0].id,
  //       title: res[0].title,
  //       file: toolFront.file,
  //     };
  //   });

  //   for (let index = 0; index < compareDB.length; index++) {
  //     try {
  //       const form = new FormData();
  //       form.append("file", compareDB[index].file);
  //       await api.axiosAuthPatch(
  //         `/cms/api/v2/step-tools-crud/${compareDB[index].id}/`,
  //         loggedUser.token,
  //         form
  //       );
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  const validateTypeTool = (tool) => {
    const fileUpload =
      typeof tool.file === "string" && tool.file.includes("https");
    if (tool.url !== "") {
      // url = tool.url;
      return (
        <>
          <a href={tool.url} target="_blank" rel="noreferrer">
            <img src={LinkIcon} alt={tool.title} />
          </a>
        </>
      );
    } else if (fileUpload) {
      return (
        <>
          <a href={tool.file} target="_blank" rel="noreferrer">
            <img src={LinkIcon} alt={tool.title} />
          </a>
        </>
      );
    } else if (typeof tool.file == "object") {
      return (
        <TooltipFile id={tool.idFront} msg={tool.file?.name}>
          <img src={LinkIcon} alt={tool.title} />
        </TooltipFile>
      );
    }
  };

  const validateTitleLength = (title) => {
    if (title.length > 15) {
      return title.slice(0, 15).concat("...");
    } else {
      return title;
    }
  };

  const errorStyles = {
    display: error ? "block" : "none",
    padding: "10px",
    marginBottom: 15,
    fontWeight: "bold",
    color: "#CC3333",
    backgroundColor: "#FFEEEE",
  };
  return (
    <>
      <Formik
        initialValues={{
          title: isEditingStep.step.title || "",
          description: isEditingStep.step.description || "",
          timeApproximate: isEditingStep.step.hours_to_implement || "",
          objective: isEditingStep.step.objective || "",
        }}
        onSubmit={(values, actions) => {
          if (backToList) {
            setIsEditingStep({ active: false, step: {} });
            updateStep(values);
          } else {
            setError(true);
            setErrorMsg("Debes guardar tus cambios");
          }
        }}
        validationSchema={Yup.object({
          title: Yup.string().required("Este campo es obligatorio"),
          description: Yup.string().required("Este campo es obligatorio"),
          objective: Yup.string(),
          timeApproximate: Yup.number().positive("Debe ser un número válido"),
        })}
      >
        {(formik) => (
          <FormFormik className="container-tactic">
            <div className="container-tacticForm">
              <Row className="rowLimit rowWidth rowDashboard">
                <Col lg={10}>
                  <MainTitle style={{ marginBottom: 50 }}>
                    Edición de táctica
                  </MainTitle>
                </Col>
                <Col lg={2}></Col>
              </Row>
              <Row className="rowLimit rowWidth rowDashboard">
                <Col md={10}>
                  <h1 className="number-step-tactic">
                    Paso {isEditingStep.step.index}.
                  </h1>
                  <Form.Group>
                    <label>¿Cuál es el título de este paso?</label>
                    <Field
                      name="title"
                      type="text"
                      className="inputNewBrand main-btn-align form-control"
                      placeholder="Da un título al paso"
                    />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="frmErrorCategory"
                    />
                  </Form.Group>
                  {/* 
                  <Row>
                    <Form.Group as={Col} md={8} lg={5}>
                      <label>Tiempo aproximado (horas)</label>
                      <Field
                        name="timeApproximate"
                        type="number"
                        className="inputNewBrand main-btn-align form-control"
                        placeholder="0 horas"
                      />
                      <ErrorMessage
                        name="timeApproximate"
                        component="div"
                        className="frmErrorCategory"
                      />
                    </Form.Group>
                  </Row>

                  <Form.Group>
                    <label>Objetivo del paso</label>
                    <Field
                      name="objective"
                      type="text"
                      className="inputNewBrand main-btn-align form-control"
                      placeholder="¿Qué se va a lograr?"
                    />
                    <ErrorMessage
                      name="objective"
                      component="div"
                      className="frmErrorCategory"
                    />
                  </Form.Group>
*/}
                  <Form.Group>
                    <label>Descripción del paso</label>
                    <TextRich
                      step={formik.values.description}
                      change={formik.setFieldValue}
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="frmErrorCategory"
                    />
                  </Form.Group>
                  {tools.length > 0 && (
                    <>
                      <div className="container-titleTool">
                        <img src={ToolIcon} alt="Herramientas" />
                        <p>Videos, archivos y url agregados</p>
                      </div>
                      {tools.map((tool, i) => (
                        <div
                          className="container-tool"
                          key={i}
                          onClick={() => editTool(tool)}
                        >
                          {/* <p>{tool.title}</p> */}
                          <TooltipFile id={i} msg={tool.title}>
                            <p>{validateTitleLength(tool.title)}</p>
                          </TooltipFile>
                          <div>
                            <b>{tool.tool_type}</b>

                            {validateTypeTool(tool)}
                          </div>
                        </div>
                      ))}
                    </>
                  )}

                  <p
                    className="tactic-addTool"
                    onClick={() => setShowModalTool(true)}
                  >
                    + Agregar videos, archivos o url
                  </p>
                  <div style={errorStyles}>{errorMsg}</div>

                  <Row>
                    <Form.Group as={Col}>
                      <button
                        type="submit"
                        className="btn-addStep-style"
                        onClick={() => {
                          setBackToList(true);
                        }}
                      >
                        Guardar cambios
                      </button>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <button
                        type="button"
                        className="btn-cancelStep-style"
                        onClick={() =>
                          setIsEditingStep({ active: false, step: {} })
                        }
                      >
                        Cancelar
                      </button>
                    </Form.Group>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="container-tacticBtn">
              <StepsContainer stage={stage} formik={formik} />
            </div>
            {children}
          </FormFormik>
        )}
      </Formik>
      <ModalStep
        isEditingTool={isEditingTool}
        setIsEditingTool={setIsEditingTool}
        setShowModalTool={setShowModalTool}
        setTools={setTools}
        showModalTool={showModalTool}
        tools={tools}
        totalTools={totalTools}
        setTotalTools={setTotalTools}
      />
    </>
  );
};

export default FormEditStep;
