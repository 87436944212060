import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import CloseIcon from "../../assets/images/icons/Close.svg";

function AlertSuccess(props) {
  const { showAlert, setShowAlert, message, style } = props;
  return (
    <>
      <Alert show={showAlert} variant="success" style={style}>
        <div
          style={{
            position: "absolute",
            right: 20,
            top: 10,
            cursor: "pointer",
          }}
          onClick={() => setShowAlert(!showAlert)}
        >
          <img src={CloseIcon} />
        </div>
        <p>{message}</p>
      </Alert>
    </>
  );
}

export default AlertSuccess;
