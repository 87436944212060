import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Col, Row } from "react-bootstrap";
import logo from "../../assets/images/icons/logra.svg";
import "./success.scss";
import { getCurrentUser } from "../../utils/auth";
import winners from "../../assets/images/icons/winners.svg";
import api from "../../utils/axiosUsage";
import ReactConfetti from "react-confetti";
import succesfullImage from "../../assets/images/icons/successfulloffer.svg";
import { useHistory } from "react-router-dom";
import { useWindowSize } from "react-use";
import { useLocation } from "react-router-dom";
import { Loading } from "@logra/component-library";
import { toast } from "react-toastify";

function Success(props) {
  const location = useLocation();
  const [loaded, setLoaded] = useState(false);
  const [firstSolution, setFirstSolution] = useState(false);
  const [allSolutions, setAllSolutions] = useState([]);
  const [loggedUser, setLoggedUser] = useState({});
  const [isEdited, setIsEdited] = useState(
    location?.state?.isEdited ? location.state.isEdited : false
  );
  const history = useHistory();
  const { width, height } = useWindowSize();

  useEffect(() => {
    getUserAndContent();

    return () => {
      setLoggedUser({});
    };
  }, []);

  useEffect(() => {
    if (allSolutions && allSolutions.length >= 1) {
      setFirstSolution(false);
    } else {
      setFirstSolution(true);
    }
  }, [allSolutions]);

  setTimeout(() => {
    setLoaded(true);
  }, 1000);

  const getUserAndContent = async () => {
    try {
      const userData = await getCurrentUser();
      getSolutions(userData, userData.token);
      setLoggedUser(userData);
    } catch (error) {
      console.log(error);
    }
  };

  const getSolutions = async (user, token) => {
    try {
      const solutions = await api.axiosAuthGet(
        `cms/api/v2/commercial-listing-by-expert/${user.id}/`,
        token
      );
      if (solutions.status === 200) {
        setAllSolutions(solutions.data.commercial_solutions_by_owner);
      } else {
        setAllSolutions([]);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <ReactConfetti width={width} height={height} />
      <Container
        fluid
        className={`successContainer ${
          loaded ? "visibleSuccess" : "hiddenSuccess"
        }`}
        style={{ height: "100vh", overflow: "auto" }}
      >
        <Col lg={5} xs={12} className="successColumn">
          <div className="successLogoImageContainer">
            <img
              src={logo}
              alt="logo"
              style={{
                marginLeft: "30px",
                marginBottom: "20px",
                marginTop: "30px",
              }}
            />
          </div>
          <Row>
            <Col className="successColumn">
              {isEdited ? (
                <>
                  <h1>Felicidades {loggedUser.name} 🎉 </h1>
                  <p style={{ marginTop: "10px" }}>
                    ¡Acabas de editar tu oferta!
                    <br /> Pronto cualquier persona podrá contratar tu oferta.
                  </p>
                </>
              ) : firstSolution ? (
                <>
                  <h1>Felicidades {loggedUser.name} 🎉 </h1>
                  <p style={{ marginTop: "10px" }}>
                    ¡Acabas de agregar tu primera oferta!
                    <br /> Pronto cualquier persona podrá contratar tu oferta.
                  </p>
                </>
              ) : (
                <>
                  <h1>Felicidades {loggedUser.name} 🎉 </h1>
                  <p style={{ marginTop: "10px" }}>
                    ¡Acabas de agregar una nueva oferta!
                    <br /> Pronto cualquier persona podrá contratar tu oferta.
                  </p>
                </>
              )}

              <img
                src={succesfullImage}
                alt="successfull offer"
                className="succesfullOfferImage"
                style={{ marginTop: "20px", marginBottom: "20px" }}
              />
              <p
                className="successOfferParagraph"
                style={{ marginTop: "10px" }}
              >
                Mientras puedes activar tu canal de comunicación para que los
                usuarios interesados en está oferta puedan contactarte o agendar
                una reunión contigo.
              </p>
              <p style={{ marginTop: "10px" }}>¿Con qué paso quieres seguir?</p>
              <Row className="buttonContainer">
                <Col style={{ marginTop: "10px" }}>
                  <button
                    className="success-btn"
                    onClick={() => history.push("/offers/new")}
                  >
                    Agregar otro recurso
                  </button>
                </Col>
                <Col style={{ marginTop: "10px" }}>
                  <button
                    className="redirect-btn"
                    onClick={() => history.push("/library")}
                  >
                    Ir a mi biblioteca
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col lg={5} xs={0} className="success-imgContainer">
          <img className="success-img" alt="Logra winners" src={winners} />
        </Col>
      </Container>
    </>
  );
}

export default Success;
