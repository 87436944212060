import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  MainTitle,
  BtnActionTitle,
  TitleDescription,
  ProfileCard,
  ProfileBottom,
  ProfileLogo,
  ProfileInfo,
  ProfileLocation,
  BoxNoData,
  OperationOption,
  TagOption,
} from "../../assets/styles/pages/general/GeneralStyles";

const Companies = () => {
  return (
    <>
      <Row className="rowLimit rowWidth rowDashboard">
        <Col lg={6}>
          <MainTitle style={{ marginBottom: 50 }}>
            Empresas
            <BtnActionTitle href="/companies/referal">
              Referir empresas
            </BtnActionTitle>
          </MainTitle>
        </Col>
        <Col lg={6}></Col>
      </Row>
    </>
  );
};

export default Companies;
