import React, { useState /* useEffect */ } from "react";
// import { Container, Row, Col, Form, Alert } from "react-bootstrap";
// import StartSlider from "../../Components/StartSlider/StartSlider";
// import AlertSuccess from "../../Components/Alert/AlertSuccess";
import { Button /* Login */ } from "@logra/component-library";
// import CloseIcon from "../../assets/images/icons/Close.svg";

import "../../assets/styles/pages/index.scss";
import "./tempReferred.scss";
import imagePlaceholder from "../../assets/images/general/imageplaceholder-referred.png";
import iconWIP from "../../assets/images/icons/comming-soon-icon.svg";
// import api from "../../utils/axiosUsage";
import { ModalTemp } from "../../Components/ModalTemp/ModalTemp";
import { useHistory } from "react-router-dom";

const Referred = () => {
  const [showModal, setshowModal] = useState(true);
  const history = useHistory();

  return (
    <div style={{ overflow: "none", backgroundColor: "#d3d3d3" }}>
      <ModalTemp
        title={"Proximamente"}
        subTitle={
          "Podrás empezar a invitar clientes en que se diagnostican 📊 "
        }
        disableCloseBtn={true}
        content={
          <div
            style={{
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <img
              src={iconWIP}
              alt="work in progress"
              srcset=""
              style={{ margin: "2rem", width: "8vw", height: "auto" }}
            />

            <p style={{ marginBottom: "3rem" }}>
              Con tu liga única totalmente personalizable, podrás invitar a
              cualquier empresa en que se diagnóstica y obtener una lista de
              prospectos con todos sus datos.
            </p>

            <Button
              label="Regresar a inicio"
              primary={true}
              size={"large"}
              onClick={() => history.push("/home")}
            />
          </div>
        }
        // startWithModal={!tacticsData.length > 0}
        modalState={showModal}
      />
      <img
        src={imagePlaceholder}
        style={{
          width: "100vw",
          height: "auto",
          filter: "brightness(65%)",
        }}
        alt="Referidos"
      />
    </div>
  );
};

export default Referred;
