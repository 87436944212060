import styled from "styled-components";
/*
import SideNav, {
    Toggle,
    Nav,
    NavItem,
    NavIcon,
    NavText
} from '../SideNav';
*/
import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav";

// SideNav
const StyledSideNav = styled(SideNav)`
  background-color: #2c5999;
  border: none;
`;
StyledSideNav.defaultProps = SideNav.defaultProps;

// Toggle
const StyledToggle = styled(Toggle)`
  background-color: #2c5999;
  > span {
    background-color: #fff !important;
  }
`;
StyledToggle.defaultProps = Toggle.defaultProps;

// Nav
const StyledNav = styled(Nav)`
  background-color: #2c5999;
  &&[class*="expanded--"] {
    [class*="sidenav-subnav--"] {
      > [class*="sidenav-subnavitem--"],
      > [class*="sidenav-subnavitem--"]:hover {
        > [class*="navitem--"] {
          color: #fff !important;
        }
      }
      > [class*="sidenav-subnavitem--"]:hover {
        > [class*="navitem--"] {
          background-color: #5cb0ce;
        }
      }
      > [class*="sidenav-subnavitem--"][class*="selected--"] {
        > [class*="navitem--"] {
          color: #fff !important;
        }
        > [class*="navitem--"]::before {
          border-left: 2px solid #fff;
        }
      }
    }
  }
  && > [class*="sidenav-navitem--"] {
    > [class*="navitem--"] {
      background-color: inherit;
      color: #fff !important;
    }
  }
  && > [class*="sidenav-navitem--"]:hover {
    > [class*="navitem--"] {
      background-color: #5cb0ce;
    }
  }
  && > [class*="sidenav-navitem--"],
  && > [class*="sidenav-navitem--"]:hover {
    > [class*="navitem--"] {
      [class*="navicon--"] {
        &,
        > * {
          color: #fff !important;
        }
      }
      [class*="sidenav-nav-text--"] {
        &,
        > * {
          color: #fff !important;
        }
      }
    }
  }
  && > [class*="sidenav-navitem--"][class*="highlighted--"],
  && > [class*="sidenav-navitem--"][class*="highlighted--"]:hover {
    > [class*="navitem--"] {
      [class*="navicon--"],
      [class*="navtext--"] {
        &,
        > * {
          color: #fff !important;
          font-weight: 500;
          font-size: 16px;
        }
      }
      [class*="sidenav-nav-text--"] {
        font-weight: 700;
      }
    }
  }
`;
StyledNav.defaultProps = Nav.defaultProps;

// NavItem
const StyledNavItem = styled(NavItem)`
  &&&:hover {
    [class*="navtext--"] {
      color: #fff;
    }
  }
`;
StyledNavItem.defaultProps = NavItem.defaultProps;

// NavIcon
const StyledNavIcon = styled(NavIcon)`
  color: #fff !important;
  cursor: pointer;
`;
StyledNavIcon.defaultProps = NavIcon.defaultProps;

// NavText
const StyledNavText = styled(NavText)`
  color: #fff !important;
`;
StyledNavText.defaultProps = NavText.defaultProps;

export {
  StyledToggle as Toggle,
  StyledNav as Nav,
  StyledNavItem as NavItem,
  StyledNavIcon as NavIcon,
  StyledNavText as NavText,
};
export default StyledSideNav;
