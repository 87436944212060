import React, { useState, useEffect } from "react";
import { CardContent } from "@logra/component-library";
import { getCurrentUser } from "../../utils/auth";
import api from "../../utils/axiosUsage";
import {
  MainTitle,
  TitleDescriptionHome,
} from "../../assets/styles/pages/general/GeneralStyles";
import { useHistory } from "react-router-dom";
import CardIcon from "../../assets/images/profile/card.png";
import TableIcon from "../../assets/images/profile/table.png";
import "../../assets/styles/pages/resources/home-resource.scss";
import TableContent from "../../Components/resource/TableResource";

export const ViewResource = () => {
  const [loggedUser, setLoggedUser] = useState({});
  const [resources, setResources] = useState([]);
  const history = useHistory();
  const [typeView, setTypeView] = useState("card");

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeView]);



  const getUser = async () => {
    try {
      const userData = await getCurrentUser();
      getResources(userData);
      const { data } = await api.axiosAuthGet(
        `/api/v1/profile-crud/${userData.id}/`,
        userData.token
      );
      setLoggedUser({ ...userData, imgUser: data.profile_photo });
    } catch (error) {
      console.log(error);
    }
  };

  const getResources = async (user) => {
    try {
      const { data } = await api.axiosAuthGet(
        `/cms/api/v2/indexed-content-by-expert/${user.id}/`,
        user.token
      );
      setResources(data.content_by_owner);
    } catch (error) {
      console.log(error);
    }
  };



  const getResource = (type) => {
    switch (type) {
      case "Post de Linkedin":
        return "linkedin";
      case "Thread de Twitter":
        return "twitter";
      case "Artículo de blog":
        return "blog";
      default:
        return "other";
    }
  };
  const getLanguage = (language) => {
    switch (language) {
      case "Español":
        return "spanish";
      case "Inglés":
        return "english";
      case "Francés":
        return "french";
      default:
        return "spanish";
    }
  };

  const getStatus = (active, draft) => {
    if (active && draft) {
      return "noPublic";
    } else if (active && !draft) {
      return "public";
    } else if (!active && draft) {
      return "incomplete";
    }
  };

  return (
    <div className="container-resourceHome">
      <div className="container-resourceHomeMain">
        <div className="container-resourceHomeTitle">
          <div>
            <MainTitle>
              {`${loggedUser.name} estás en tu librería de recursos`}
            </MainTitle>
            <TitleDescriptionHome style={{ marginBottom: 50 }}>
              Agrega, administra y edita todas tus recursos desde aquí 💪
            </TitleDescriptionHome>
          </div>
          <div className="container-resourceHomeBtns">
            <button
              className="resourceHome-btnContent"
              type="button"
              onClick={() => history.push("/resource/new")}
            >
              Agrega un recurso
            </button>
            <button
              className="resourceHome-btnTactic"
              type="button"
              onClick={() => history.push("/tactic/new")}
            >
              Crea una táctica nueva
            </button>
          </div>
        </div>

        <div className="container-resourceHome-sections">
          <button
            className="resourceHome-btnSectionTactic"
            type="button"
            onClick={() => history.push("/home")}
          >
            Tus tácticas
          </button>
          <button
            className="resourceHome-btnSectonIndex"
            type="button"
            onClick={() => history.push("/resources")}
          >
            Tus recursos
          </button>
        </div>

        <div className="container-profile-typeView">
          <div
            className={`profile-view ${typeView === "card" && "active"}`}
            onClick={() => setTypeView("card")}
          >
            <img src={CardIcon} alt="ver por tarjetas" />
          </div>
          <div
            className={`profile-view ${typeView === "table" && "active"}`}
            onClick={() => setTypeView("table")}
          >
            <img src={TableIcon} alt="ver por tabla" />
          </div>
        </div>
        {/* cards */}
        {typeView === "card" ? (
          <div
            className={
              resources.length > 2
                ? "container-resourceCardsGrid"
                : "container-resourceCardsFlex"
            }
          >
            {resources.map((resource, i) => (
              <CardContent
                key={i}
                title={resource.title}
                isLove={false}
                photo={loggedUser?.imgUser}
                name={loggedUser.name}
                count={0}
                rating={0}
                resource={getResource(resource.resource_type)}
                language={getLanguage(resource.language)}
                status={getStatus(resource.is_active, resource.is_draft)}
                edit={() => history.push(`/resource/edit/${resource.id}`)}
              />
            ))}
          </div>
        ) : (
          <TableContent resources={resources} loggedUser={loggedUser} />
        )}
      </div>
    </div>
  );
};
