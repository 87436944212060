import React, { useState, useEffect } from "react";
import Stage1 from "./Stage1";
import Stage2 from "./Stage2";
import Stage3 from "./Stage3";
import api from "../../utils/axiosUsage";
import { getCurrentUser } from "../../utils/auth";
import { Loading } from "@logra/component-library";
import { useLocation, useParams } from "react-router-dom";

const CreateTactic = () => {
  const location = useLocation();
  const urlParams = useParams();

  const [stage, setStage] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tacticData, setTacticData] = useState(
    location.state?.tactic ? location.state.tactic : {}
  );
  const { id } = useParams();
  const [idTactic, setIdTactic] = useState(location.state?.tactic.id || null);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      setIsLoading(true);
      const userData = await getCurrentUser();

      if (editMode === false && location.pathname.includes("edit")) {
        setIsLoading(true);
        getContent(userData.token);
      } else {
        setEditMode(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getContent = async (token) => {
    if (id !== undefined) {
      try {
        setIsLoading(true);
        const tactic = await api.axiosAuthGet(
          `/cms/api/v2/tactic-and-steps/${urlParams.id}/`,
          token
        );
        setTacticData(tactic.data);
        setEditMode(true);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const nextStage = () => {
    setStage(stage + 1);
  };

  const stages = [
    {
      frm: (
        <Stage1
          nextStage={nextStage}
          stage={stage}
          tacticInfo={tacticData}
          editMode={editMode}
          idTactic={idTactic}
          setIdTactic={setIdTactic}
        />
      ),
    },
    {
      frm: (
        <Stage2
          nextStage={nextStage}
          stage={stage}
          tacticInfo={tacticData}
          editMode={editMode}
          idTactic={idTactic || id}
          setTacticData={setTacticData}
        />
      ),
    }
  ];

  return (
    <div>
      {isLoading ? (
        <div className="container-resourceLoading">
          <div>
            <Loading />
          </div>
        </div>
      ) : (
        stages[stage - 1].frm
      )}
    </div>
  );
};

export default CreateTactic;
