import React, { useEffect, useState } from "react";
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  MainTitle,
  LograCard,
} from "../../assets/styles/pages/general/GeneralStyles";

import FileCopy from "../../assets/images/icons/FileCopy.svg";
import FacebookIcon from "../../assets/images/icons/FacebookIcon.png";
import WhatsAppIcon from "../../assets/images/icons/WhatsappIcon.png";
import LinkedInIcon from "../../assets/images/icons/LinkedInIcon.png";
import ChatIcon from "../../assets/images/icons/ChatIcon.png";

import shareEmail from "../../assets/images/icons/shareEmail.svg";
import shareInfo from "../../assets/images/icons/shareInfo.svg";
import shareVideo from "../../assets/images/icons/shareVideo.svg";

import { getCompany } from "../../utils/auth";
import { createSlugName } from "../../utils/common";

const Referal = () => {
  const [companyInfo, setCompanyInfo] = useState(null);

  useEffect(() => {
    setCompanyInfo(getCompany());
  }, []);

  const copySlugToClipboard = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_DOMAIN_PYMES_APP}/${
        companyInfo ? createSlugName(companyInfo.name) : ""
      }`
    );
  };

  const renderTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        Copiar
      </Tooltip>
    );
  };

  return (
    <>
      <Row className="rowLimit rowWidth rowDashboard">
        <Col lg={6}>
          <MainTitle style={{ marginBottom: 50 }}>PyMEs</MainTitle>
        </Col>
        <Col lg={6}></Col>
      </Row>
      <Row className="rowLimit rowWidth rowDashboard">
        <Col lg={6}>
          <LograCard>
            <h4>Refiere Empresas</h4>
            <p>
              Invita una PyME a ser parte de tu instutución en la plataforma.
            </p>
            <p>Enlace de landing</p>
            <div className="landingLink">
              {process.env.REACT_APP_DOMAIN_PYMES_APP}/
              {companyInfo ? createSlugName(companyInfo.name) : "no-data"}
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <img
                  src={FileCopy}
                  alt="FileCopy"
                  className="fileCopyImg"
                  onClick={() => copySlugToClipboard()}
                />
              </OverlayTrigger>
            </div>
            <p>Compartir</p>
            <ul className="sharedLinks">
              <li>
                <a href="#" target="_blank">
                  <img src={FacebookIcon} alt="FacebookIcon" />
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <img src={WhatsAppIcon} alt="WhatsappIcon" />
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <img src={LinkedInIcon} alt="LinkedInIcon" />
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <img src={ChatIcon} alt="ChatIcon" />
                </a>
              </li>
            </ul>
          </LograCard>
        </Col>
        <Col lg={6}>
          <LograCard>
            <h4>Recursos para referir</h4>
            <div className="resourcesBox">
              <div className="resourceRow">
                <div className="boxColumn">
                  <img src={shareEmail} alt="ShareEmail" />
                  <div className="boxText">
                    <h6>Plantillas de correo</h6>
                    <span>E-mail</span>
                  </div>
                </div>
                <button>Ver recurso</button>
              </div>
              <div className="resourceRow">
                <div className="boxColumn">
                  <img src={shareInfo} alt="ShareInfo" />
                  <div className="boxText">
                    <h6>Infografías y datos duros</h6>
                    <span>Imágenes</span>
                  </div>
                </div>
                <button>Ver recurso</button>
              </div>
              <div className="resourceRow" style={{ border: "none" }}>
                <div className="boxColumn">
                  <img src={shareVideo} alt="ShareVideo" />
                  <div className="boxText">
                    <h6>Videos y animaciones</h6>
                    <span>Video</span>
                  </div>
                </div>
                <button>Ver recurso</button>
              </div>
            </div>
          </LograCard>
        </Col>
      </Row>
    </>
  );
};

export default Referal;
