import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
import { AuthContext } from "../context/auth/AuthContext";
import { PrivateRoutes } from "./PrivateRoutes";
import { PublicRoutes } from "./PublicRoutes";
import { RouterApp } from "./RouterApp";
import { RouterAuth } from "./RouterAuth";
// import api from "../utils/axiosUsage";
import { getCurrentUser, logout } from "../utils/auth";
import { LoadingApp } from "../Components/Atoms/LoadingApp";
import { setUser } from "@sentry/react";
import { validTokenTime } from "../utils/token";

export const RouterMain = () => {
  const { user, login, logout: logoutContext } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    verifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyToken = async () => {
    try {
      setIsLoading(true);
      const user = getCurrentUser();
      const isValidToken = await validTokenTime(user.token, user.refreshToken);
      if (!isValidToken) {
        logoutContext();
        logout();
        window.localStorage.isAuthenticaded = JSON.stringify(false);
        localStorage.clear();
      } else {
        login({
          name: user.name,
          token: user.token,
        });
        setUser({ email: user.email });
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <LoadingApp />;
  }

  return (
    <>
      <Router>
        <div>
          <Switch>
            <PublicRoutes
              path="/start"
              component={RouterAuth}
              isAuthenticated={user.isLogged}
              //   isAuthenticated={true}
            />
            <PrivateRoutes
              path="/"
              component={RouterApp}
              isAuthenticated={user.isLogged}
              //   isAuthenticated={true}
            />
          </Switch>
        </div>
      </Router>
    </>
  );
};
