import { TacticStepTitle } from "../../assets/styles/pages/general/GeneralStyles";
import EditImg from "../../assets/images/tactics/Edit.png";
export default function AccordionEdit({
  title,
  step,
  stepInfo,
  setIsEditingStep,
}) {
  return (
    <div className="tacticEdit-listContainer">
      <img
        src={EditImg}
        alt="check"
        className="tacticEdit-img"
        onClick={() =>
          setIsEditingStep({ active: true, step: { ...stepInfo, index: step } })
        }
      />
      <TacticStepTitle>
        <p>
          <span>Paso {step} - </span>
          {title}
        </p>
      </TacticStepTitle>
    </div>
  );
}

