import React from "react";

import { Row, Col, Form } from "react-bootstrap";
import {
  MainTitle,
  TitleDescription,
} from "../../assets/styles/pages/general/GeneralStyles";

const CreateStep = () => {
  return (
    <>
      <Row className="rowLimit rowWidth rowDashboard">
        <Col lg={6}>
          <MainTitle style={{ marginBottom: 50 }}>
            Crear táctica
            <TitleDescription></TitleDescription>
          </MainTitle>
        </Col>
        <Col lg={6}></Col>
      </Row>
      <Row className="rowLimit rowWidth rowDashboard">
        <Col md={8}>
          <h3>Paso 1</h3>
          <br />
          <Form.Group>
            <label>Título</label>
            <Form.Control
              className="inputNewBrand main-btn-align"
              type="text"
              placeholder="Táctica"
              value=""
              id="title"
            />
          </Form.Group>
          <Form.Group>
            <label>Descripción</label>
            <Form.Control
              className="inputNewBrand inputTextarea main-btn-align"
              type="textarea"
              placeholder="Descripción"
              value=""
              id="about"
            />
          </Form.Group>
          <Form.Group>
            <label>Video (opcional)</label>
            <Form.Control
              className="inputNewBrand main-btn-align"
              type="text"
              placeholder="URL de video"
              value=""
              id="video"
            />
          </Form.Group>
          <Form.Group>
            <label>URL de herramienta (Si aplica)</label>
            <Form.Control
              className="inputNewBrand main-btn-align"
              type="text"
              placeholder="URL de herramienta"
              value=""
              id="tool"
            />
          </Form.Group>
          <a href="#" className="btn-style">
            Agregar paso
          </a>
        </Col>
        <Col md={4}>
          <h1>Parte 3</h1>
          <br />
          <p>
            Crear pasos para la táctica. La táctica necesita al menos un paso.
          </p>
          <br />
          <a href="/home" className="btn-tactic-style">
            Continuar
          </a>
        </Col>
      </Row>
    </>
  );
};

export default CreateStep;
