import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import StartSlider from "../../Components/StartSlider/StartSlider";
import { Button, Login } from "@logra/component-library";

import "../../assets/styles/pages/index.scss";
import {
  LograLink,
  MainTitle,
} from "../../assets/styles/pages/general/GeneralStyles";

import MailIcon from "../../assets/images/icons/MailIcon.svg";
import PasswordIcon from "../../assets/images/icons/PasswordIcon.svg";

import api from "../../utils/axiosUsage";
import { handleLogin } from "../../utils/auth";

const RecoveryPassword = () => {
  const history = useHistory();
  const { token } = useParams();
  const [pass, setPass] = useState("");
  const [passConfirmed, setPassConfirmed] = useState("");

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /*
  const setEmail = (event) => {
    setEmailValid(true);
    setUserEmail(event.target.value);
  };

  const validateEmail = (email) => {
    const pattern =
      /[a-zA-Z0-9]+[\\.]?([a-zA-Z0-9]+)?[\\@][a-z]{3,9}[\\.][a-z]{2,5}/g;
    const result = pattern.test(email);
    return result;
  };
  */

  const handleSubmit = async () => {
    setErrorMessage("");
    setError(false);
    if (pass === "" || passConfirmed === "") {
      setErrorMessage("Debes de ingresar tu nueva contraseña");
      setError(true);
    } else {
      if (pass !== passConfirmed) {
        setErrorMessage("Las contraseñas NO coinciden");
        setError(true);
      } else {
        const credentials = { confirmation_code:token, new_password1:pass, new_password2: passConfirmed };

        let response = await api.axiosPost("/api/v1/restore-password", credentials);

        if (typeof response !== "undefined" && response !== null) {
          if (response.status === 200) {
            //alert("✅ Tu contraseña ha sido cambiada existosamente")
            window.localStorage.passwordChanged = JSON.stringify(true);
            setPass("")
            setPassConfirmed("")
            // history.push("/login");
            history.push("/start/login");
          }
        } else {
          setError(true);
          setErrorMessage(
            "Hubo un fallo en el cambio de contraseña"
          );
          setPass("")
          setPassConfirmed("")
        }
      }
    }
  };

  return (
    <Container fluid className="landing-content">
      <Row className="rowPage" style={{ display: "flex", flexWrap: "wrap" }}>
        <Col xs={12} md={12} lg={6} className="columnIntro">
          <div className="home-topbar">
            <img src="/images/logos/logo.svg" alt="logra-io-logo" />
          </div>
          <Login
            data={{
              pass,
              passConfirmed,
              actions: { setPass, setPassConfirmed, handleSubmit },
            }}
            errorData={{ error, errorMessage }}
            isRestoringPwd={true}
          />
          {/*
          <Form>
            <MainTitle>Inicia sesión</MainTitle>
            <Form.Group controlId="formBasicEmail">
              <img className="input-icon" src={MailIcon} alt="Email" />
              <Form.Control
                className="inputName"
                type="email"
                placeholder="Correo electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <img className="input-icon" src={PasswordIcon} alt="Password" />
              <Form.Control
                className="inputName"
                type="password"
                placeholder="Password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
              />
            </Form.Group>
            <div
              style={{
                display: error ? "block" : "none",
                padding: "10px",
                marginBottom: 15,
                fontWeight: "bold",
                color: "#CC3333",
                backgroundColor: "#FFEEEE",
              }}
            >
              {errorMessage}
            </div>
            
            <Button onClick={handleSubmit} className="btn-style btn-text">
              Ingresar
            </Button>


            <br />
            <br />
            <LograLink href="/">Regresar al Sitio</LograLink>
          </Form>
            */}
        </Col>
      </Row>
      <div className="startCover"></div>
    </Container>
  );
};

export default RecoveryPassword;
