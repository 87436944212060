import "./Accordion.css";
import chevronRight from "./chevron-right.svg";
import { Accordion, Container, Card, Row, Col } from "react-bootstrap";

import { TacticStepTitle } from "../../assets/styles/pages/general/GeneralStyles";
import CheckImg from '../../assets/images/tactics/Check.png'
export default function AccordionDisplay({
  children,
  title,
  step,
  arrowPosition,
  forceDefaultHide = false,
  setActiveStep,
  stepInfo,
  activeStep,
}) {
  return (
    <Accordion defaultActiveKey="0">
      <Card
        style={{
          padding: "10px 20px",
          borderRadius: "15px",
          backgroundColor: "white",
        }}
      >
        {/* Declare <Accordion.Collapse> before <Accordion.Toggle> due to how CSS combinators work */}
        <Accordion.Collapse eventKey={forceDefaultHide ? "1" : "0"}>
          <Card.Body>
            <div>{children}</div>
          </Card.Body>
        </Accordion.Collapse>
        <Accordion.Toggle
          as={Card.Header}
          eventKey={forceDefaultHide ? "1" : "0"}
          style={{ border: "none" }}
          onClick={() => setActiveStep({ index: step - 1, info: stepInfo })}
        >
          <Row>
            <Col className="col-10 px-3">
              <div
                className="d-flex align-items-center"
                style={{ height: "100%" }}
              >
                {/* <div className="container-stepNotComplete" /> */}
                {/* if the step is complete , validation*/}
                {/* <div className="container-stepComplete">
                  <img
                    src={CheckImg}
                    alt="check"
                  />
                </div> */}
                <TacticStepTitle>
                  {/*<img src={Uncheck} />*/}
                  <p>
                    <span>Paso {step} - </span>
                    {/*step.title*/}
                    {title}
                  </p>
                </TacticStepTitle>
                {/* {activeStep.index === step - 1 && (
                  <div className="container-stepProgress">
                    <p>En proceso</p>
                  </div>
                )} */}
              </div>
            </Col>
            <Col
              className="col-2 align-self-center pl-2 py-2"
              style={{ textAlign: arrowPosition ? arrowPosition : "left" }}
            >
              <img
                className="accordion-arrow"
                src={chevronRight}
                width="12"
                height="12"
                alt="chevron-right"
                // onClick={() =>
                //   setActiveStep({ index: step - 1, info: stepInfo })
                // }
              />
            </Col>
          </Row>
        </Accordion.Toggle>
      </Card>
    </Accordion>
  );
}
