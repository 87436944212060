import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: "https://3b8a3a0dddac4c46b7a685cffafb0c9f@o1303870.ingest.sentry.io/6543373",
  release: "tacticas-experto@1.0.0",
  autoSessionTracking: true,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
});

Sentry.addGlobalEventProcessor((event) => {
  if (event.sdk && event.sdk.name === "sentry.javascript.browser") {
    event.sdk.withReplay = true;
  }
  return event;
});
ReactDOM.render(<App />, document.getElementById("root"));
reportWebVitals();
