import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getCurrentUser } from "../../utils/auth";
import api from "../../utils/axiosUsage";
import Logra from "../../assets/images/logrador/lograLogo.png";
import LograFinal from "../../assets/images/logrador/logradorFinal.png";
import Library from "../../assets/images/home/homeLibrary.png";
import Offer from "../../assets/images/home/homeOffer.png";
import "../../assets/styles/pages/logrador/logrador-final.scss";

export const LogradorFinal = () => {
  const [user, setUser] = useState();
  const [haveContent, setHaveContent] = useState(false)
  const history = useHistory();

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const userData = await getCurrentUser();
    const respTactics = await api.axiosAuthGet(`/cms/api/v2/tactics-by-owner/${userData.id}/`, userData.token)
    const respResources = await api.axiosAuthGet(`/cms/api/v2/indexed-content-by-expert/${userData.id}/`, userData.token)

    if (respTactics.data.tactics_count > 0 || respResources.data.content_count > 0) {
      setHaveContent(true)
    }
    setUser(userData);
  };

  return (
    <main className="logradorFinal-container">
      <img src={Logra} alt="Logra logo" />
      <div className="logradorFinal-containerSections">
        <section className="logradorFinal-containerInfo">
          <h1>Genial {user?.name} 👏</h1>
          <p>
            Estamos ya súper alineados sobre porqué estamos haciendo eso juntos,
            cuál es nuestro objetivo común y cómo lo vamos a lograr.
          </p>
          <h4>
            Te faltan dos pasos claves para completar tu perfil de experto.
          </h4>
          <div className="logradorFinal-cardActions">
            <div className="logradorFinal-actionTitleAndImg">
              <img src={Offer} alt="Ofertas" />
              <div className="logradorFinal-actionTitleAndSub">
                <p>Crea tu primera oferta.</p>
                <span>
                  Da de alta tu primer producto o servicio para empezar a atraer
                  nuevos clientes.
                </span>
              </div>
            </div>
            <button type="Button" onClick={() => history.push("/offer/new")}>Crear</button>
          </div>
          <div className="logradorFinal-cardActions">
            <div className="logradorFinal-actionTitleAndImg">
              <img src={Library} alt="Ofertas" />
              <div className="logradorFinal-actionTitleAndSub">
                <p>{haveContent ? "Continúa con tu bliblioteca de recursos." : "Empieza tu biblioteca de recursos."}</p>
                <span>
                  Agrega contenido de valor en cada reto de negocio para
                  volverte experto dominante.
                </span>
              </div>
            </div>
            <button type="Button" onClick={() => history.push("/library")}>
              Empezar
            </button>
          </div>
        </section>
        <section className="logradorFinal-img">
          <img src={LograFinal} alt="Logrador" />
        </section>
      </div>
    </main>
  );
};
