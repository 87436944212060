import React from "react";

const typeData = [
  "Post de Linkedin",
  "Thread de Twitter",
  "Video de Youtube",
  "Artículo de blog",
  "Post de Facebook",
  "Website/Herramienta",
  "Otro",
];

export const TypeResource = ({ setType, type }) => {
  return (
    <div className="container-resourceType">
      {typeData.map((typeResource, i) => (
        <div
          key={i}
          className={`resource-type ${typeResource === type && "active"}`}
          onClick={() => setType(typeResource)}
        >
          <p>{typeResource}</p>
        </div>
      ))}
    </div>
  );
};
