import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const TooltipFile = ({children, id, msg}) => {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip id={id}>{msg}</Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  )
}

export default TooltipFile
