import React from "react";

import { Container, Row, Col, Form } from "react-bootstrap";
import {
  MainTitle,
  TitleDescription,
} from "../../assets/styles/pages/general/GeneralStyles";

const CreateAdv = () => {
  return (
    <>
      <Row className="rowLimit rowWidth rowDashboard">
        <Col lg={6}>
          <MainTitle style={{ marginBottom: 50 }}>
            Crear táctica
            <TitleDescription></TitleDescription>
          </MainTitle>
        </Col>
        <Col lg={6}></Col>
      </Row>
      <Row className="rowLimit rowWidth rowDashboard">
        <Col md={8}>
          <Form.Group>
            <label>Tags</label>
            <Form.Control
              className="inputNewBrand main-btn-align"
              type="text"
              placeholder="Tags"
              value=""
              id="tags"
            />
          </Form.Group>
          <Form.Group>
            <label for="lastName">Modelo de negocio</label>
            <Form.Control as="select" className="inputNewBrand main-btn-align">
              <option>Selecciona</option>
              <option value="1">Empresas B2B</option>
              <option value="2">Instituciones y gobierno (B2B)</option>
              <option value="3">Clientes finales (B2C)</option>
              <option value="4">Cualquiera</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <label for="lastName">Tipo de producto</label>
            <Form.Control as="select" className="inputNewBrand main-btn-align">
              <option>Selecciona</option>
              <option value="1">Bienes</option>
              <option value="2">Servicios</option>
              <option value="3">Cualquiera</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <label for="lastName">Tamaño de empresa</label>
            <Form.Control as="select" className="inputNewBrand main-btn-align">
              <option>Selecciona</option>
              <option value="1">Estoy solo/a</option>
              <option value="2">2 a 5 colaboradores</option>
              <option value="3">6 a 20 colaboradores</option>
              <option value="4">21 en adelante</option>
              <option value="5">Cualquiera</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <label for="lastName">Venta en línea</label>
            <Form.Control as="select" className="inputNewBrand main-btn-align">
              <option>Selecciona</option>
              <option value="1">Si</option>
              <option value="2">No</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <label for="lastName">Tipo de venta en línea</label>
            <Form.Control as="select" className="inputNewBrand main-btn-align">
              <option>Selecciona</option>
              <option value="1">Mi propio e-commerce</option>
              <option value="2">Instagram, Facebook</option>
              <option value="3">Amazon</option>
              <option value="4">Rappi, etc.</option>
              <option value="5">Cualquiera</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <label for="lastName">País</label>
            <Form.Control as="select" className="inputNewBrand main-btn-align">
              <option>Selecciona</option>
              <option value="1">México</option>
              <option value="2">Colombia</option>
              <option value="3">Cualquiera</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <label for="lastName">Dificultad</label>
            <Form.Control as="select" className="inputNewBrand main-btn-align">
              <option>Selecciona</option>
              <option value="1">Fácil</option>
              <option value="2">Regular</option>
              <option value="3">Difícil</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <label for="lastName">Costo</label>
            <Form.Control as="select" className="inputNewBrand main-btn-align">
              <option>Selecciona</option>
              <option value="1">Gratis</option>
              <option value="2">Paga</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={4}>
          <h1>Parte 2</h1>
          <br />
          <p>
            Configuración secundaria. Esta información ayudará a que mostremos
            la táctica a más perfiles.
          </p>
          <br />
          <a href="/create-step" className="btn-tactic-style">
            Continuar
          </a>
        </Col>
      </Row>
    </>
  );
};

export default CreateAdv;
