import React from "react";
import ActionLibrary from "../../assets/images/home/homeLibrary.png";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { Loading } from "@logra/component-library";

export const HomeActions = ({ haveContent }) => {
  const [enableContentCreation, setEnableContentCreation] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getLograUser();
  }, []);

  const getLograUser = () => {
    if (localStorage.getItem("lograUser")) {
      setEnableContentCreation(true);
    } else {
      setEnableContentCreation(false);
    }
    setIsLoaded(true);
  };

  const history = useHistory();

  return (
    <div className="homeActions-container">
      {!isLoaded ? (
        <div className="loadingHomeContainer">
          <Loading />
        </div>
      ) : (
        <div className="homeActions-cards">
          <img src={ActionLibrary} alt="Acción 2" />
          <div className="homeActions-titleCard">
            <h5>
              {haveContent
                ? "Continúa con tu biblioteca"
                : "Empieza con tu biblioteca"}
            </h5>
            <p>
              Agrega contenido de valor para cada reto de negocio para construir
              tu reputación.
            </p>
            <button type="button" onClick={() => history.push("/library")}>
              Empezar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
