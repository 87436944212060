import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from "./StyledSideNav";

import HomeIcon from "../../assets/images/icons/HomeIcon";
import ScrollIcon from "../../assets/images/icons/ScrollIcon";
import LogOutIcon from "../../assets/images/icons/LogOutIcon";
import DashboardIcon from "../../assets/images/icons/sidebar-dashboard.svg";
import ShareIcon from "../../assets/images/icons/sidebar-share.svg";
import api from "../../utils/axiosUsage";
import { getCurrentUser, logout } from "../../utils/auth";
import { AuthContext } from "../../context/auth/AuthContext";

const SideNavMenu = () => {
  const history = useHistory();
  const [profileData, setProfileData] = useState([]);
  const { logout: logoutContext } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      const userData = await getCurrentUser();

      // Info Perfil Experto
      let profileInfo = await api.axiosAuthGet(
        `/api/v1/expert-profile/${userData.userUrlSlug}/`,
        userData.token
      );
      if (profileInfo.status === 200) {
        setProfileData(profileInfo.data);
      } else {
        setProfileData([]);
      }
    })();
  }, []);

  const closeSession = () => {
    logout();
    window.localStorage.isAuthenticaded = JSON.stringify(false);
    localStorage.clear();
    logoutContext();
  };

  return (
    <SideNav
      onSelect={(selected) => {
      }}
    >
      <Toggle />
      <Nav defaultSelected="home">
        <NavItem eventKey="home">
          <NavIcon>
            <span
              onClick={() => history.push("/home")}
              style={{ cursor: "pointer" }}
            >
              <HomeIcon color="#fff" />
            </span>
          </NavIcon>
          <NavText>
            <span
              onClick={() => history.push("/home")}
              style={{ marginLeft: 8, cursor: "pointer" }}
              Inicio
            >
            </span>
          </NavText>
        </NavItem>
        <NavItem eventKey="library">
          <NavIcon>
            <span
              onClick={() => history.push("/library")}
              style={{ cursor: "pointer" }}
            >
              <ScrollIcon color="#fff" />
            </span>
          </NavIcon>
          <NavText>
            <span
              onClick={() => history.push("/library")}
              style={{ marginLeft: 8, cursor: "pointer" }}
            >
              Biblioteca
            </span>
          </NavText>
        </NavItem>

        <NavItem eventKey="dashboard">
          <NavIcon>
            <span onClick={() => history.push("/dashboard")}>
              <div style={{ paddingTop: ".3rem", paddingBottom: ".3rem" }}>
                <img src={DashboardIcon} alt="Dashboard" />
              </div>
            </span>
          </NavIcon>
          <NavText>
            <span
              onClick={() => history.push("/dashboard")}
              style={{ marginLeft: 8 }}
            >
              Dashboard
            </span>
          </NavText>
        </NavItem>

        <NavItem eventKey="referred">
          <NavIcon>
            <span onClick={() => history.push("/referred")}>
              <div style={{ paddingTop: ".3rem", paddingBottom: ".3rem" }}>
                <img src={ShareIcon} alt="Referidos" />
              </div>
            </span>
          </NavIcon>
          <NavText>
            <span
              onClick={() => history.push("/referred")}
              style={{ marginLeft: 8 }}
            >
              Reffered
            </span>
          </NavText>
        </NavItem>

        <NavItem
          onClick={() => closeSession()}
          style={{ position: "absolute", bottom: 0, width: "100%" }}
        >
          <NavIcon>
            <LogOutIcon />
          </NavIcon>
          <NavText style={{ color: "#fff" }}>Salir</NavText>
        </NavItem>
      </Nav>
    </SideNav>
  );
};

export default SideNavMenu;
