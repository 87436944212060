import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form, Alert } from "react-bootstrap";
import StartSlider from "../../Components/StartSlider/StartSlider";
import AlertSuccess from "../../Components/Alert/AlertSuccess";
import { Button, Login } from "@logra/component-library";
import CloseIcon from "../../assets/images/icons/Close.svg";

import "../../assets/styles/pages/index.scss";
import {
  LograLink,
  MainTitle,
} from "../../assets/styles/pages/general/GeneralStyles";

import MailIcon from "../../assets/images/icons/MailIcon.svg";
import PasswordIcon from "../../assets/images/icons/PasswordIcon.svg";

import api from "../../utils/axiosUsage";
import { handleLogin } from "../../utils/auth";

import GoogleLoginBtn from "../../Components/Button/GoogleLoginBtn";
import { AuthContext } from "../../context/auth/AuthContext";

const LoginPage = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const { login } = useContext(AuthContext);

  useEffect(() => {
    const passwordChanged = localStorage.getItem("passwordChanged");
    if (passwordChanged && passwordChanged === "true") {
      setAlertMessage("✅ Tu contraseña ha sido cambiada existosamente");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        localStorage.removeItem("passwordChanged");
      }, 2500);
    }
  }, []);

  const requestPasswordReset = async () => {
    setError(false);
    setErrorMessage("");

    if (email === "") {
      setErrorMessage("Debes de ingresar un correo electrónico");
      setError(true);
    } else {
      const credentials = { email };

      let response = await api.axiosPost(
        "/api/v1/request-password-reset",
        credentials
      );

      if (typeof response !== "undefined" && response !== null) {
        setEmail("");
        setAlertMessage("✅ El correo ha sido enviado.");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2500);
      } else {
        setError(true);
        setErrorMessage("Usuario No existe, vuelve a intentar.");
      }
    }
  };

  const handleSubmit = async () => {
    if (email === "" || pass === "") {
      setErrorMessage("Oops! Debes de ingresar tus credenciales");
      setError(true);
    } else {
      const credentials = { email, password: pass };

      let response = await api.axiosPost("/api/v1/login/", credentials);

      if (typeof response !== "undefined" && response !== null) {
        if (response.status === 200) {
          let respAccount = await api.axiosAuthGet(
            `/api/v1/account`,
            response.data.access
          );
          if (respAccount.status === 200) {
            let accountInfo = await api.axiosAuthGet(
              `/api/v1/profile-crud/${respAccount.data.id}/`,
              response.data.access
            );

            /* const corpId = accountInfo.data.corp;
            const userUrlSlug = accountInfo.data.user_url_slug; */
            respAccount.data.companyId = accountInfo.data.corp;
            respAccount.data.userUrlSlug = accountInfo.data.user_url_slug;
            respAccount.data.user_token = response.data.access;
            respAccount.data.refreshToken = response.data.refresh;

            const handleLoginResult = handleLogin(respAccount);
            if (handleLoginResult !== undefined && handleLoginResult !== null) {
              history.replace("/home");
              window.localStorage.isAuthenticaded = JSON.stringify(true);
              login({
                name: response.data.name,
                token: response.data.user_token,
              });
            }
          } else {
            setError(true);
            setErrorMessage("Ocurrió un error, vuelve a intentar.");
          }
        }
      } else {
        setError(true);
        setErrorMessage("Credenciales de acceso inválidos, vuelve a intentar.");
      }
    }
  };

  const handleGoogleLogin = async (gmail, gauth, name, lastname, imageUrl) => {
    if (gmail === "" || gauth === "") {
      setErrorMessage(
        "Oops! Ocurrio un error al autenticar con google, intentalo nuevamente"
      );
      setError(true);
    } else {
      // console.log('gmail ', gmail)
      //console.log('i came and i conquered  ', imageUrl)

      const credentials = { email: gmail, google_oauth_token: gauth };
      // console.log('your data', credentials)

      let response = await api.axiosPost("/api/v1/login", credentials);

      if (typeof response !== "undefined" && response !== null) {
        if (response.status === 200) {
          //store user auth type if signed in with o auth
          localStorage.setItem(
            "is_oauth_account",
            response.data.is_oauth_account
          );

          let accountInfo = await api.axiosAuthGet(
            `/api/v1/profile-crud/${response.data.id}/`,
            response.data.user_token
          );

          //console.log('token ', response.data.user_token)
          //console.log('image', imageUrl)

          //console.log('here ill get the data of the user to patch the image ', response.data)
          //let updatePicture = {
          //  profile_photo: imageUrl
          //}

          //let patchImage = await api.axiosAuthPatch(
          //  `/api/v1/profile-crud/${response.data.id}/`,
          //  response.data.user_token,
          //  updatePicture,
          //)
          //console.log('image patch response', patchImage)

          const corpId = accountInfo.data.corp;
          console.log("cpd ", corpId);
          const userUrlSlug = accountInfo.data.user_url_slug;
          response.data.companyId = corpId;
          response.data.userUrlSlug = userUrlSlug;

          const handleLoginResult = await handleLogin(response);

          if (handleLoginResult !== undefined && handleLoginResult !== null) {
            history.replace("/home");
            window.localStorage.isAuthenticaded = JSON.stringify(true);
            login({
              name,
              token: gauth,
            });
          }
        }
      } else {
        console.log("try to sign the user up");
        handleGoogleSignup(gmail, gauth, name, lastname, imageUrl);
        // setError(true);
        //setErrorMessage("Ocurrio un error al iniciar sesion.");
      }
    }
  };

  const handleGoogleSignup = async (gmail, gauth, name, lastname, imageUrl) => {
    setError(false);

    if (gmail === "" || gauth === "") {
      setError(true);
    } else {
      const credentials = {
        email: gmail,
        google_oauth_token: gauth,
        name,
        lastname,
        imgUser: imageUrl,
      };
      //the user object needs these data which google provides, send it to them

      let response = await api.axiosPost("/api/v1/signup", credentials);

      if (response !== undefined && response !== null) {
        if (response.status === 201) {
          handleGoogleLogin(gmail, gauth, name, lastname, "home", imageUrl);
          // handleGoogleLogin(gmail, gauth, name, lastname, 'home', imageUrl)

          // handleGoogleLogin(gmail, gauth, name, lastname, imageUrl, 'onboarding')
          //console.log('the image sent', imageUrl)
        }
      } else {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      }
    }
  };

  return (
    <Container fluid className="landing-content">
      <AlertSuccess
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        message={alertMessage}
      />
      <Row className="rowPage" style={{ display: "flex", flexWrap: "wrap" }}>
        <Col xs={12} md={12} lg={6} className="columnIntro">
          <img
            src="/images/logos/logo.svg"
            alt="logra-io-logo"
            className="logoPage"
          />
          <Login
            title="Inicia sesión"
            welcomeMessage="¡Hola! Que bueno verte de nuevo 😃"
            data={{
              email,
              pass,
              actions: {
                setEmail,
                setPass,
                handleSubmit,
                requestPasswordReset,
              },
            }}
            errorData={{ error, errorMessage }}
            googleLogin={false}
            // func={() => handleGoogleLogin}
            // clientId={process.env.REACT_APP_CLIENT_ID}
            // extraComponent={
            //   <GoogleLoginBtn submit={handleGoogleLogin} authType={"login"} />
            // }
          />
        </Col>
      </Row>
      <div className="startCover">
        <StartSlider />
      </div>
    </Container>
  );
};

export default LoginPage;
