import React from "react"
import ArrowRightIcon from "../../assets/images/tactics/ArrowRight.png"
import NoImageProfile from "../../assets/images/tactics/NoImageNew.svg"
import "../../assets/styles/pages/tactics/sidebar-tactic.scss"

const SidebarTactic = ({
  background,
  expert,
  renderTacticSteps,
  setShowSidebarTactic
}) => {

  return (
    <div className="sidebar__tactic-container-tacticInfo">
      <div className="container-tacticCenter">
        <div
          className="container-tacticBackground"
          style={{
            backgroundImage: `url(${background})`,
          }}
        >
          <div className="container-arrowLeft" onClick={() => setShowSidebarTactic(false)}>
            <img src={ArrowRightIcon} alt="Flecha izquierda" />
          </div>
        </div>
        
        {/* slug/id  */}
        {expert !== "noInfo" && (
          <div className="sidebar__tactic-container-infoExpert">
            <img
              src={expert.imgUser ? expert.imgUser : NoImageProfile}
              alt="Perfil experto"
            />
            <p className="name-expert">{expert.name}</p>
          </div>
        )}

        {renderTacticSteps()}
      </div>
    </div>
  )
}

export default SidebarTactic
