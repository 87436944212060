import React from "react";
import Logra from "../../assets/images/logrador/lograLogo.png";
import LogradorImg from "../../assets/images/logrador/logradorLevel.png";
import "../../assets/styles/pages/logrador/index.scss";
import { LogradorLocation } from "./LogradorLocation";

export const LogradorLevel = ({ setLocationLogrador, locationLogrador }) => {
  return (
    <main className="logrador-container">
      <section className="logrador-sectionInfo">
        <div>
          <img src={Logra} alt="Logra logo" />
        </div>
        <h1>Cómo mejorar tu nivel de Experto Logrador ⭐️</h1>
        <div>
          <img src={LogradorImg} alt="Logrador misión" />
        </div>
        <div className="logrador-cardMsg">
          <div className="logrador-titleCard">
            <p>
              Tu nivel de Logrador Experto mejora
              <span> cuando agregas soluciones bien calificadas.</span>
            </p>
          </div>
          <p>
            Existen 4 niveles de Experto Logrador que te dan diferentes
            beneficios mientras mejoras o empeoras. En cualquier momento puedes
            subir o bajar de nivel.
          </p>
        </div>
        <div className="logrador-cardMsg">
          <div className="logrador-titleCard">
            <p>
              Cómo cambias de nivel y
              <span> que obtienes al volverte un mejor Logrador Experto.</span>
            </p>
          </div>
          <ul className="logrador-listInfo">
            <li>
              Al agregar soluciones en los retos de negocio que dominas, mejoras
              tu nivel.
            </li>
            <li>
              Al tener buenas calificaciones para las diferentes soluciones de
              tu biblioteca.
            </li>
            <li>
              Estás mejor posicionado, tu perfil y tus soluciones aparecen
              primero.
            </li>
            <li>
              Obtendrás descuento en nuestro modelo de suscripción premium.
            </li>
          </ul>
        </div>
        <button
          type="button"
          className="logrador-btnSection"
          onClick={() => setLocationLogrador(5)}
        >
          ¡Listo!
        </button>
      </section>
      <section className="logrador-sectionLocation">
        <LogradorLocation locationLogrador={locationLogrador} />
      </section>
    </main>
  );
};
