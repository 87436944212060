const typeInstitution = [
  { description: "Cámara de comercio", value: "Cámara de comercio" },
  { description: "Secretaría de Gobierno", value: "Secretaría de Gobierno" },
  { description: "Cámara de compensación", value: "Cámara de compensación" },
  { description: "Banco de desarrollo", value: "Banco de desarrollo" },
  { description: "Banco empresarial", value: "Banco empresarial" },
  { description: "SaaS", value: "SaaS" },
  { description: "Fintech", value: "Fintech" },
  { description: "Comunidad o programa", value: "Comunidad o programa" },
  { description: "Otro", value: "Otro" },
];

const legalCategory = [
  { description: "Persona Física", value: "Persona Física" },
  { description: "Empresa", value: "Empresa" },
];

const serviceType = [
  { description: "SaaS", value: false },
  { description: "Consultoría", value: false },
  { description: "Cursos", value: false },
  { description: "Newsletter", value: false },
  { description: "Otro servicio", value: false },
  { description: "Otra solución digital", value: false },
];

/*
const expertiseArea = [
  { description: "Dirección y estrategia", value: false },
  { description: "Marketing y ventas", value: false },
  { description: "Administración y finanzas", value: false },
  { description: "Operación, logística y producción", value: false },
  { description: "Recursos humanos, equipo y cultura", value: false },
  { description: "Tecnología", value: false },
  { description: "Servicio al cliente", value: false },
  { description: "Innovación", value: false },
  { description: "Otros", value: false },
];
*/

const expertiseArea = [
  { description: "Planeación y estrategia", value: false },
  { description: "Equipo y cultura", value: false },
  { description: "Marketing y ventas", value: false },
  { description: "Administración y finanzas", value: false },
  { description: "Digitalización y tecnología", value: false },
];

/*
const expertContent = [
  { description: "Redes sociales", value: false },
  { description: "Blog", value: false },
  { description: "Plataforma de educación", value: false },
  { description: "Otros", value: false },
];
*/

const expertContent = [
  { description: "Post en redes sociales", value: false },
  { description: "Videos en Youtube", value: false },
  { description: "Artículos en revistas", value: false },
  { description: "Cursos en plataformas", value: false },
  { description: "Posts en blogs", value: false },
  { description: "Otros", value: false },
];

const contentAsTactic = [
  { description: "Si", value: false },
  { description: "No", value: false },
];

const expertDescribe = [
  {
    description: "Consultor independiente",
    selection: "1_INDEPENDENT_CONSULTANT",
    value: false,
  },
  {
    description: "Consultor tiempo parcial",
    selection: "2_PART_TIME_CONSULTANT",
    value: false,
  },
  {
    description: "Empleado de una empresa",
    selection: "3_EMPLOYEE_CORPORATION",
    value: false,
  },
  {
    description: "Empresa de consultoria",
    selection: "4_CONSULTANCY_COMPANY",
    value: false,
  },
  {
    description: "Empresa de servicio/tecnología",
    selection: "5_SERVICE_TECH_COMPANY",
    value: false,
  },
  { description: "Otros", selection: "6_OTHER_COMPANY", value: false },
];

const idealBusiness = [
  {
    firstRow: [
      {
        id: 1,
        description: "Todos",
        value: "ALL",
      },
      {
        id: 2,
        description: "Micro empresario",
        value: "MICRO_BUSINESS",
      },
      {
        id: 3,
        description: "Consultor",
        value: "CONSULTANT",
      },
      {
        id: 4,
        description: "Freelance",
        value: "FREELANCE",
      },
    ],
  },
  {
    secondRow: [
      {
        id: 5,
        description: "Pequeña empresa (-10 personas)",
        value: "SMALL_COMPANY",
      },
      {
        id: 6,
        description: "Mediana empresa (-50 personas)",
        value: "MEDIUM_COMPANY",
      },
      {
        id: 7,
        description: "Empresa grande (+50 personas)",
        value: "BIG_COMPANY",
      },
      {
        id: 8,
        description: "Otros: ONG, Asociaciones, etc.",
        value: "OTHER",
      },
    ],
  },
];

const idealProfiles = [
  {
    firstRow: [
      {
        id: 1,
        description: "Todos",
        value: "ALL_PROFILES",
      },
      {
        id: 2,
        description: "Dueño/Director general",
        value: "OWNER_CEO",
      },
      {
        id: 3,
        description: "Director/Gerente",
        value: "MANAGER",
      },
    ],
  },
  {
    secondRow: [
      {
        id: 4,
        description: "Líder con personas a cargo",
        value: "TEAM_LEADER",
      },
      {
        id: 5,
        description: "Cualquier miembro de equipo",
        value: "ANY_MEMBER",
      },
    ],
  },
];

const mockCategories = [
  {
    id: 1,
    description: "Planeación y estrategia",
    value: "PLANNING",
  },
  {
    id: 2,
    description: "Equipo y cultura",
    value: "TEAM_AND_CULTURE",
  },
  {
    id: 3,
    description: "Marketing y ventas",
    value: "MARKETING_AND_SALES",
  },
  {
    id: 4,
    description: "Administración y finanzas",
    value: "ADMINISTRATION",
  },
  {
    id: 5,
    description: "Digitalización y tecnología",
    value: "DIGITALIZACION",
  },
];

const mockSubCategories = [
  {
    id: 1,
    description: "Subcategoría #1",
    value: "SUBCATEGORIE1",
  },
  {
    id: 2,
    description: "Subcategoría #1",
    value: "SUBCATEGORIE2",
  },
  {
    id: 3,
    description: "Subcategoría #3",
    value: "SUBCATEGORIE3",
  },
  {
    id: 4,
    description: "Subcategoría #4",
    value: "SUBCATEGORIE4",
  },
  {
    id: 5,
    description: "Subcategoría #5",
    value: "SUBCATEGORIE5",
  },
];

const mockChallenges = [
  {
    first: [
      { id: 1, description: "Reto #1", value: "CHALLENGE1" },
      { id: 2, description: "Reto #2", value: "CHALLENGE2" },
      { id: 3, description: "Reto #3", value: "CHALLENGE3" },
    ],
  },
  {
    second: [
      { id: 4, description: "Reto #4", value: "CHALLENGE4" },
      { id: 5, description: "Reto #5", value: "CHALLENGE5" },
      { id: 6, description: "Reto #6", value: "CHALLENGE6" },
    ],
  },
  {
    third: [
      { id: 7, description: "Reto #7", value: "CHALLENGE7" },
      { id: 8, description: "Reto #8", value: "CHALLENGE8" },
      { id: 9, description: "Reto #9", value: "CHALLENGE9" },
    ],
  },
  {
    forth: [
      { id: 10, description: "Reto #10", value: "CHALLENGE10" },
      { id: 11, description: "Reto #11", value: "CHALLENGE11" },
      { id: 12, description: "Reto #12", value: "CHALLENGE12" },
    ],
  },
  {
    fifth: [
      { id: 13, description: "Reto #13", value: "CHALLENGE13" },
      { id: 14, description: "Reto #14", value: "CHALLENGE14" },
      { id: 15, description: "Reto #15", value: "CHALLENGE15" },
    ],
  },
];

const organizeWebinar = [
  { description: "Si", value: false },
  { description: "No", value: false },
];

const companyArea = [
  { description: "Dirección y estrategia", value: "1" },
  { description: "Marketing y ventas", value: "2" },
  { description: "Administración y finanzas", value: "3" },
  { description: "Operación, logística y producción", value: "4" },
  { description: "Recursos humanos, equipo y cultura", value: "5" },
  { description: "Tecnología", value: "6" },
  { description: "Servicio al cliente", value: "7" },
  { description: "Innovación", value: "8" },
];

const segmentationType = [
  { description: "Empleados", value: false },
  { description: "Sectores", value: false },
  { description: "Ventas", value: false },
  { description: "Otros", value: false },
  { description: "Ninguno", value: false },
];

const comunicationType = [
  { description: "Mail", value: false },
  { description: "SMS o WhatsApp", value: false },
  { description: "Redes sociales y grupos", value: false },
  { description: "Eventos", value: false },
  { description: "Otro", value: false },
];

const cargos = [
  { description: "Cargo 1", value: "1" },
  { description: "Cargo 2", value: "2" },
  { description: "Cargo 3", value: "3" },
  { description: "Cargo 4", value: "4" },
];

const companyColors = [
  { value: "#000000", isSelected: false },
  { value: "#EA001B", isSelected: false },
  { value: "#4080FF", isSelected: false },
  { value: "#F1C33D", isSelected: false },
  { value: "#F18A41", isSelected: false },
  { value: "#3BBD48", isSelected: false },
  { value: "#000080", isSelected: false },
];

export default {
  typeInstitution,
  legalCategory,
  serviceType,
  expertiseArea,
  expertContent,
  contentAsTactic,
  organizeWebinar,
  companyArea,
  segmentationType,
  comunicationType,
  cargos,
  companyColors,
  expertDescribe,
  idealBusiness,
  idealProfiles,
  mockCategories,
  mockSubCategories,
  mockChallenges,
};
