import React, { useEffect } from "react";
import ArrowLeftlIcon from "../../assets/images/tactics/ArrowLeft.png";
import NoImageProfile from "../../assets/images/tactics/NoImageNew.svg";
import ContentIcon from "../../assets/images/tactics/Content.png";
import CommentIcon from "../../assets/images/tactics/Comments.png";
import ToolIcon from "../../assets/images/tactics/ToolRight.png";
import LinkIcon from "../../assets/images/tactics/Link.png";
import SidebarTactic from "./SideBar";
import "../../assets/styles/pages/tactics/sidebar-tactic.scss";
import TooltipCustom from "../Atoms/TooltipCustom";
import useScreenSize from "../../hooks/useScreenSize";
import { useHistory } from "react-router-dom";
import TooltipFile from "../Atoms/TooltipFile";

const TacticHome = ({
  background,
  tactic,
  expert,
  renderTacticSteps,
  activeStep,
  setShowModalTacticGeneral,
  showSidebarTactic,
  setShowSidebarTactic,
}) => {
  const history = useHistory();
  const { width } = useScreenSize();
  useEffect(() => {
    if (width < 900) {
      setShowSidebarTactic(false);
    }
  }, [width]);

  const getUrl = (url) => {
    if (url === undefined) {
      return;
    }
    if (url.includes("watch")) {
      const [, urlVideo] = url.split("=");
      return `https://www.youtube.com/embed/${urlVideo}`;
    } else {
      const urlVideo = url.split("/").pop();
      return `https://www.youtube.com/embed/${urlVideo}`;
    }
  };

  const splitActiveStepDesc = () => {
    const descSplit = activeStep.info.description?.split("\n");
    return descSplit;
  };

  const validateTextRich = () => {
    if (activeStep.info.description.includes("<")) {
      return (
        <div
          className="container-textRich"
          dangerouslySetInnerHTML={{ __html: activeStep.info.description }}
        />
      );
    } else {
      return splitActiveStepDesc().map((line, i) => (
        <p className="tactic-setpDescription" key={i}>
          {line}
        </p>
      ));
    }
  };

  const filterVideo = () => {
    const haveVideo = activeStep.info?.step_tools.filter((tool) =>
      tool.url.includes("you")
    );
    if (haveVideo?.length > 0) {
      const url = getUrl(haveVideo[0].url);
      return (
        <iframe
          width="100%"
          height="384"
          src={url}
          title="Tool"
          frameBorder="0"
        />
      );
    }
  };

  const validateTypeTool = (tool) => {
    let url = "";
    if (tool.url !== "") {
      url = tool.url;
    } else if (tool.file !== "") {
      url = tool.file;
    }
    return url;
  };

  const validateTitleLength = (title) => {
    if (title.length > 15) {
      return title.slice(0, 15).concat("...");
    } else {
      return title;
    }
  };

  return (
    <div className="container-viewTacticCenter">
      <div className="container-viewTactic">
        {showSidebarTactic ? (
          <SidebarTactic
            background={background}
            expert={expert}
            renderTacticSteps={renderTacticSteps}
            setShowSidebarTactic={setShowSidebarTactic}
          />
        ) : (
          <div className="container-tacticInfo">
            <div className="container-tacticCenter">
              <div
                className="container-tacticBackground"
                style={{
                  backgroundImage: `url(${background})`,
                }}
              >
                <div
                  className="container-arrowLeft"
                  onClick={() => setShowSidebarTactic(true)}
                >
                  <img src={ArrowLeftlIcon} alt="Flecha izquierda" />
                </div>
                <p
                  className="title-tactic"
                  onClick={() => setShowModalTacticGeneral(true)}
                >
                  {tactic.title}
                </p>
                <button
                  className="btn-edit"
                  onClick={() =>
                    history.push(`/tactic/edit/${tactic.id}`, {
                      tactic: tactic,
                    })
                  }
                >
                  Editar táctica
                </button>
              </div>
              {/* slug/id  */}
              {expert !== "noInfo" && (
                <div className="container-infoExpert">
                  <img
                    src={expert.imgUser ? expert.imgUser : NoImageProfile}
                    alt="Perfil experto"
                  />
                  <p className="name-expert">
                    {expert.name} {expert.first_lastname} -
                  </p>
                  <p className="perfil-expert">{expert.jobPosition}</p>
                </div>
              )}
              <div className="container-sectionContentComments">
                <div className="container-content">
                  <img src={ContentIcon} alt="Contenido" />
                  <p>Contenido</p>
                </div>
                <TooltipCustom id="tooltip-comment">
                  <div className="container-comment">
                    <img src={CommentIcon} alt="Contenido" />
                    <p>Comentarios</p>
                  </div>
                </TooltipCustom>
              </div>
              {renderTacticSteps()}
              <hr className="tactic-separator" />
            </div>
          </div>
        )}
        {/* steps */}
        <div
          className="container-tacticStep"
          style={{ width: showSidebarTactic && "65%" }}
        >
          <div className="container-stepCenter">
            {tactic.is_draft !== true && (
              <span className="tactic-stepTitle">
                Paso {activeStep.index + 1} -
              </span>
            )}
            <span className="tactic-stepName">{activeStep.info?.title}</span>
            <div className="container-tacticToolFrame">{filterVideo()}</div>

            {/* Objetivo */}
            {activeStep.info?.objective && (
              <div className="tactic-objective">
                <p className="title">Objetivo:</p>
                <p>{activeStep.info?.objective}</p>
              </div>
            )}
            {activeStep.info?.step_tools.length > 0 && (
              <>
                <div className="container-titleTool">
                  <img src={ToolIcon} alt="Herramientas" />
                  <p>Videos, archivos y url agregados</p>
                </div>
                {activeStep.info?.step_tools.map((tool, i) => (
                  <div className="container-tool" key={i}>
                    {/* <p>{tool.title}</p> */}
                    <TooltipFile id={i} msg={tool.title}>
                      <p>{validateTitleLength(tool.title)}</p>
                    </TooltipFile>
                    <div>
                      <b>{tool.tool_type}</b>
                      <a
                        href={validateTypeTool(tool)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={LinkIcon} alt="Link de la herramienta" />
                      </a>
                    </div>
                  </div>
                ))}
              </>
            )}
            {activeStep.info?.description && validateTextRich()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TacticHome;
