import React, { useEffect, useState } from "react";
import { Formik, Field, ErrorMessage, Form as FormFormik } from "formik";
import { Row, Col, Form } from "react-bootstrap";
import * as Yup from "yup";
import { MenuResource } from "./MenuResource";
import { CategoriesResource } from "./CategoriesResource";
import api from "../../utils/axiosUsage";
import { ModalTemp } from "../ModalTemp/ModalTemp";
import { Button } from "@logra/component-library";
import { useHistory } from "react-router-dom";
import "../ButtonSelector/ButtonSelector.css";

const Stage2Resource = ({
  loggedUser,
  stage,
  idContent,
  setStage,
  contentResource,
}) => {
  const history = useHistory();
  const [category, setCategory] = useState(
    contentResource?.category || "Estrategia"
  );
  const [subCategories, setSubCategories] = useState({
    cultura: [],
    estrategia: [],
    finanzas: [],
    tecnologia: [],
    marketing: [],
    ventas: [],
  });
  const [challenges, setChallenges] = useState([]);
  const [showModal, setshowModal] = useState(false);

  useEffect(() => {
    if (loggedUser.token === undefined) {
      return;
    }
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser]);

  const getCategories = async () => {
    try {
      const { data } = await api.axiosAuthGet(
        "/cms/api/v1/platform-categories-subcat-gc",
        loggedUser?.token
      );
      setSubCategories({
        finanzas: Object.keys(data["Finanzas"]),
        estrategia: Object.keys(data["Estrategia"]),
        tecnologia: Object.keys(data["Tecnología"]),
        marketing: Object.keys(data["Marketing"]),
        cultura: Object.keys(data["Equipo"]),
        ventas: Object.keys(data["Marketing"]),
      });

      setChallenges(Object.values(data));
    } catch (error) {
      console.log(error);
    }
  };

  const renderCategory = () => {
    if (category.includes("Finanzas")) return subCategories.finanzas;
    else if (category.includes("Estrategia")) return subCategories.estrategia;
    else if (category.includes("Tecnología")) return subCategories.tecnologia;
    else if (category.includes("Marketing")) return subCategories.marketing;
    else if (category.includes("Equipo")) return subCategories.cultura;
    else if (category.includes("Ventas")) return subCategories.marketing;
    else return subCategories.finanzas;
  };

  const renderRetos = (subCategory) => {
    if (subCategory === null) {
      return null;
    }

    if (!subCategory || challenges.length === 0) {
      return null;
    }
    const filtrar = challenges.filter((sub) => sub[subCategory]);

    return filtrar[0][subCategory];
  };

  // const renderChallenges = (subCategory) => {
  //   if (!subCategory || challenges.length === 0) {
  //     return null;
  //   }
  //   const filtrar = challenges.filter((sub) => sub[subCategory]);

  //   return filtrar[0][subCategory].map((challenge) => (
  //     <option key={challenge[1]} value={challenge[1]}>
  //       {challenge[1]}
  //     </option>
  //   ));
  // };

  const onContinuosCreation = async (formValues, resetForm) => {
    const formData = {
      is_active: true,
      is_draft: false,
      subcategory: formValues.subCategory,
      category,
      business_challenge: formValues.challenge,
      company_type: formValues.companyType,
      company_size: formValues.size,
      economic_sector: formValues.economicSector,
      applies_only_to_ecommerce: formValues.ecommerce === "Si" ? true : false,
    };
    try {
      await api.axiosAuthPatch(
        `/cms/api/v2/indexed-content-crud/${idContent}/`,
        loggedUser.token,
        formData
      );
      setshowModal(true);
      resetForm();
    } catch (error) {
      console.log(error);
    }
  };

  // const [showProponerModal, setShowProponerModal] = useState(false);

  return (
    <>
      <Formik
        initialValues={{
          subCategory: contentResource?.subcategory || "",
          challenge: contentResource?.business_challenge || "",
          companyType: contentResource?.company_type || "Todos",
          size: contentResource?.company_size || "Todos",
          economicSector: contentResource?.economic_sector || "Todos",
          ecommerce: contentResource?.applies_only_to_ecommerce
            ? "Si"
            : "No" || "",
        }}
        onSubmit={(values, { resetForm }) => {
          onContinuosCreation(values, resetForm);
        }}
        validationSchema={Yup.object({
          subCategory: Yup.string()
            .required("Este campo es obligatorio")
            .max(255, "Máximo 255 caracteres"),
          challenge: Yup.string()
            .required("Este campo es obligatorio")
            .max(255, "Máximo 255 caracteres"),
          companyType: Yup.string()
            .required("Este campo es obligatorio")
            .max(255, "Máximo 255 caracteres"),
          size: Yup.string()
            .required("Este campo es obligatorio")
            .max(100, "Máximo 100 caracteres"),
          economicSector: Yup.string()
            .required("Este campo es obligatorio")
            .max(100, "Máximo 100 caracteres"),
          ecommerce: Yup.string().required("Este campo es obligatorio"),
        })}
      >
        {(formik) => (
          <FormFormik className="container-resource">
            <ModalTemp
              title={`🎉 Felicidades ${loggedUser.name} 🎉`}
              subTitle={
                "¡Tu recurso fue éxitosamente indexado! Te va ayudar a posicionarte mejor como experto impulsando las PyMEs en crecer"
              }
              disableCloseBtn={true}
              content={
                <div
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    marginTop: "2rem",
                  }}
                >
                  <Button
                    label="Indexar otro recurso"
                    primary={true}
                    size={"large"}
                    onClick={() => {
                      history.push("/resource/new");
                      setStage(1);
                    }}
                  />
                  <br />
                  <Button
                    label="Regresar a mi Biblioteca"
                    size={"large"}
                    onClick={() => history.push("/library")}
                  />
                </div>
              }
              showModal={showModal}
            />

            {/* <ModalTemp
              title={'Ayúdanos a mejorar 🙌'}
              content={
                <div className="proponerReto" >
                  <h6>Propón una subcateogría o un reto de crecimiento.</h6>
                  <hr />
                  <h6>¿Identificaste una oportunidad para clasificar mejor las soluciones que recomendamos a las PyMEs?</h6>
                  <hr />
                  <div className="proponerTextbox" style={{ marginBottom: '1rem' }}>
                    <label htmlFor="suggestGrowthChallenge">Explícanos claramente qué mejorarías: </label>
                    <textarea resizable={false} name="suggestGrowthChallenge" cols="30" rows="10" placeholder="¿Agregarías una subcategoría o un reto de crecimiento? &#10; ¿En qué área? Mientras más detalle nos des, mejor. ">
                    </textarea>
                  </div>
                  <Button label="Enviar mi propuesta al equipo" primary />
                </div>
              }
              showModal={showProponerModal}
              setShowModal={setShowProponerModal}
            /> */}

            <div className="container-resourceForm">
              <Row className="rowLimit rowWidth rowDashboard">
                <Col lg={10}>
                  <h1>{loggedUser.name}, estás indexando un recurso 👌</h1>
                </Col>
              </Row>
              <Row className="rowLimit rowWidth rowDashboard">
                <Col md={10}>
                  <p className="resource-subTitle">
                    Organízalo por área y reto de crecimiento
                  </p>

                  <Form.Group className="resource-field">
                    <div className="resource-nivel">
                      <p>Nivel 1:</p>
                      <label>Escoge la categoría de tu táctica:</label>
                    </div>
                    <CategoriesResource
                      setCategory={setCategory}
                      category={category}
                    />
                  </Form.Group>
                  <Form.Group className="resource-field">
                    <div className="resource-nivel">
                      <p>Nivel 2:</p>
                      <label>¿En qué subcategoría la incluyes?</label>
                    </div>

                    <div role="group" className="btnPicker-container">
                      {renderCategory().map((subcat, i) => (
                        <div key={i} className={"buttonPicker"}>
                          <Field
                            type="radio"
                            id={subcat}
                            name={"subCategory"}
                            value={subcat}
                            key={i + 1}
                          />
                          <label
                            className="buttonPickerLabel withTempOverrides"
                            htmlFor={subcat}
                          >
                            {subcat}
                          </label>
                        </div>
                      ))}
                    </div>

                    <ErrorMessage
                      name="subCategory"
                      component="div"
                      className="frmErrorCategory"
                    />
                  </Form.Group>
                  <Form.Group className="resource-field">
                    <div className="resource-nivel">
                      <p>Nivel 3:</p>
                      <label>¿Qué reto de crecimiento resuelve?</label>

                      {/* <button onClick={() => setShowProponerModal(true)} style={{ marginBottom: '0.5rem', border: 'none', backgroundColor: 'transparent', color: '#2C5999', fontWeight: '600', marginLeft: 'auto' }} type="link">+ Proponer un reto</button> */}
                    </div>

                    <div role="group" className="btnPicker-container">
                      {renderRetos(formik.values.subCategory) !== null ? (
                        renderRetos(formik.values.subCategory).map(
                          (challenge, i) => (
                            <div key={i} className={"buttonPicker"}>
                              <Field
                                type="radio"
                                id={challenge[1]}
                                name={"challenge"}
                                value={challenge[1]}
                                key={i + 1}
                              />
                              <label
                                className="buttonPickerLabel withTempOverrides"
                                htmlFor={challenge[1]}
                              >
                                {challenge[1]}
                              </label>
                            </div>
                          )
                        )
                      ) : (
                        <p
                          className="MustPickACategory"
                          style={{ opacity: 0.8 }}
                        >
                          Es necesario elegir una sub categorí a para continuar
                        </p>
                      )}
                    </div>
                    {/* <Field
                      name="challenge"
                      as={"select"}
                      className="inputNewBrand main-btn-align form-control"
                    >
                      <option value="">Seleccionar</option>
                      {renderChallenges(formik.values.subCategory)}
                    </Field> */}
                    <ErrorMessage
                      name="challenge"
                      component="div"
                      className="frmErrorCategory"
                    />
                  </Form.Group>
                  <p className="resource-subTitle">
                    ¿Para quién es ideal este recurso?
                  </p>

                  <Row>
                    <Form.Group className="resource-field" as={Col}>
                      <label>Tipo de empresa</label>
                      <Field
                        name="companyType"
                        as="select"
                        className="inputNewBrand main-btn-align form-control"
                      >
                        <option value="Todos">Todos</option>
                        <option value="PyMe">PyMe</option>
                        <option value="Startup">Startup</option>
                        <option value="Consultor/Freelance">
                          Consultor/Freelance
                        </option>
                        <option value="ONG/Asociación">ONG/Asociación</option>
                        <option value="Otro">Otro</option>
                      </Field>
                      <ErrorMessage
                        name="companyType"
                        component="div"
                        className="frmErrorCategory"
                      />
                    </Form.Group>
                    <Form.Group className="resource-field" as={Col}>
                      <label>¿De qué tamaño?</label>
                      <Field
                        name="size"
                        as="select"
                        className="inputNewBrand main-btn-align form-control"
                      >
                        <option value="Todos">Todos</option>
                        <option value="Estoy solo">Estoy solo/a</option>
                        <option value="2 a 5 colaboradores">
                          2 a 5 colaboradores
                        </option>
                        <option value="6 a 20 colaboradores">
                          6 a 20 colaboradores
                        </option>
                        <option value="21 en adelante">21 en adelante</option>
                        <option value="Cualquiera">Cualquiera</option>
                      </Field>
                      <ErrorMessage
                        name="size"
                        component="div"
                        className="frmErrorCategory"
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group className="resource-field" as={Col}>
                      <label>¿De qué sector económico?</label>
                      <Field
                        name="economicSector"
                        as="select"
                        className="inputNewBrand main-btn-align form-control"
                      >
                        <option value="Todos">Todos</option>
                        <option value="Servicios Profesionales">
                          Servicios Profesionales
                        </option>
                        <option value="Comercialización y Distribución">
                          Comercialización y Distribución
                        </option>
                        <option value="Tecnología y Software">
                          Tecnología y Software
                        </option>
                        <option value="Manufactura">Manufactura</option>
                        <option value="Otros Sectores">Otros Sectores</option>
                      </Field>
                      <ErrorMessage
                        name="economicSector"
                        component="div"
                        className="frmErrorCategory"
                      />
                    </Form.Group>
                    <Form.Group className="resource-field" as={Col}>
                      <label>¿Es exclusivo para e-commerce?</label>
                      <Field
                        name="ecommerce"
                        as="select"
                        className="inputNewBrand main-btn-align form-control"
                      >
                        <option value="">Selecciona</option>
                        <option value="Si">Sí</option>
                        <option value="No">No</option>
                      </Field>
                      <ErrorMessage
                        name="ecommerce"
                        component="div"
                        className="frmErrorCategory"
                      />
                    </Form.Group>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="container-resourceMenu">
              <MenuResource stage={stage} formik={formik} />
            </div>
          </FormFormik>
        )}
      </Formik>
    </>
  );
};

export default Stage2Resource;
