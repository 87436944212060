import { useState, useEffect } from "react";
import "./helper.scss";
import Collapse from "react-bootstrap/Collapse";

const HelperBox = (props) => {
  const [render, setRender] = useState(false);
  const [open, setOpen] = useState(false);

  let list = props.list;
  let titleExample = props.titleExample;
  let listExample = props.listExample;
  let state = props.state;
  let helperTitle = props.helperTitle;
  let Checked = props.checklist;
  let Tick = props.dropdown_close;
  let ReverseTick = props.dropdown_open;
  let Bulb = props.BulbTitle;

  let margin = {
    margin: 0,
    padding: 0,
  };

  let helperlist = {
    fontSize: "12px",
    margin: 0,
    padding: 3,
  };

  let example_title = {
    fontWeight: 700,
    margin: 0,
    padding: 2,
  };

  /**
   * This useEffect sets the animation for the help list
   */
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setRender(true);
      }, 50);
    } else {
      setRender(false);
    }
  }, [open]);

  const handleClick = () => {
    setOpen(!open);
  };

  const HelperList = ({ list }) => {
    return list.map((li, index) => {
      return (
        <li
          key={`helper-list${index}`}
          style={margin}
          className="helper_item_text"
        >
          <img src={Checked} alt="tick" className="helper_tick" />
          <p style={{ margin: 0, padding: 5 }} className="helper_list_text">
            {li}
          </p>
        </li>
      );
    });
  };

  const HelperExample = ({ titleExample, listExample }) => {
    return (
      <div className="helper_example" style={{ paddingTop: "6px" }}>
        <p className="helper_example_title" style={example_title}>
          {titleExample}
        </p>
        <ul className="helper_example_item">
          {listExample.map((li, index) => {
            return (
              <li key={`helper-list${index}`} style={helperlist}>
                {li}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className="helper_box">
      <div className="helper_content" style={{ paddingBottom: "15px" }}>
        <img src={Bulb} alt="helper_logo" className="helper_logo" />
        <p className="helper_title" style={{ margin: 0, padding: 0 }}>
          {helperTitle}
        </p>
        <img
          src={open ? Tick : ReverseTick}
          className="helper_dropdown"
          alt="helper_dropdown"
          onClick={() => handleClick()}
        />
      </div>
      <Collapse in={open} timeout={300}>
        <div className={`render ${render ? "show" : undefined}`}>
          <div className="helper_list" style={{ margin: "0", padding: "0" }}>
            <ul style={{ paddingBottom: "5px" }}>
              {<HelperList list={list} />}
            </ul>
          </div>
          {state === false ? null : (
            <HelperExample
              titleExample={titleExample}
              listExample={listExample}
            />
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default HelperBox;
