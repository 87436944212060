export const createSlugName = (name) => {
  let slug = name ? name.toLowerCase() : "";
  slug = slug.replaceAll(" ", "");
  return slug;
};

export const strToBoolean = (string) => {
  switch (string.toLowerCase().trim()) {
    case "true":
    case "yes":
    case "1":
      return true;

    case "false":
    case "no":
    case "0":
    case null:
      return false;

    default:
      return Boolean(string);
  }
};
