import React, { useState } from 'react'
import './ModalTemp.css'
import CloseIcon from '../../assets/images/icons/Close.svg';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Button } from '@logra/component-library';
import { useEffect } from 'react';

export const ModalTemp = ({
  title,
  subTitle,
  slides,
  disableCloseBtn = false,
  content,
  ctaLabel = 'Siguiente',
  ctaFunction = () => console.log('cta'),
  onClose = () => console.log('close'),
  showModal,
  setShowModal,
}) => {
  return showModal !== false ? (
    <div className={'lograModal-overlay'}>
      <div className={` ${content ? 'baseTempModal' : 'baseLograModal'} `}>
        {
          !disableCloseBtn && <button onClick={disableCloseBtn ? false : (() => { setShowModal(false); onClose() })} className="lograModal-close-btn">
            <img src={CloseIcon} alt="Close modal" />
          </button>
        }
        {/* onChange={onChange} onClickItem={onClickItem} onClickThumb={onClickThumb} */}
        <div className='lograModal-head'>
          {title && <h2 className='lograModal-title'>{title}</h2>}
          {subTitle && <h4 className='lograModal-subtitle'>{subTitle}</h4>}
        </div>
        {
          content ? (
            (
              <div className="">
                {content}
              </div>)
          ) : (
            <div className="lograModal-slide-container">
              {/* @ts-ignore*/}
              <Carousel
                // infiniteLoop
                showThumbs={false}
                showStatus={false}
                transitionTime={200}
                showArrows={false}
                showIndicators
                autoPlay
                swipeable
                className={'banner-carousel'}
              >
                {slides.map(({ media, content, title }, index) => (
                  <div key={index}>
                    <h2 className='lograModal-title-slides'>{title}</h2>
                    <div className="lograModal-slide">
                      <div className='lograModal-slide-container'>
                        <img src={media} alt="" className='lograModal-media' />
                        <div className='lograModal-slide-content'>
                          {content}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>)
        }
        {
          slides && (
            <div className='lograModal-btn-container'>
              <Button label={ctaLabel} primary={true} size={'large'} onClick={() => ctaFunction()} />
            </div>
          )
        }
      </div>
    </div >
  ) : <></>

}