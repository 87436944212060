import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const TooltipCustom = ({children, id}) => {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip id={id}>¡Disponible proximamente!</Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  )
}

export default TooltipCustom
