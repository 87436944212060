import React, { useEffect, useState } from "react";
import { Button } from "@logra/component-library";
import "./TacticCard.css";
import { ToggleSwitchTemp } from "../ToggleSwitchTemp/ToggleSwitchTemp";
import api from "../../utils/axiosUsage";

export const TacticCardTemp = ({
  category,
  title,
  subcategory,
  slug_url,
  views = 0,
  user,
  rating = 0,
  is_active,
  is_draft,
  cardStyle = "Card",
  business_challenge = "Productividad",
  size = "Default",
  onView,
  onEdit,
  onToggle,
  onShare,
  id,
  token,
}) => {
  const [tacticSteps, setTacticSteps] = useState();

  const foldBigNumbers = (num) => {
    if (num === undefined || num === 0) {
      return 0;
    } else if (num > 999 && num < 999999) {
      let k = num / 100;
      return `${Math.floor(k) / 10}k`;
    } else if (num > 999999) {
      return `${Math.floor(num / 1000000)}m`;
    } else {
      return num;
    }
    /*
    change for this native method

     const number = 1234;
     console.log(new Intl.NumberFormat('en', { notation: 'compact' }).format(number));
     returns 1.2k
     */
  };

  const getSteps = async () => {
    try {
      const { data } = await api.axiosAuthGet(
        `/cms/api/v2/tactic-and-steps/${id}/`,
        token
      );
      let steps = data.steps.length;
      setTacticSteps(steps);

      if (steps !== undefined && steps < 1) {
        await api.axiosAuthPatch(`/cms/api/v2/tactics/${id}/`, token, {
          is_active: false,
          is_draft: true,
        });
      }
    } catch (error) {
      console.log("steps fetch error: ", error);
    }
  };

  const foldString = (str, max) => {
    if (str !== undefined && max !== undefined && str.length > 0) {
      if (str.length > max) {
        return `${str.substring(0, max - 3)}...`;
      } else {
        return str;
      }
    } else {
      return "Sin definir";
    }
  };

  const setColor = (category) => {
    if (category.includes("Estrategia") || category.includes("estrategia"))
      return "#e57771";
    else if (category.includes("Marketing")) return "#f1ad5d";
    else if (category.includes("Finanzas") || category.includes("finanzas"))
      return "#e081ac";
    else if (category.includes("Tecnología") || category.includes("tecnología"))
      return "#af87b5";
    else if (category.includes("Equipo") || category.includes("equipo"))
      return "#6a79b5";
    else if (category.includes("Ventas")) return "#f1ad5d";
    else return "darkSlateGrey";
  };

  const setColorCategory = (category) => {
    if (category.includes("Estrategia")) {
      return {
        bg: "#F5CECE",
        txt: "#E16B67",
        theme: "planning-theme",
      };
    } else if (category.includes("Marketing")) {
      return {
        bg: "#faddb9",
        txt: "#EFA54D",
        theme: "marketing-theme",
      };
    } else if (category.includes("Finanzas")) {
      return {
        bg: "#ECD0DD",
        txt: "#DD74A4",
        theme: "admi-theme",
      };
    } else if (category.includes("Tecnología")) {
      return {
        bg: "#d3b7d6",
        txt: "#A77BAD",
        theme: "tech-theme",
      };
    } else if (category.includes("Equipo")) {
      return {
        bg: "#c2c9f0",
        txt: "#5C6CAEB2",
        theme: "team-theme",
      };
    } else if (category.includes("Ventas")) {
      return {
        bg: "#faddb9",
        txt: "#EFA54D",
        theme: "marketing-theme",
      };
    } else {
      return {
        bg: "#c2c9f0",
        txt: "#5C6CAEB2",
        theme: "team-theme",
      };
    }
  };

  const handleStatusChange = async () => {
    try {
      if (is_active === true && is_draft === false) {
        await api.axiosAuthPatch(`/cms/api/v2/tactics/${id}/`, token, {
          is_active: false,
          is_draft: true,
        });
      }

      if (is_active === false && is_draft === true) {
        await api.axiosAuthPatch(`/cms/api/v2/tactics/${id}/`, token, {
          is_active: true,
          is_draft: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [ratingAndCompleted, setRatingAndCompleted] = useState({
    rating: 0,
    completed: 0,
  });

  const getRatingAndCompleted = async () => {
    const { data } = await api.axiosPost("/cms/api/v2/tactic-rating", {
      tactic: id,
    });
    setRatingAndCompleted({
      rating: data?.rating,
      completed: data?.count,
    });
  };

  useEffect(() => {
    getRatingAndCompleted();
    getSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCard = (cardStyle) => {
    if (cardStyle === "Card") {
      return (
        <div className={"TacticCard-temp"}>
          <div
            className="tactic-top logra-pattern"
            style={{ backgroundColor: `${setColor(category)}` }}
          >
            <button
              className={"share-btn"}
              onClick={onShare}
              disabled={tacticSteps < 1 ? true : false}
            >
              <div className="share-icon tactic-icon"></div>
            </button>
            <p
              className="tct-p category"
              style={{
                color: `${setColorCategory(category).txt}`,
                backgroundColor: `${setColorCategory(category).bg}`,
              }}
            >
              {foldString(category, 33)}
            </p>
            <h3 onClick={onView} className="tactictemp-title">
              {title}
            </h3>
          </div>

          <div className="tactic-bottom">
            <div className="row tactic-row">
              <p className="tct-p subcategory">{foldString(subcategory, 31)}</p>

              <div className="icon-combo views-container">
                <p className="tct-p views">
                  {foldBigNumbers(ratingAndCompleted.completed)}
                </p>
                <div className="views-icon tactic-icon"></div>
              </div>
            </div>

            <div className="row tactic-row">
              <div className={"tactic-user"}>
                <img
                  src={user?.profilePicture}
                  alt="profile"
                  className="tactic-user-picture"
                />
                <p className="tct-p owner">{foldString(user?.name, 30)}</p>
              </div>

              <div className="icon-combo ratings-container">
                <p className="tct-p ratings">
                  {ratingAndCompleted.rating === 0
                    ? ""
                    : ratingAndCompleted.rating}
                </p>
                <div className="ratings-icon tactic-icon"></div>
              </div>
            </div>

            <div className="row tactic-row">
              {(tacticSteps !== undefined && tacticSteps) > 0 ? (
                <p
                  className={`status ${
                    is_active === true && is_draft !== true && "status-active"
                  }`}
                >
                  {is_active === false ? "No publica" : "Publica"}{" "}
                </p>
              ) : (
                <p className="status">Incompleta</p>
              )}

              <Button
                label="Editar"
                className="edit-btn"
                size="large"
                onClick={onEdit}
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={"TacticList"}>
          <div className="list-title-col">
            <h3 onClick={onView} className="list-title">
              {title}
            </h3>
            <div className="list-top-col">
              <p
                className={`tct-p category list-sub ${
                  setColorCategory(category).theme
                }`}
                style={{
                  backgroundColor: `${setColorCategory(category).bg}`,
                }}
              >
                {foldString(category, 33)}
              </p>
              <p className="tct-p subcategory">{foldString(subcategory, 31)}</p>
            </div>
          </div>
          {/* subt */}
          <div className="list-title-col subt">
            <p className="tct-p">{foldString(business_challenge, 56)}</p>
          </div>

          <div className="col">
            <div className="icon-combo ratings-container">
              {/* <div className="ratings-icon tactic-icon"></div> */}
              <p className="tct-p ratings">
                {ratingAndCompleted?.rating === 0
                  ? "No se ha calificado"
                  : ratingAndCompleted?.rating}
              </p>
            </div>
          </div>

          <div className="col">
            <div className="icon-combo views-container">
              <p className="tct-p views">
                {foldBigNumbers(ratingAndCompleted.completed)}
              </p>
            </div>
          </div>

          <div className="col">
            {(tacticSteps !== undefined && tacticSteps) > 0 ? (
              <ToggleSwitchTemp
                enabled={tacticSteps < 1 ? false : true}
                onLabel="Pública"
                offLabel="No publicada"
                onClick={() => handleStatusChange()}
                autoToggle={
                  is_active === true && is_draft === false ? true : false
                }
              />
            ) : (
              <p className="status">Incompleta</p>
            )}
          </div>

          <div className="col">
            <Button
              label="Editar"
              className="edit-btn"
              size="large"
              onClick={onEdit}
            />
          </div>
        </div>
      );
    }
  };

  return renderCard(cardStyle);
};
