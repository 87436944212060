import React, { useState } from "react";
import "./banner.scss";
import CloseIcon from "../../assets/images/icons/Close.svg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Button } from "@logra/component-library";

const LograBanner = ({
  title,
  body,
  slides,
  startWithBanner,
  media,
  bannerAction,
  bannerActionLabel,
}) => {
  const [banner, setBanner] = useState(startWithBanner);
  return banner ? (
    <div className="banner-container">
      <button onClick={() => setBanner(false)} className="banner-close-btn">
        <img src={CloseIcon} alt="Close modal" />
      </button>
      <Carousel
        showThumbs={false}
        showStatus={false}
        transitionTime={200}
        showArrows={false}
        showIndicators={true}
        autoPlay={true}
        swipeable={true}
        className={"banner-carousel"}
      >
        {slides.map(
          (
            {
              title,
              body,
              media,
              backgroundColor = "#e57a74",
              bannerActionLabel = "Crear mi primera tactica",
            },
            index
          ) => (
            <div
              className="banner banner-logra-pattern"
              key={index}
              style={{ backgroundColor: backgroundColor }}
            >
              <div className="banner-content">
                <h4 className="banner-title responsive-FontSize">{title}</h4>
                <p className="banner-sub-title responsiveDescription-FontSize">
                  {body}
                </p>
                <Button
                  label={bannerActionLabel}
                  onClick={bannerAction}
                  primary
                  size="medium"
                  style={{
                    backgroundColor: "white",
                    color: "#404040",
                    width: "65%",
                  }}
                />
              </div>
              {media !== undefined && (
                <img src={media} alt="" className="banner-media" />
              )}
            </div>
          )
        )}
      </Carousel>
    </div>
  ) : (
    <></>
  );
};

export default LograBanner;
