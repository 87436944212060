import React, { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage, Form as FormFormik } from "formik";
import * as Yup from "yup";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Row, Col, Form } from "react-bootstrap";
import StepsContainer from "./StepsContainer";
import { MainTitle } from "../../assets/styles/pages/general/GeneralStyles";

import api from "../../utils/axiosUsage";
import { getCurrentUser } from "../../utils/auth";
import "./buttonPicker.css";
import "../../assets/styles/pages/tactics/index.scss";
import { toast } from "react-toastify";

const Stage1 = ({
  nextStage,
  stage,
  tacticInfo,
  editMode,
  setIdTactic,
  idTactic,
}) => {
  const [loggedUser, setLoggedUser] = useState({});
  const [category, setCategory] = useState(tacticInfo.category || "Estrategia");
  const [objectives,setObjectives] = useState({
    finanzas: [],
    marketing: [],
    equipo: [],
    resumen: [],
    ventas: [],
  });
  
  const [selectedObjective, setSelectedObjective] = useState("Estrategia");
  const token = loggedUser.token;

  useEffect(() => {
    const userData = getCurrentUser();
    setLoggedUser(userData);
    getCategories(userData.token);
  }, []);

  const onChangeCategory = (value) => {
    setCategory(value);
    setSelectedObjective(value);
  };

  const getCategories = async (token) => {
    try {
      const { data } = await api.axiosAuthGet(
        "/cms/api/v3/platform-categories-objectives",
        token
      );
      const newObjectives = {
        finanzas: [],
        marketing: [],
        equipo: [],
        resumen: [],
        ventas: [],
      };
        data.forEach(categoryData => {
        const categoryName = categoryData.name;
        const categoryObjectives = categoryData.objectives.map(objective => objective.name);
        newObjectives[categoryName.toLowerCase()] = categoryObjectives;
      });
  
      setObjectives(newObjectives);

    } catch (error) {
      console.log(error);
    }
  };
  const renderObjectives = () => {
    if (category.includes("Finanzas")) return objectives.finanzas ;
    else if (category.includes("Marketing")) return objectives.marketing;
    else if (category.includes("Equipo")) return objectives.equipo;
    else if (category.includes("Resumen")) return objectives.resumen;
    else if (category.includes("Ventas")) return objectives.ventas;
    else return objectives.finanzas;
  }

  const createTactic = async (values) => {
    const formData = {
      category,
      title: values.titleTactic.trim(),
      overview: values.description,
      outcome: values.result,
      owner: loggedUser.id,
      business_challenge: values.objectives,
      is_draft: false,
      is_active: true,
      objective: values.objective,
    };

    if (!editMode) {
      try {
        const { data } = await api.axiosAuthPost(
          `/cms/api/v2/tactics/`,
          token,
          formData
        );
        if (data === undefined) {
          console.log("No se creó la táctica");
          toast.error("Error con la táctica");
          return;
        }
        setIdTactic(data);
        nextStage();
      } catch (error) {
        console.log("No se creó la táctica");
      }
    } else {
      try {
        await api.axiosAuthPatch(
          `/cms/api/v2/tactics/${tacticInfo.id}/`,
          loggedUser.token,
          formData
        );
        nextStage();
      } catch (error) {
        console.log("No se actualizó la táctica");
      }
    }
  };


  return (
    <>
      <div>
        <Formik
          initialValues={{
            objectives: tacticInfo.subcategory || "",
            titleTactic: tacticInfo.title || "",
            description: tacticInfo.overview || "",
            result: tacticInfo.outcome || "",
            objective: tacticInfo.objective || "",
          }}
          onSubmit={(values) => {
            createTactic(values);
          }}
          validationSchema={Yup.object({
            objectives: Yup.string()
              .required("Este campo es obligatorio")
              .max(255, "Máximo 255 caracteres")
              .nullable(),
            titleTactic: Yup.string()
              .min(2, "Al menos 2 caracteres")
              .required("Este campo es obligatorio")
              .max(255, "Máximo 255 caracteres"),
            description: Yup.string()
              .min(2, "Al menos 2 caracteres")
              .required("Este campo es obligatorio"),
            result: Yup.string()
              .required("Este campo es obligatorio")
              .max(255, "Máximo 255 caracteres"),
            objective: Yup.string()
              .required("Este campo es obligatorio")
              .max(255, "Máximo 255 caracteres"),
          })}
        >
          {(formik) => (
            <FormFormik className="container-tactic">
              <div className="container-tacticForm">
                <Row className="rowLimit rowWidth rowDashboard">
                  <Col lg={10}>
                    <MainTitle style={{ marginBottom: 50 }}>
                      ¡ {loggedUser.name}{" "}
                      {editMode
                        ? "estás editando una táctica!"
                        : "estás creando una táctica!"}
                    </MainTitle>
                  </Col>
                  <Col lg={2}></Col>
                </Row>
                <Row className="rowLimit rowWidth rowDashboard">
                  <Col md={10}>
                    <Form.Group>
                      <div className="categoryRow">
                        <label htmlFor="category">
                          <b>Nivel 1: </b>
                          Escoge la categoría de tu táctica:
                        </label>
                      </div>
                      <div className="container-tacticCategory">
                       <button
                          type="button"
                          className={`tactic-category admi ${
                            category === "Finanzas" && "active"
                          }`}
                          onClick={() => {
                            onChangeCategory("Finanzas");
                            formik.values.objectives = "";
                          }}
                        >
                          Finanzas
                        </button>
                        <button
                          type="button"
                          className={`tactic-category marketing ${
                            category === "Marketing" && "active"
                          }`}
                          onClick={() => {
                            onChangeCategory("Marketing");
                            formik.values.objectives = "";
                          }}
                        >
                          Marketing
                        </button>
                        <button
                          type="button"
                          className={`tactic-category team ${
                            category === "Equipo" && "active"
                          }`}
                          onClick={() => {
                            onChangeCategory("Equipo");
                            formik.values.objectives = "";
                          }}
                        >
                          Equipo
                        </button>
                        <button
                          type="button"
                          className={`tactic-category resumen ${
                            category === "Resumen" && "active"
                          }`}
                          onClick={() => {
                            onChangeCategory("Resumen");
                            formik.values.objectives = "";
                          }}
                        >
                          Resumen
                        </button>
                          <button
                          type="button"
                          className={`tactic-category marketing ${
                            category === "Ventas" && "active"
                          }`}
                          onClick={() => {
                            onChangeCategory("Ventas");
                            formik.values.objectives = "";
                          }}
                        >
                          Ventas
                        </button>
                      
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <div className="tactics-label">
                        <label htmlFor="objectives">
                          <b>Nivel 2: </b>
                          ¿Que objetivo resuelve tu tactica?
                        </label>
                      </div>

                      <div role="group" className="btnPicker-container">
                        {renderObjectives().map((objective, i) => (
                          <div key={i} className={"buttonPicker"}>
                            <Field
                              type="radio"
                              id={objective}
                              name={"objectives"}
                              value={objective}
                              key={i + 1}
                            />
                            <label
                              className="buttonPickerLabel"
                              htmlFor={objective}
                            >
                              {objective}
                            </label>
                          </div>
                        ))}
                      </div>

                      <ErrorMessage
                        name="objectives"
                        component="div"
                        className="frmErrorCategory"
                      />
                    </Form.Group>
                    <Form.Group>
                      <div className="tactics-label">
                        <label htmlFor="titleTactic">
                          ¿Cuál es el nombre de tu táctica?
                        </label>
                      </div>
                      <Field
                        name="titleTactic"
                        type="text"
                        className="inputNewBrand main-btn-align form-control"
                        placeholder="Elige un nombre llamativo y que deje claro el resultado"
                      />
                      <ErrorMessage
                        name="titleTactic"
                        component="div"
                        className="frmErrorCategory"
                      />
                    </Form.Group>
                    <Row>
                      <Form.Group as={Col}>
                        <div className="tactics-label">
                          <label htmlFor="result">
                            ¿Qué resultado logrará con la táctica?
                          </label>
                        </div>
                        <Field
                          name="result"
                          as="textarea"
                          className="inputTextarea main-btn-align form-control"
                          placeholder="¿Qué va a lograr y por qué vale la pena?"
                        />
                        <ErrorMessage
                          name="result"
                          component="div"
                          className="frmErrorCategory"
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <label htmlFor="objective">
                          ¿Cuál es el objetivo de la táctica?
                        </label>
                        <div style={{}}>
                          <Field
                            name="objective"
                            as="textarea"
                            className="inputTextarea main-btn-align form-control"
                            placeholder="¿Que obtendrá al finalizar la táctica?"
                          />
                          <ErrorMessage
                            name="objective"
                            component="div"
                            className="frmErrorCategory"
                          />
                        </div>
                      </Form.Group>
                    </Row>
                    <Form.Group>
                      <div className="tactics-label">
                        <label htmlFor="description">
                          ¿Cómo describes está táctica?
                        </label>
                        <span>Esta es una presentación general.</span>
                      </div>
                      <Field
                        name="description"
                        as="textarea"
                        className="inputTextarea main-btn-align form-control"
                        placeholder="Describe tu táctica en uno o dos párrafos"
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="frmErrorCategory"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div className="container-tacticBtn">
                <StepsContainer
                  stage={stage}
                  formik={formik}
                  editMode={editMode}
                />
              </div>
            </FormFormik>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Stage1;
