import React from "react";
import Logra from "../../assets/images/logrador/lograLogo.png";
import LogradorImg from "../../assets/images/logrador/logradorIndicator.png";
import "../../assets/styles/pages/logrador/index.scss";
import { LogradorLocation } from "./LogradorLocation";

export const LogradorExpert = ({ setLocationLogrador, locationLogrador }) => {
  return (
    <main className="logrador-container">
      <section className="logrador-sectionInfo">
        <div>
          <img src={Logra} alt="Logra logo" />
        </div>
        <h1>Cómo te posicionarás como experto dominante 💪</h1>
        <div>
          <img src={LogradorImg} alt="Logrador misión" />
        </div>
        <div className="logrador-cardMsg">
          <div className="logrador-titleCard">
            <p>
              Las MiPyMEs y sus equipos buscan
              <span> resolver retos de negocio de manera efectiva.</span>
            </p>
          </div>
          <p>
            Quieren descubrir y aprender sobre problemas o retos que no conocen
            antes de poder implementar acciones concretas para otener
            resultados rápidos y tangibles.
          </p>
        </div>
        <div className="logrador-cardMsg">
          <div className="logrador-titleCard">
            <p>
              En tu biblioteca agregas recursos y soluciones
              <span> que resuelvan retos de negocio.</span>
            </p>
          </div>
          <ul className="logrador-listInfo">
            <li>
              Puedes agregar artículos, videos, herramientas, etc. que sean
              propios o no.
            </li>
            <li>
              Las MiPyMEs y sus equipos encuentran tus recursos buscando
              resolver retos.
            </li>
            <li>
              Si tus recursos son valiosos, te califican bien. Mejora tu
              posicionamiento.
            </li>
            <li>
              Al estar bien posicionado incrementas tu posibilidad de generar
              más clientes.
            </li>
          </ul>
        </div>
        <button
          type="button"
          className="logrador-btnSection"
          onClick={() => setLocationLogrador(4)}
        >
          Siguiente
        </button>
      </section>
      <section className="logrador-sectionLocation">
        <LogradorLocation locationLogrador={locationLogrador} />
      </section>
    </main>
  );
};
