import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import "tinymce/tinymce";

// Theme
import "tinymce/themes/silver";
// // Toolbar icons
import "tinymce/icons/default";
// // Editor styles
import "tinymce/skins/ui/oxide/skin.min.css";

// importing the plugin js.
// import "tinymce/plugins/advlist";
// import "tinymce/plugins/autolink";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/lists";
// import "tinymce/plugins/charmap";
// import "tinymce/plugins/hr";
// import "tinymce/plugins/anchor";
// import "tinymce/plugins/searchreplace";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
// import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/media";
// import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/table";
// import "tinymce/plugins/template";
import "tinymce/plugins/autoresize";

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
// import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
// import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';

export const TextRich = ({ descriptionStep, step, change }) => {

  return (
    <>
      <Editor
        textareaName="description"
        // onInit={(evt, editor) => (descriptionStep.current = editor)}
        value={step}
        // initialValue="<p>This is the initial content of the editor.</p>"
        onEditorChange={(newValue, editor) => {
          // setPaso(newValue);
          change('description', newValue)
          // setText(editor.getContent({format: 'text'}));
        }}
        init={{
          // height: 300,
          resize: true,
          menubar: true,
          statusbar: false,
          plugins: ["image", "lists", "fullscreen", "table", "link", "autoresize"],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
    </>
  );
};
