import React, { useState, useEffect } from "react";
import Lodash from "lodash";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Container, Row, Col, Form, Image, Spinner } from "react-bootstrap";
import mockData from "../../assets/content/onboardingMock";
import {
  BoxRadioOptions,
  BoxRadioOptions2,
  RadioOption,
  RadioOption2,
  BoxOption,
  TagOption,
} from "./oboardingStyles";

import api from "../../utils/axiosUsage";
import logoBgAdd from "../../assets/images/onboarding/logo-bg-add.png";
import checkIcon from "../../assets/images/icons/CheckIcon.svg";

import { getCurrentUser, setCurrentUser } from "../../utils/auth";

const FrmAboutExperience = (props) => {
  const {
    personName,
    changeStep,
    step,
    changeInstitutionName,
    editMode,
    profileData,
    setProfileData,
    setAlertMessage,
    setShowAlert,
  } = props;
  const [loggedUser, setLoggedUser] = useState({});
  const [imgPreview, setImgPreview] = useState(null);
  const [pictureData, setPictureData] = useState(null);
  const [companyColors, setCompanyColors] = useState(mockData.companyColors);
  const [currentRadioOptions, setCurrentRadioOptions] = useState({
    contentAsTactic: mockData.contentAsTactic,
    organizeWebinar: mockData.organizeWebinar,
  });
  const [currentTagOptions, setCurrentTagOptions] = useState({
    segmentationType: mockData.segmentationType,
    comunicationType: mockData.comunicationType,
    serviceType: mockData.serviceType,
    expertiseArea: mockData.expertiseArea,
    expertContent: mockData.expertContent,
  });

  const [optExpertDescribe, setOptExpertDescribe] = useState(
    mockData.expertDescribe
  );

  const [isDisabled, setIsDisabled] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    (async () => {
      const userData = await getCurrentUser();
      setLoggedUser(userData);

      if (editMode && profileData) {
        // ¿Como te defines mejor?
        const dataExpertDescribe = [...optExpertDescribe];
        const keySelected = Lodash.findKey(mockData.expertDescribe, {
          selection: profileData.expert_best_definition,
        });
        
        if (profileData && keySelected !== undefined) {
          dataExpertDescribe[keySelected].value = true;
          setOptExpertDescribe(dataExpertDescribe);
        }
        
        // Expertise and Content.
        const dataTagExpert = { ...currentTagOptions };
        const expertiseAreaData = dataTagExpert["expertiseArea"];
        const expertContentData = dataTagExpert["expertContent"];

        expertiseAreaData[0].value =
          profileData.expert_expertise_area_strategy_direction;
        expertiseAreaData[1].value =
          profileData.expert_expertise_area_hr_team_culture;
        expertiseAreaData[2].value =
          profileData.expert_expertise_area_mkt_sales;
        expertiseAreaData[3].value =
          profileData.expert_expertise_area_finance_admin;
        expertiseAreaData[4].value = profileData.expert_expertise_area_tech;

        expertContentData[0].value = profileData.expert_content_social_networks;
        expertContentData[1].value = profileData.expert_content_youtube;
        expertContentData[2].value = profileData.expert_content_magazines;
        expertContentData[3].value = profileData.expert_content_lms;
        expertContentData[4].value = profileData.expert_content_blog;
        expertContentData[5].value = profileData.expert_content_others;

        dataTagExpert["expertiseArea"] = expertiseAreaData;
        dataTagExpert["expertContent"] = expertContentData;
        setCurrentTagOptions(dataTagExpert);
      }
    })();
  }, []);

  const createSlugName = (name) => {
    let slug = name.toLowerCase();
    slug = slug.replaceAll(" ", "");

    return slug;
  };

  const updateExperience = (form) => {
    const objExpertDefinition = Lodash.find(optExpertDescribe, { value: true });

    const profileDataToUpdate = {
      is_expert: true,

      expert_legal_category: "Persona Física",
      expert_best_definition: objExpertDefinition.selection,

      expert_expertise_area_strategy_direction:
        currentTagOptions.expertiseArea[0].value,
      expert_expertise_area_hr_team_culture:
        currentTagOptions.expertiseArea[1].value,
      expert_expertise_area_mkt_sales: currentTagOptions.expertiseArea[2].value,
      expert_expertise_area_finance_admin:
        currentTagOptions.expertiseArea[3].value,
      expert_expertise_area_tech: currentTagOptions.expertiseArea[4].value,

      expert_content_social_networks: currentTagOptions.expertContent[0].value,
      expert_content_youtube: currentTagOptions.expertContent[1].value,
      expert_content_magazines: currentTagOptions.expertContent[2].value,
      expert_content_lms: currentTagOptions.expertContent[3].value,
      expert_content_blog: currentTagOptions.expertContent[4].value,
      expert_content_others: currentTagOptions.expertContent[5].value,
    };

    if (editMode) {
      setIsProcessing(true);
      setIsDisabled(true);
    }

    api
      .axiosAuthPatch(
        `/api/v1/profile-crud/${loggedUser.id}/`,
        loggedUser.token,
        profileDataToUpdate
      )
      .then((r) => {
        if (editMode) {
          setShowAlert(true);
          if (r.status === 200) {
            const newProfileData = { ...profileData };

            // ¿Cómo te defines mejor?
            newProfileData.expert_best_definition =
              r.data.expert_best_definition;

            // Expertise Area
            newProfileData.expert_expertise_area_strategy_direction =
              r.data.expert_expertise_area_strategy_direction;
            newProfileData.expert_expertise_area_hr_team_culture =
              r.data.expert_expertise_area_hr_team_culture;
            newProfileData.expert_expertise_area_mkt_sales =
              r.data.expert_expertise_area_mkt_sales;
            newProfileData.expert_expertise_area_finance_admin =
              r.data.expert_expertise_area_finance_admin;
            newProfileData.expert_expertise_area_tech =
              r.data.expert_expertise_area_tech;

            // Contenido
            newProfileData.expert_content_social_networks =
              r.data.expert_content_social_networks;
            newProfileData.expert_content_youtube =
              r.data.expert_content_youtube;
            newProfileData.expert_content_magazines =
              r.data.expert_content_magazines;
            newProfileData.expert_content_lms = r.data.expert_content_lms;
            newProfileData.expert_content_blog = r.data.expert_content_blog;
            newProfileData.expert_content_others = r.data.expert_content_others;

            setProfileData(newProfileData);

            setAlertMessage(
              "✅ El Perfil de experto se actualizó correctamente"
            );
            setTimeout(() => {
              setShowAlert(false);
              setAlertMessage("");
              setIsProcessing(false);
              setIsDisabled(false);
            }, 2500);
          } else {
            setAlertMessage("❌ El Perfil de experto NO pudo ser actualizado");
            setTimeout(() => {
              setShowAlert(false);
              setAlertMessage("");
              setIsProcessing(false);
              setIsDisabled(false);
            }, 2500);
          }
          console.log("Perfil Experto Actualizado");
        } else {
          changeStep(step + 1);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const formik = useFormik({
    initialValues: {
      legalCategory: "",
    },
    onSubmit: (values) => {
      updateExperience(values);
    },
  });

  const renderDropdown = (formik, data, inputName, label) => {
    const inputOptions = data.map((element, i) => {
      return (
        <option key={`${inputName}_${i}`} value={element.value}>
          {element.description}
        </option>
      );
    });
    return (
      <Form.Group>
        <label>{label}</label>
        <Form.Control
          as="select"
          className="inputNewBrand main-btn-align"
          id={inputName}
          name={inputName}
          onChange={formik.handleChange}
          value={formik.values[inputName]}
        >
          <option value="">Selecciona</option>
          {inputOptions}
        </Form.Control>
      </Form.Group>
    );
  };

  const createImage = (file) => {
    let reader = new FileReader();

    reader.onload = (e) => {
      const preview = e.target.result;
      setImgPreview(preview);
    };

    reader.readAsDataURL(file);
  };

  const onFileChange = (e) => {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) {
      return;
    }
    const picture = files[0];
    setPictureData(picture);
    createImage(files[0]);
  };

  const selectColor = (color) => {
    const colorSelected = Lodash.find(mockData.companyColors, ["value", color]);

    const newColors = Object.values(mockData.companyColors).map((obj, i) => {
      if (obj === colorSelected) {
        obj.isSelected = true;
      } else {
        obj.isSelected = false;
      }
      return obj;
    });

    setCompanyColors(newColors);
  };

  const renderListColors = () => {
    const colors = Object.values(companyColors).map((color, i) => {
      return (
        <li
          key={i}
          onClick={() => selectColor(color.value)}
          style={color.isSelected ? { border: "solid thin #000" } : {}}
        >
          <div
            style={{
              width: "15px",
              height: "15px",
              background: color.value,
              borderRadius: "50%",
            }}
          ></div>
        </li>
      );
    });

    return colors;
  };

  const changeStatus = (keyData, element, status) => () => {
    const newRadioOptions = { ...currentRadioOptions };

    newRadioOptions[keyData][0].value = false;
    newRadioOptions[keyData][1].value = false;
    newRadioOptions[keyData][element].value = status;
    setCurrentRadioOptions(newRadioOptions);
  };

  const changeExpertDefStatus = (element, status) => () => {
    const newRadioOptions = [...optExpertDescribe];

    newRadioOptions[0].value = false;
    newRadioOptions[1].value = false;
    newRadioOptions[2].value = false;
    newRadioOptions[3].value = false;
    newRadioOptions[4].value = false;
    newRadioOptions[5].value = false;
    newRadioOptions[element].value = status;
    setOptExpertDescribe(newRadioOptions);
  };

  const changeStatusTag = (keyData, element, status) => () => {
    const newTagOptions = { ...currentTagOptions };
    newTagOptions[keyData][element].value = status;
    setCurrentTagOptions(newTagOptions);
  };

  const renderRadioOptions = (keyData) => {
    const radioOptions = Object.keys(currentRadioOptions[keyData]).map(
      (element, i) => {
        return (
          <div key={`radio-option-${element}`}>
            <RadioOption
              className={
                currentRadioOptions[keyData][element].value ? "selected" : ""
              }
              onClick={changeStatus(
                keyData,
                element,
                !currentRadioOptions[keyData][element].value
              )}
            >
              <img src={checkIcon} />
            </RadioOption>
            {currentRadioOptions[keyData][element].description}
          </div>
        );
      }
    );

    return <>{radioOptions}</>;
  };

  const renderExpertDefinition = () => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "50%", marginRight: 5 }}>
          {optExpertDescribe.map((element, i) => {
            if (i < 3) {
              return (
                <div className="optExpert" key={`expert-def-${i}`}>
                  <RadioOption2
                    className={element.value ? "selected" : ""}
                    onClick={changeExpertDefStatus(i, !element.value)}
                  >
                    <img src={checkIcon} />
                  </RadioOption2>
                  {element.description}
                </div>
              );
            }
          })}
        </div>
        <div style={{ width: "50%" }}>
          {optExpertDescribe.map((element, i) => {
            if (i >= 3) {
              return (
                <div className="optExpert" key={`expert-def-${i}`}>
                  <RadioOption2
                    className={element.value ? "selected" : ""}
                    onClick={changeExpertDefStatus(i, !element.value)}
                  >
                    <img src={checkIcon} />
                  </RadioOption2>
                  {element.description}
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  };

  const renderTagOptions = (keyData) => {
    const tagOptions = Object.keys(currentTagOptions[keyData]).map(
      (element, i) => {
        return (
          <TagOption
            key={`tag-option-${element}`}
            className={
              currentTagOptions[keyData][element].value ? "selected" : ""
            }
            onClick={changeStatusTag(
              keyData,
              element,
              !currentTagOptions[keyData][element].value
            )}
          >
            {currentTagOptions[keyData][element].description}
          </TagOption>
        );
      }
    );

    return <>{tagOptions}</>;
  };

  return (
    <>
      {editMode ? (
        <>
          <h1 className="onTitle">Perfil Experto</h1>
        </>
      ) : (
        <>
          <h2 className="onSubTitle">
            {personName} es un placer tenerte aquí 🙌
          </h2>
          <h1 className="onTitle">¿En qué eres experto? Cuéntanos.</h1>
        </>
      )}
      <div className="stepBoxForm">
        <form onSubmit={formik.handleSubmit}>
          <Container fluid className="stepBoxContainer">
            <Row>
              <Col md={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
                <Form.Group>
                  <label>¿Cómo te defines mejor?</label>
                  <BoxRadioOptions2>
                    {renderExpertDefinition()}
                  </BoxRadioOptions2>
                </Form.Group>
              </Col>
            </Row>
            {/*
            <Row>
              <Col md={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
                {renderDropdown(
                  formik,
                  mockData.legalCategory,
                  "legalCategory",
                  "¿Eres?"
                )}
                {formik.touched.legalCategory && formik.errors.legalCategory ? (
                  <div className="frmError">{formik.errors.legalCategory}</div>
                ) : null}
              </Col>
            </Row>
            
            <Row>
              <Col md={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
                <Form.Group>
                  <label>Tipo de servicio que manejas:</label>
                  <BoxOption>{renderTagOptions("serviceType")}</BoxOption>
                </Form.Group>
              </Col>
            </Row>
            */}
            <Row>
              <Col md={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
                <Form.Group>
                  <label>
                    Escoge las 3 áreas en cuales te sientes más experto.
                  </label>
                  <BoxOption>{renderTagOptions("expertiseArea")}</BoxOption>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
                <Form.Group>
                  <label>¿Cómo estás generando contenido hoy? </label>
                  <BoxOption>{renderTagOptions("expertContent")}</BoxOption>
                </Form.Group>
              </Col>
            </Row>
            {/*
            <Row>
              <Col md={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
                <Form.Group>
                  <label>
                    ¿Te interesaría subir este contenido como táctica?
                  </label>
                  <BoxRadioOptions>
                    {renderRadioOptions("contentAsTactic")}
                  </BoxRadioOptions>
                </Form.Group>
              </Col>
            </Row>
            
            <Row>
              <Col md={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
                <Form.Group>
                  <label>
                    ¿Te interesaría organizar webinar con nuestros usuarios?
                  </label>
                  <BoxRadioOptions>
                    {renderRadioOptions("organizeWebinar")}
                  </BoxRadioOptions>
                </Form.Group>
              </Col>
            </Row>
          */}
            {/*
            <Row>
              <Col
                md={12}
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Form.Group>
                  <label style={{ width: 165 }}>Logo de tu institución</label>
                  <div
                    style={{
                      margin: 15,
                      width: 100,
                      height: 100,
                      display: "inline-block",
                      borderRadius: "50%",
                      border: "dashed 1px #979797",
                      backgroundImage: `url(${
                        imgPreview ? imgPreview : logoBgAdd
                      })`,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                </Form.Group>

                <label
                  htmlFor="file-upload"
                  className="btn-style main-btn-align"
                  style={{
                    width: 200,
                    backgroundColor: "#ECECEC",
                    color: "#232323",
                    justifyContent: "center",
                  }}
                >
                  Seleccionar Archivo
                </label>
                <input
                  id="file-upload"
                  type="file"
                  name="file"
                  onChange={onFileChange}
                />
              </Col>
            </Row>
            */}

            <Row style={{ marginTop: "5px" }}>
              <Col md={12} style={{ padding: "0px 0px", paddingLeft: 8 }}>
                <button
                  type="submit"
                  className={isDisabled ? "frmButtonDisabled" : "frmButton"}
                  disabled={isDisabled}
                  style={{ marginTop: 0 }}
                >
                  {isProcessing ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : !editMode ? (
                    "Continuar"
                  ) : (
                    "Guardar"
                  )}
                </button>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
    </>
  );
};

export default FrmAboutExperience;
