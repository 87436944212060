import React from "react";
import { useHistory } from "react-router-dom";
import Logrador from "../../assets/images/home/helpLogra.png";

export const UserHelp = () => {
  const history = useHistory();
  return (
    <div className="userHelp-container">
      <div className="userHelp-containerImgAndMsg">
        <img src={Logrador} alt="Logrador" />
        <div className="userHelp-containerMsg">
          <h4>¿Cómo te vas a volver un Logrador?</h4>
          <p>
            Introducción y mejores consejos para volverte una persona que
            descubre, aprende e implementa acciones para crecer.
          </p>
        </div>
      </div>
      <button type="button" onClick={() => history.push("/logrador")}>
        Descubrir cómo
      </button>
    </div>
  );
};
