import React from "react";
import Logra from "../../assets/images/logrador/lograLogo.png";
import LogradorImg from "../../assets/images/logrador/logradorMission.png";
import "../../assets/styles/pages/logrador/index.scss";
import { LogradorLocation } from "./LogradorLocation";

export const LogradorMission = ({ setLocationLogrador, locationLogrador }) => {
  return (
    <main className="logrador-container">
      <section className="logrador-sectionInfo">
        <div>
          <img src={Logra} alt="Logra logo" />
        </div>
        <h1>Nuestra misión común: impulsar la región 🌎</h1>
        <div>
          <img src={LogradorImg} alt="Logrador misión" />
        </div>
        <div className="logrador-cardMsg">
          <div className="logrador-titleCard">
            <p>
              Compartimos un objetivo:
              <span> hacer más productivas las MiPyMEs latinoaméricanas.</span>
            </p>
          </div>
          <p>
            Con MiPyMEs más sólidas, equipos más eficientes y felices: la región
            crece.
          </p>
          <p>
            El empleo, los salarios y la calidad de vida de las personas
            mejoran.
          </p>
        </div>
        <div className="logrador-cardMsg">
          <div className="logrador-titleCard">
            <p>
              Vamos a darte todas las herramientas para que
              <span> te vuelvas un experto Logrador.</span>
            </p>
          </div>

          <ul className="logrador-listInfo">
            <li>
              Estructurando tu conocimiento para que las MiPyMEs perciben valor
              tuya rápido.
            </li>
            <li>
              Organizando tu expertise en productos y servicios para que te
              contraten fácil.
            </li>
            <li>
              Dándote acceso a una red de potenciales clientes alrededor de todo
              LatAm.
            </li>
            <li>
              Mejorando constantemente la plataforma contigo para facilitarte la
              vida.
            </li>
          </ul>
        </div>
        <button
          type="button"
          className="logrador-btnSection"
          onClick={() => setLocationLogrador(2)}
        >
          Siguiente
        </button>
      </section>
      <section className="logrador-sectionLocation">
        <LogradorLocation locationLogrador={locationLogrador} />
      </section>
    </main>
  );
};
