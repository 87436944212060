import React, { useState } from "react";
import { LogradorPymes } from "../../Components/logrador/LogradorPymes";
import { LogradorExpert } from "../../Components/logrador/LogradorExpert";
import { LogradorLevel } from "../../Components/logrador/LogradorLevel";
import { LogradorMission } from "../../Components/logrador/LogradorMission";
import { LogradorFinal } from "../../Components/logrador/LogradorFinal";

export const Logrador = () => {
  const [locationLogrador, setLocationLogrador] = useState(1);
  const sectionsLogrador = [
    {
      id: 1,
      section: (
        <LogradorMission
          setLocationLogrador={setLocationLogrador}
          locationLogrador={locationLogrador}
        />
      ),
    },
    {
      id: 2,
      section: (
        <LogradorPymes
          setLocationLogrador={setLocationLogrador}
          locationLogrador={locationLogrador}
        />
      ),
    },
    {
      id: 3,
      section: (
        <LogradorExpert
          setLocationLogrador={setLocationLogrador}
          locationLogrador={locationLogrador}
        />
      ),
    },
    {
      id: 4,
      section: (
        <LogradorLevel
          setLocationLogrador={setLocationLogrador}
          locationLogrador={locationLogrador}
        />
      ),
    },
    {
      id: 5,
      section: <LogradorFinal />,
    },
  ];

  return <>{sectionsLogrador[locationLogrador - 1].section}</>;
};
