import React, { useState, useEffect } from "react";
import "../../assets/styles/pages/library/index.scss";

import wcBannerMedia1 from "../../assets/images/welcome-banner-media/welcomeBannerMedia1.png";
import wcBannerMedia2 from "../../assets/images/welcome-banner-media/welcomeBannerMedia2.png";
import wcBannerMedia3 from "../../assets/images/welcome-banner-media/welcomeBannerMedia3.png";
import stack_list from "../../assets/images/icons/stack_list.svg";
import stack_card from "../../assets/images/icons/stack_card.svg";
import api from "../../utils/axiosUsage";
import { getCurrentUser } from "../../utils/auth";
import ModalShare from "../../Components/ModalMessage/ModalShare";
import {
  Loading,
} from "@logra/component-library";
import LograBanner from "../../Components/Banner/LograBanner";
import { TacticCardTemp } from "../../Components/TacticCardTemp/TacticCardTemp";
import { useHistory } from "react-router-dom";
import { ModalTemp } from "../../Components/ModalTemp/ModalTemp";
import { Container, Col, Row } from "react-bootstrap";

const Library = () => {
  const [tacticsData, setTacticsData] = useState([]);
  const [loggedUser, setLoggedUser] = useState({});
  const [contentLoaded, setContentLoaded] = useState(false);
  const currentUser = getCurrentUser();
  const [showModalShare, setShowModalShare] = useState({
    active: false,
    info: {
      id: "",
      title: "",
      slugUrl: "",
    },
    type: "tactic",
  });
  const [Stack, setStack] = useState("Stack");
  const [innerContent, setInnerContent] = useState(false);
  const [tab, setTab] = useState("Tacticas");
  const [showModal, setShowModal] = useState(false);
  const [expert, setExpert] = useState({});
  const [showHelpBanner, setShowHelpBanner] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [enableContentCreation, setEnableContentCreation] = useState(false);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (currentUser.email.includes("logra")) {
        console.log("logra domain in email");
        localStorage.setItem("lograUser", true);
        setEnableContentCreation(true);
      } else {
        console.log("no logra domain in email");
        localStorage.setItem("lograUser", false);
        setEnableContentCreation(true);
      }

      let ls;
      ls = localStorage.getItem("startWithModal");
      if (ls === null || !ls) {
        localStorage.setItem("startWithModal", true);
      } else {
        setShowModal(false);
      }
    })();
  }, []);

  useEffect(
    (active, draft) => {
      (async () => {
        const userData = await getCurrentUser();
        setLoggedUser(userData);
        getProfileInformation(userData, userData.token);
        getTactics(userData, userData.token);
        setContentLoaded(true);
        setInnerContent(true);
      })();
      return () => {
        setInnerContent(false);
      };
    },
    [tab, Stack, reRender]
  );

  const getProfileInformation = async (user, token) => {
    try {
      const expert_info = await api.axiosAuthGet(
        `/api/v1/expert-profile/${user.userUrlSlug}/`,
        token
      );
      if (expert_info.status === 200) {
        setExpert(expert_info.data);
      } else {
        setExpert({});
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTactics = async (user, token) => {
    try {
      const tactics = await api.axiosAuthGet(
        `/cms/api/v2/tactics-by-owner/${user.id}/`,
        token
      );

      if (tactics.status === 200) {
        setTacticsData(tactics.data.tactics_by_owner);
      } else {
        setTacticsData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const tacticsBanner = () => {
    return (
      <LograBanner
        startWithBanner={true}
        onClose={() => setShowHelpBanner(false)}
        bannerActionLabel={"Crea una táctica"}
        bannerAction={() =>
          history.push(`${enableContentCreation ? "/tactic/new" : "/library"}`)
        }
        slides={[
          {
            title:
              "Comparte fácilmente cada una de tus tácticas o tu perfil de experto 🔗",
            body: "Utiliza el URL único para compartir cualquier táctica en LinkedIn o donde sea a quien la necesita.",
            backgroundColor: "#e57a74",
            bannerActionLabel: `${enableContentCreation
              ? "Crear mi primera tactica"
              : "Más sobre las tacticas"
              }`,
          },
          {
            title:
              "Obtén datos y genera prospectos calificados gracias a tus tácticas 📊",
            body: "Todas las personas que implementan tus tácticas aparecen en tu dashboard de análisis de datos.",
            backgroundColor: "#e57a74",
            bannerActionLabel: `${enableContentCreation
              ? "Crear mi primera tactica"
              : "Más sobre las tacticas"
              }`,
          },
          {
            title:
              "Administra tu reputación y genera nuevas oportunidades de negocio 💸️",
            body: "Mientras mejor calificación y comentarios de.", //tus tácticas, mejor posicionamiento dentro de la red
            backgroundColor: "#e57a74",
            bannerActionLabel: `${enableContentCreation
              ? "Crear mi primera tactica"
              : "Más sobre las tacticas"
              }`,
          },
          {
            title:
              "Convierte tu contenido y conocimiento en tácticas accionables para empresas ✅",
            body: "Creamos el formato ideal para que las PyMEs implementan cualquier acción, herramienta, solución o mejor práctica.",
            backgroundColor: "#e57a74",
            bannerActionLabel: `${enableContentCreation
              ? "Crear mi primera tactica"
              : "Más sobre las tacticas"
              }`,
          },
        ]}
      />
    );
  };

  const renderBanner = () => {
    //render if there are no tactics
    if (tab === "Tacticas" && tacticsData?.length === 0) {
      return tacticsBanner();
      }
    if (tab === "Tacticas" && showHelpBanner) {
      return tacticsBanner();
    } 
  };

  function switchTab(str) {
    switch (str) {
      case "Tacticas":
        return <TabButton typeTab="tactic" txt="Crea una táctica" />;
       default:
        return null;
    }
  }

  const TabButton = ({ typeTab, txt }) => {
    if (enableContentCreation) {
      return (
        <button
          className="library-btn"
          onClick={() => history.push(`/${typeTab}/new`)}
        >
          {txt}
        </button>
      );
    } else {
      return null;
    }
  };
  function renderTabContent(tab) {
    if (tab === "Tacticas") {
      return tacticsData !== undefined &&
        loggedUser !== undefined &&
        tacticsData.length > 0 ? (
        <div className={Stack === "Stack" && "GridViewContent"}>
          {tacticsData.map((tactic, index) => (
            <TacticCardTemp
              user={{
                name: expert?.name,
                profilePicture: expert?.profile_photo,
              }}
              category={tactic.category}
              title={tactic.title}
              subcategory={tactic.subcategory}
              growth_challenge={tactic.growth_challenge}
              key={index}
              cardStyle={Stack === "Stack" ? "Card" : "List"}
              is_active={tactic.is_active}
              is_draft={tactic.is_draft}
              onView={() => history.push(`/tactic/view/${tactic.id}`)}
              onEdit={() =>
                history.push(`/tactic/edit/${tactic.id}`, {
                  tactic: tactic,
                })
              }
              onShare={() =>
                setShowModalShare({
                  active: true,
                  info: {
                    id: "id",
                    title: "Compartir Tactica",
                    slugUrl: `${tactic.slug_url}`,
                  },
                  type: "tactic",
                })
              }
              id={tactic.id}
              token={loggedUser.token}
            />
          ))}
        </div>
      ) : (
        <div className="banner-section">
          <p
            className="responsive-FontSize"
            style={{
              color: "#7A4199",
              marginTop: "0.4rem",
              wordWrap: "break-word",
            }}
          >
            Aún no tienes tácticas creadas 🤷‍♂️
          </p>
          {renderBanner()}
        </div>
      );
    } else {
      return undefined;
    }
  }
  

  return !contentLoaded ? (
    <div className="container-profile-expertLoading">
      <Loading />
    </div>
  ) : (
    <>
      <ModalTemp
        slides={[
          {
            title: "Bienvenido en tu librería de contenido 👋",

            media: wcBannerMedia1,
            content: (
              <>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "1.5vw",
                    lineHeight: "120%",
                    marginBottom: "3rem",
                  }}
                  className={"responsiveOverrides"}
                >
                  Puedes organizar{" "}
                  <strong>tu conocimiento y contenido de valor</strong> en un
                  solo lugar.
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "1.5vw",
                    lineHeight: "120%",
                    color: "#2c5999",
                  }}
                  className={"responsiveOverrides"}
                >
                  Y compartir a cualquiera <strong>tu librería pública!</strong>
                </p>
              </>
            ),
          },
          {
            title: "Pronto te ayudará a generar prospectos 💸️",
            media: wcBannerMedia2,
            content: (
              <>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "1.5vw",
                    lineHeight: "120%",
                    marginBottom: "3rem",
                  }}
                  className={"responsiveOverrides"}
                >
                  Tendrás acceso a un
                  <strong>análisis de datos precisos </strong> de los perfiles y
                  tipos de PyMEs que ven tu librería.
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "1.5vw",
                    lineHeight: "120%",
                    color: "#2c5999",
                  }}
                  className={"responsiveOverrides"}
                >
                  ¡Y pronto{" "}
                  <strong>
                    a la lista de prospectos que implementen tus tácticas!
                  </strong>
                </p>
              </>
            ),
          },
          {
            title: "Y tendrás acceso a más herramientas 📊",
            media: wcBannerMedia3,
            content: (
              <>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "1.5vw",
                    lineHeight: "120%",
                    marginBottom: "3rem",
                  }}
                  className={"responsiveOverrides"}
                >
                  Una página personalizable para invitar empresas{" "}
                  <strong>
                    en detectar sus retos mientras ves el resultado.
                  </strong>
                </p>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "1.5vw",
                    lineHeight: "120%",
                    color: "#2c5999",
                  }}
                  className={"responsiveOverrides"}
                >
                  ¡Un ejemplo de cómo{" "}
                  <strong>ayudarte a escalar tu negocio más fácilmente!</strong>
                </p>
              </>
            ),
          },
        ]}
        showModal={showModal}
        setShowModal={setShowModal}
        onClose={() => localStorage.setItem("startWithModal", false)}
      />

      <ModalShare
        show={showModalShare.active}
        onHide={() =>
          setShowModalShare({
            active: false,
            info: {
              id: "",
              title: "",
              slugUrl: "",
            },
          })
        }
        title={showModalShare.info.title}
        slug={showModalShare.info.slugUrl}
        type={showModalShare.type}
      />
      <Container fluid lg={12} sm={6}>
        <div
          className="container-homeMain"
          style={{ marginLeft: "1.6rem", height: "100vh", width: "100vw" }}
        >
          <Row>
            <Col>
              <div className="stack-head" style={{ padding: "0.8rem" }}>
                <h3
                  className="stack-title"
                  style={{ fontWeight: "bold", color: "#212529" }}
                >
                  {loggedUser?.name}, esta es tu biblioteca de recursos
                </h3>
                <h5 className="stack-subtitle">
                  Agrega y administra tu conocimiento, artículos y herramientas
                  aquí.
                </h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={8} sm={12}>
              <div className="stack-control-tab" style={{ padding: "0.8rem" }}>
                <div className="stack-tabs" style={{ width: "fit-content" }}>
                  <button
                    onClick={() => setTab("Tacticas")}
                    className={`${tab === "Tacticas" && "activeTab"} tabButton`}
                  >
                    <h5 className="tabLabel">Tus tácticas</h5>
                  </button>
                  <div style={{ margin: "auto" }}>
                    <button
                      onClick={() => {
                        setShowHelpBanner(!showHelpBanner);
                      }}
                      className="helpButton"
                    >
                      <h5 className="help-label">?</h5>
                    </button>
                  </div>
                </div>
              </div>
            </Col>

            <Row>
              <Col lg={3}>
                <div
                  className="stack-control margin-stack-btns"
                  style={{ height: "100%" }}
                >
                  <div
                    className="stack-btns stack-control"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      height: "100%",
                    }}
                  >
                    <button
                      onClick={() => setStack("List")}
                      className={`${Stack === "List" && "stack-active"}`}
                    >
                      <img
                        src={stack_list}
                        alt="stack icon"
                        className="stack-img-btn"
                      />
                    </button>
                    <button
                      onClick={() => setStack("Stack")}
                      className={`${Stack === "Stack" && "stack-active"}`}
                    >
                      <img
                        src={stack_card}
                        alt="stack card"
                        className="stack-img-btn"
                      />
                    </button>
                  </div>
                </div>
              </Col>
              <Col lg={7}>
                <div className="switchTab-Container">{switchTab(tab)}</div>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col>
              {innerContent ? (
                <div className="tabcontent-section">
                  {renderTabContent(tab)}
                </div>
              ) : (
                <div className="innerContent-Loading">
                  <Loading />
                </div>
              )}
            </Col>
          </Row>
          <Row className="helperbanner-section">
            <Col>
              <div className="content-container">
                {showHelpBanner && renderBanner()}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Library;
