import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form, Accordion, Card } from "react-bootstrap";
import AccordionDisplay from "./AccordionDisplay";
import {
  CategoryTag,
  TacticTitle,
  SmallTag,
  SkillsContainer,
  TacticInfoCard,
  TacticInfoCardBottom,
  TacticInfoCardTop,
  TacticStep,
  TacticStepTop,
  TacticStepTitle,
  TacticStepStatus,
  TacticStepBottom,
  TacticHeader,
} from "../../assets/styles/pages/general/GeneralStyles";

import ChevronUp from "../../assets/images/general/ChevronUp.svg";
import LinkIcon from "../../assets/images/general/link.svg";
import ShareIcon from "../../assets/images/icons/ShareIcon";
import ModalShare from "../../Components/ModalMessage/ModalShare";

import chevronRight from "./chevron-right.svg";

const Tactic = ({ data, contentLoaded }) => {
  const history = useHistory();
  const [defaultActiveKey, setDefaultActiveKey] = useState("0");
  const [arrowPosition, setArrowPosition] = useState("left");
  const [modalShow, setModalShow] = useState(false);

  const renderSkillToDevelop = () => {
    if (data.skills_to_develop) {
      const listSkills = data.skills_to_develop.split(",");
      return listSkills.map((skill, i) => {
        return <SmallTag key={`tag-${i}`}>{skill}</SmallTag>;
      });
    } else {
      return "";
    }
  };

  const renderTacticSteps = () => {
    if (data.steps) {
      const steps = data.steps.map((step, i) => {
        return (
          <AccordionDisplay
            key={`tacticStep-${i}`}
            title={step.title}
            step={i + 1}
            arrowPosition="right"
            forceDefaultHide={true}
          >
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "33.33%",
                  marginRight: 40,
                  whiteSpace: "pre-line",
                }}
              >
                <TacticStepTitle>Descripción</TacticStepTitle>
                <p>{step.description}</p>
              </div>
              <div style={{ width: "33.33%" }}>
                <TacticStepTitle>Recursos:</TacticStepTitle>

                {step.url_tool && (
                  <>
                    <TacticStepTitle style={{ fontSize: 12, marginTop: 5 }}>
                      Herramienta:
                    </TacticStepTitle>
                    <a>
                      <img src={LinkIcon} width={14} />{" "}
                      <a
                        href={step.url_tool}
                        style={{ fontSize: 12 }}
                        target="_blank"
                      >
                        {step.url_tool}
                      </a>
                    </a>
                  </>
                )}

                {step.url_video && (
                  <>
                    <TacticStepTitle style={{ fontSize: 12, marginTop: 5 }}>
                      Video:
                    </TacticStepTitle>
                    <a>
                      <img src={LinkIcon} width={14} />{" "}
                      <a
                        href={step.url_video}
                        style={{ fontSize: 12 }}
                        target="_blank"
                      >
                        {step.url_video}
                      </a>
                    </a>
                  </>
                )}
              </div>
              <div style={{ width: "33.33%" }}></div>
            </div>
          </AccordionDisplay>
        );
        /*
        return (
          
          <TacticStep key={`tacticStep-${i}`}>
            <TacticStepTop>
              <TacticStepTitle>
                <img src={Uncheck} />
                <p>
                  <span>Paso {i + 1} - </span>
                  {step.title}
                </p>
              </TacticStepTitle>
              <TacticStepStatus>
                <ProgressTag>En proceso</ProgressTag>
                <img src={ChevronUp} />
              </TacticStepStatus>
            </TacticStepTop>
            <TacticStepBottom>
              <Row>
                <Col lg={5}>
                  <TacticStepTitle>Descripción</TacticStepTitle>
                  <p>{step.description}</p>
                </Col>
                <Col lg={5}>
                  <TacticStepTitle>Herramientas:</TacticStepTitle>

                  <a>
                    <img src={LinkIcon} /> {step.url_tool}
                  </a>
                </Col>
                <Col lg={2}>
                  
            <TacticAction>
              <a className="btn-tactic-style">Completar</a>
            </TacticAction>
            
                </Col>
              </Row>
            </TacticStepBottom>
          </TacticStep>
        );
        */
      });
      return steps;
    } else {
      return "";
    }
  };

  const renderSteps = () => {
    if (data.steps) {
      const allSteps = data.steps.map((step, i) => {
        return (
          <Card key={`step-${i}`}>
            <Accordion.Toggle
              as={Card.Header}
              eventKey={`${i}`}
              className="custom-h2-steps"
            >
              <Container>
                <Row>
                  <Col className="col-10 px-3">
                    <div
                      className="d-flex align-items-center"
                      style={{ height: "100%" }}
                    >
                      Paso {parseInt(i) + 1} - {step.title}
                    </div>
                  </Col>
                  <Col
                    className="col-2 align-self-center pl-2 py-2"
                    style={{
                      textAlign: arrowPosition ? arrowPosition : "left",
                    }}
                  >
                    <img
                      className="accordion-arrow"
                      src={chevronRight}
                      width="18"
                      height="18"
                      alt="chevron-right"
                    />
                  </Col>
                </Row>
              </Container>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={`${i}`}>
              <Card.Body>Contenido del Paso</Card.Body>
            </Accordion.Collapse>
          </Card>
        );
      });
      return allSteps;
    } else {
      return "";
    }
  };

  return (
    (data, contentLoaded) && (
      <>
        <Row className="rowLimit rowWidth rowDashboard">
          <Col lg={6}>
            <TacticHeader>
              <CategoryTag>{data?.category}</CategoryTag>
              <SmallTag>{data?.subcategory}</SmallTag>
            </TacticHeader>
          </Col>
          <Col lg={6} style={{ display: "flex", justifyContent: "flex-end" }}>
            <a
              className="btn-main-style btn-share-style"
              onClick={() => {
                setModalShow(true);
              }}
            >
              <ShareIcon />
              Compartir
            </a>
            <a
              className="btn-main-style btn-saveTemp-style"
              onClick={() => {
                history.push(`/tactic/edit/${data.id}`, { tacticInfo: data });
              }}
            >
              Editar táctica
            </a>
          </Col>
        </Row>
        <Row className="rowLimit rowWidth rowDashboard">
          <Col lg={6}>
            <TacticTitle>
              <h3>
                <span>{data.tactic_type}: </span> {data.title}
              </h3>
            </TacticTitle>
          </Col>
          <Col lg={6}>
            <SkillsContainer>
              <p>
                <strong>Habilidades que desarrollarás:</strong>
              </p>
              <br />
              <span>{renderSkillToDevelop()}</span>
            </SkillsContainer>
          </Col>
        </Row>
        <br />
        <Row className="rowLimit rowWidth rowDashboard">
          <Col lg={6}>
            <div
              className="expertTacticCardCover"
              style={{
                backgroundImage: `url(${data.cover_image})`,
                minHeight: "380px",
              }}
            />
          </Col>

          <Col lg={6}>
            <TacticInfoCard>
              <TacticInfoCardTop>
                <p>
                  <strong>Por: </strong> Autor
                </p>
                <br />
                <p>{data.overview}</p>
              </TacticInfoCardTop>
              <TacticInfoCardBottom>
                <p>
                  <strong>Resultado esperado:</strong>
                  <br />
                  {data.outcome}
                </p>
                <p>
                  <strong>Etapa de empresa:</strong>
                  <br />
                  {data.business_stage}
                </p>
              </TacticInfoCardBottom>
            </TacticInfoCard>
          </Col>
        </Row>
        <Row className="rowLimit rowWidth rowDashboard">
          <Col>
            <div style={{ marginTop: "20px" }}>{renderTacticSteps()}</div>

            {/*
            <TacticStep>
              <TacticStepTop>
                <TacticStepTitle>
                  <img src={Check} />
                  <p>
                    <span>Paso 1 - </span>Define el objetivo
                  </p>
                </TacticStepTitle>
                <TacticStepStatus>
                  <CompletedTag>Completada</CompletedTag>
                  <img src={ChevronDown} />
                </TacticStepStatus>
              </TacticStepTop>
              <TacticStepBottom></TacticStepBottom>
            </TacticStep>
            */}
            {/*
            <Accordion defaultActiveKey={defaultActiveKey}>
              {renderSteps()}
            </Accordion>
            {renderTacticSteps()}
            */}
          </Col>
        </Row>
        <ModalShare
          show={modalShow}
          onHide={() => setModalShow(false)}
          editMode={false}
          tacticId={data.id}
          title={data.title}
          slug={data.slug_url}
        />
      </>
    )
  );
};

export default Tactic;
