import { useEffect, useState } from "react";
import { getCurrentUser } from "../../utils/auth";
import api from "../../utils/axiosUsage";

export const useTactics = () => {
  const [tactics, setTactics] = useState([]);

  const fetchTactics = async () => {
    const userData = await getCurrentUser();
    const response = await api.axiosAuthGet(
      `/cms/api/v2/tactics-by-owner/${userData.id}/`,
      userData.token
    );
    if (response && response.data) setTactics(response.data);
  };

  useEffect(() => {
    fetchTactics();
  }, []);

  return { tactics };
};
