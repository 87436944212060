import React from "react";
import { Table } from "react-bootstrap";
import { TableRowResource } from "./TableRowResource";
// import "../../assets/styles/pages/expert/table.scss";
// import { TableRow } from "./TableRow";

const TableContent = ({ resources, loggedUser }) => {
  return (
    <div className="container-table">
      <Table responsive>
        <thead>
          <tr>
            <th>Título, área y reto de crecimiento</th>
            <th>Tipo de recurso</th>
            <th>Objetivo</th>
            <th className="table-field-center">Calificación</th>
            <th>Acciones</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {resources.map((resource, i) => (
            <TableRowResource
              key={i}
              title={resource.title}
              category={resource.category}
              subCategory={resource.subcategory}
              id={resource.id}
              resourceType={resource.resource_type}
              objective={resource.objective}
              active={resource.is_active}
              token={loggedUser.token}
              draft={resource.is_draft ? false : true}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TableContent;
