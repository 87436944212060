import React, { useEffect, useState } from "react";
import { Button } from "@logra/component-library";
import "./GrowthPlanCard.css";

export const GrowthPlanCard = ({
  category,
  challenges,
  status, //might change for is_active, is_draft like the tactic card comp
}) => {
  const [tacticChallenges, setTacticChallenges] = useState();

  const setColor = (category) => {
    if (category.includes("Estrategia")) return "#e57771";
    else if (category.includes("Marketing")) return "#f1ad5d";
    else if (category.includes("Finanzas")) return "#e081ac";
    else if (category.includes("Tecnología")) return "#af87b5";
    else if (category.includes("Cultura")) return "#6a79b5";
    else if (category.includes("Ventas")) return "#f1ad5d";
    else return "darkSlateGrey";
  };

  const handleStatusChange = async () => {
    // try {
    //   if (is_active === true && is_draft === false) {
    //     await api.axiosAuthPatch(
    //       `/cms/api/v2/tactics/${id}/`,
    //       token,
    //       {
    //         is_active: false,
    //         is_draft: true
    //       }
    //     );
    //   }
    //   if (is_active === false && is_draft === true) {
    //     await api.axiosAuthPatch(
    //       `/cms/api/v2/tactics/${id}/`,
    //       token,
    //       {
    //         is_active: true,
    //         is_draft: false
    //       }
    //     );
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  // useEffect(() => {
  //   getTacticStatus();
  //   //in progress -> etc
  // }, []);

  return (
    <div className={"gcp-card"}>
      <div
        className="gcp-top logra-pattern"
        style={{
          backgroundColor: `${setColor(category)}`,
          filter: `${!status ? "brightness(70%)" : ""} `,
        }}
      >
        <h3 onClick={() => console.slog(".")} className="gcp-title">
          {category}
        </h3>
      </div>

      <div
        className="gcp-bottom"
        style={{ backgroundColor: `${!status ? "#a7a8ac" : ""} ` }}
      >
        {status && (
          <div
            className="row gcp-row"
            style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
          >
            <div className="challenge-icon gcp-icon"></div>
            <p className="gcp-p">{challenges.length} retoss a reforzar</p>
          </div>
        )}

        {status ? (
          <>
            <div className="row gcp-row">
              <p className={"gpc-status"}>En progreso </p>
            </div>
            <div className="row gcp-row gcp-btnOverrides">
              <Button
                label="Ir a mi plan"
                size="medium"
                primary
                onClick={() => console.log("")}
              />
            </div>
          </>
        ) : (
          <div style={{ marginTop: "5rem" }}>
            <div className="row gcp-row gcp-btnOverrides">
              <Button
                label="Hacer mi diagnóstico"
                size="medium"
                primary
                onClick={() => console.log("")}
              />
            </div>
            <div className="row gcp-row gcp-btnOverrides gcp-btnOverrides2">
              <Button
                label="Invitar miembro del equipo"
                size="small"
                onClick={() => console.log("")}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
