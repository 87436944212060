import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../utils/axiosUsage";
import Star from "../../assets/images/tactics/Star.png";

export const TableRowResource = ({
  title,
  category,
  subCategory,
  id,
  resourceType,
  objective,
  active,
  token,
  draft,
}) => {
  const history = useHistory();
  const [isPublic, setIsPublic] = useState(draft);

  const colorCategory = (category) => {
    switch (category) {
      case "Estrategia":
        return "planning";
      case "Equipo":
        return "team";
      case "Marketing":
        return "marketing";
      case "Finanzas":
        return "admi";
      case "Tecnología":
        return "tech";
      case "Ventas":
        return "marketing";
      default:
        return;
    }
  };

  const onChangeStatus = async () => {
    try {
      setIsPublic((prev) => !prev);
      await api.axiosAuthPatch(
        `/cms/api/v2/indexed-content-crud/${id}/`,
        token,
        {
          is_draft: isPublic,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <tr>
      <td>
        <div>
          <div className="table-categoryAndSub">
            <div className={`table-category ${colorCategory(category)}`}>
              <p>{category || "Sin información"}</p>
            </div>
            <div className="table-subCategory">
              <p>{subCategory || "Sin información"}</p>
            </div>
          </div>
          <p className="table-title">{title}</p>
        </div>
      </td>
      <td>
        <p className="table-element">{resourceType}</p>
      </td>
      <td>
        <p className="table-element">{objective}</p>
      </td>
      <td>
        <div className="table-rating">
          <img src={Star} alt="Calificación" />
          <p>0</p>
        </div>
      </td>
      <td>
        <button
          className="resourceTable-edit"
          onClick={() => history.push(`/resource/edit/${id}`)}
        >
          Editar
        </button>
      </td>
      <td>
        {!active ? (
          <div className="resoruceTable-statusIncomplete">
            <p>Incompleta</p>
          </div>
        ) : (
          <div
            className={`container-resourceTable-switch ${isPublic && "active"}`}
          >
            <div
              className={`resourceTableSwitch ${isPublic && "active"}`}
              // onClick={() => {
              //   setStatus((prev) => !prev);
              //   onChangeStatus();
              // }}
              onClick={onChangeStatus}
            />
          </div>
        )}
      </td>
    </tr>
  );
};
