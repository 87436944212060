import React, { useEffect, useState } from "react";
import { UserHelp } from "../../Components/home/UserHelp";
import { HomeActions } from "../../Components/home/HomeActions";
import { useTactics } from "./useTactics";
import { getCurrentUser } from "../../utils/auth";
import "../../assets/styles/pages/home/index.scss";

function NewHomePage() {
  const { tactics } = useTactics();
  const [haveContent, setHaveContent] = useState(false);
  const user = getCurrentUser();

  useEffect(() => {
    if (tactics && tactics.tactics_count > 0) {
      setHaveContent(true);
    } else {
      setHaveContent(false);
    }
  }, [tactics]);

  return (
    <main className="home-container">
      <section className="home-welcome">
        <h1>Bienvenido {user.name} 👋</h1>
        <p className="home-welcomeMsg">
          Aquí es dónde encuentras todas las novedades.
        </p>
        <hr />
        <UserHelp />
        <HomeActions haveContent={haveContent} />
      </section>
    </main>
  );
}

export default NewHomePage;
