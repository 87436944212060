import React from "react";
import { Switch, Route } from "react-router-dom";
import { GlobalStyle } from "../Components/Styles/GlobalStyle";
import LoginPage from "../Pages/Login/LoginPage";
import RecoveryPassword from "../Pages/Login/RecoveryPassword";
import OnboardingSteps from "../Pages/Onboarding/steps";
import StartPage from "../Pages/Start/StartPage";

const routesList = [
  {
    auth: false,
    component: LoginPage,
    exact: true,
    name: "login",
    path: "/start/login",
  },

  {
    auth: false,
    component: RecoveryPassword,
    exact: true,
    name: "recoveryPassword",
    path: "/start/resetpassword/:token",
  },
  {
    auth: false,
    component: StartPage,
    exact: true,
    name: "startPage",
    path: "/start",
  },
  {
    auth: false,
    component: OnboardingSteps,
    exact: true,
    name: "onboarding",
    path: "/start/onboarding",
  },
];

export const RouterAuth = () => {
  return (
    <div>
      <GlobalStyle />
      <Switch>
        {routesList.map((route, index) => (
          <Route
            exact={route.exact}
            path={route.path}
            component={route.component}
            key={index}
          />
        ))}
      </Switch>
    </div>
  );
};
