import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import StartSlider from "../../Components/StartSlider/StartSlider";
import "../../assets/styles/pages/index.scss";
import { LograLink } from "../../assets/styles/pages/general/GeneralStyles";
import GoogleLoginBtn from "../../Components/Button/GoogleLoginBtn";
import api from "../../utils/axiosUsage";
import { getCurrentUser, handleLogin } from "../../utils/auth";
import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { AuthContext } from "../../context/auth/AuthContext";

const StartPage = () => {
  const history = useHistory();
  const [userEmail, setUserEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);

  const [user, setUser] = useState({});

  const [error, setError] = useState("");
  const { login } = useContext(AuthContext);

  const setEmail = (event) => {
    setEmailValid(true);
    setUserEmail(event.target.value);
  };

  const validateEmail = (email) => {
    const pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/g;
    const result = pattern.test(email);
    return result;
  };

  const handleGoogleSignup = async (gmail, gauth, name, lastname, imageUrl) => {
    setError(false);

    if (gmail === "" || gauth === "") {
      setError(true);
    } else {
      const credentials = {
        email: gmail,
        google_oauth_token: gauth,
        name,
        lastname,
        imgUser: imageUrl,
      };
      //the user object needs these data which google provides, send it to them

      //console.log('yikes', credentials);

      let response = await api.axiosPost("/api/v1/signup", credentials);

      if (response !== undefined && response !== null) {
        if (response.status === 201) {
          //console.log("yikes, get token and id to upload image? ", response);
          handleGoogleLogin(gmail, gauth, name, lastname, "home", imageUrl);
          // handleGoogleLogin(gmail, gauth, name, lastname, 'home', imageUrl)

          // handleGoogleLogin(gmail, gauth, name, lastname, imageUrl, 'onboarding')
          //console.log('the image sent', imageUrl)
        }
      } else {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      }
    }
  };

  //this method signature is wrong, might be the case on why this dont redirect to the onboarding page, change it l8r
  const handleGoogleLogin = async (gmail, gauth, name, lastname, imageUrl) => {
    //console.log('yikes')
    if (gmail === "" || gauth === "") {
      setError(
        "Oops! Ocurrio un error al autenticar con google, intentalo nuevamente"
      );
    } else {
      //console.log('yikes 2 but i have the image', imageUrl)

      let uploadThisImage = imageUrl;
      const credentials = { email: gmail, google_oauth_token: gauth };

      let response = await api.axiosPost("/api/v1/login", credentials);

      if (typeof response !== "undefined" && response !== null) {
        //console.log('yikes 3')
        if (response.status === 200) {
          //console.log('yikes 4')
          //store user auth type if signed in with o auth
          localStorage.setItem(
            "is_oauth_account",
            response.data.is_oauth_account
          );

          //good
          let accountInfo = await api.axiosAuthGet(
            `/api/v1/profile-crud/${response.data.id}/`,
            response.data.user_token
          );

          //console.log('here ill get the data of the user to patch the image ', response.data)
          //console.log('imma send u this image', uploadThisImage)
          //let updatePicture = {
          //  ...accountInfo.data,
          //  profile_photo: uploadThisImage
          //}
          //console.log('comes good ', updatePicture)

          //let patchImage = await api.axiosAuthPatch(
          //  `/api/v1/profile-crud/${response.data.id}/`,
          //  response.data.user_token,
          //  updatePicture,
          //)
          // console.log('image patch response', patchImage)

          const corpId = accountInfo.data.corp;
          const userUrlSlug = accountInfo.data.user_url_slug;
          response.data.companyId = corpId;
          response.data.userUrlSlug = userUrlSlug;

          const handleLoginResult = await handleLogin(response);

          if (handleLoginResult !== undefined && handleLoginResult !== null) {
            history.replace("/home");
            window.localStorage.isAuthenticaded = JSON.stringify(true);
            login({
              name,
              token: gauth,
            });
          }
        }
      } else {
        handleGoogleSignup(gmail, gauth, name, lastname, imageUrl);
        console.log("yikes 5");
        //setError("Ocurrio un error al iniciar sesion.");
      }
    }
  };

  return (
    <Container fluid className="landing-content">
      <Row className="rowPage" style={{ display: "flex", flexWrap: "wrap" }}>
        <Col xs={12} md={12} lg={6} className="columnIntro">
          <img
            src="/images/logos/logo.svg"
            alt="logra-io-logo"
            className="logoPage"
          />
          <p className="component-min-wrapper custom-h4">Hola 😃</p>
          <p className="component-min-wrapper custom-h1">
            Empieza tu registro <br />
            como Experto aquí 👇
          </p>
          <p className="component-min-wrapper product-description">
            ¿Cómo vamos ayudarte a crecer? Te lo explicamos en 3 pasos 👉
          </p>
          <div style={{ width: "100%", padding: 10 }}>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                const userEmail = event.target.userEmail.value;
                const emailTransform = userEmail.toLowerCase();
                const result = validateEmail(userEmail);

                mixpanel.track("Submit form", {
                  name: "Empecemos",
                  stage: "input email",
                });

                if (result) {
                  history.push("/onboarding", { userEmail: emailTransform });
                  history.push("/start/onboarding", {
                    userEmail: emailTransform,
                  });
                } else {
                  setEmailValid(false);
                }
              }}
            >
              <Form.Group>
                <label className="labelInput" htmlFor="userEmail">
                  ¿Cuál es tu correo?
                </label>
                <Form.Control
                  className="inputNewBrand main-btn-align"
                  type="email"
                  placeholder="nombre@ejemplo.com"
                  onChange={(e) => {
                    setEmail(e);
                  }}
                  id="userEmail"
                  name="userEmail"
                />

                {!emailValid && (
                  <div style={{ color: "red", padding: 10 }}>
                    El formato de correo electrónico es inválido
                  </div>
                )}
              </Form.Group>

              {
                <>
                  <Button type="submit" className="btn-style main-btn-align">
                    Empecemos
                  </Button>
                  <div
                    style={{
                      display: error ? "block" : "none",
                      padding: "10px",
                      marginTop: 15,
                      marginBottom: 15,
                      fontWeight: "bold",
                      color: "#CC3333",
                      backgroundColor: "#FFEEEE",
                    }}
                  >
                    Ocurrio un error, Intentelo nuevamente
                  </div>
                  {/* <GoogleLoginBtn submit={handleGoogleLogin} /> */}
                </>
              }
            </form>
          </div>
          <br />
          <br />
          <p className="component-min-wrapper common-text-2">
            ¿Ya tienes una cuenta?
            <br />
            <LograLink href="/start/login">Inicia sesión aquí</LograLink>
            {/* <LograLink href="/login">Inicia sesión aquí</LograLink> */}
          </p>
        </Col>
      </Row>
      <div className="startCover">
        <StartSlider />
      </div>
    </Container>
  );
};

export default StartPage;
