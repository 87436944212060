import React, { useEffect, useState } from 'react'
import { useCallback } from 'react'
//import './ToggleSwitch.css'

export const ToggleSwitchTemp = ({
  enabled = true,
  disabledLabel = 'Incompleta',
  onLabel,
  offLabel,
  activeColor = "#1fab08",
  inactiveColor = "#979797",
  onClick = () => console.log('toggle func'),
  autoToggle
}) => {
  const [toggle, setToggle] = useState(autoToggle)
  const [firstLoad, setFirstLoad] = useState(false)
  // useEffect(() => {
  //   return onClick
  // }, [toggle])

  return (
    <div className='toggle-container' style={{ width: '7rem' }}>
      <button
        onClick={() => { onClick(); setToggle(!toggle) }}
        disabled={!enabled && true}
        className={`toggle-switch ${toggle === true ? 'true' : 'false'} ${!enabled && 'disabled'}`}>
        <div className={`toggle-indicator ${!enabled && 'disabled'} `} style={{ backgroundColor: `${toggle === true && enabled === true ? activeColor : inactiveColor}` }}></div>
      </button >
      {
        enabled ? (
          (onLabel !== undefined && offLabel !== undefined) && <h5 className='toggle-label'>{toggle === true ? onLabel : offLabel}</h5>
        ) : (
          <h5 className='toggle-label'>{disabledLabel}</h5>
        )
      }
    </div >
  )
}
