import React from "react";

const categoriesData = [
  "Estrategia",
  "Equipo",
  "Marketing",
  "Tecnología",
  "Finanzas",
];

export const CategoriesResource = ({ category, setCategory }) => {
  const getCategoryStyle = (category) => {
    switch (category) {
      case "Estrategia":
        return "planning";
      case "Equipo":
        return "team";
      case "Marketing":
        return "marketing";
      case "Tecnología":
        return "tech";
      case "Finanzas":
        return "admi";
      case "Ventas":
        return "marketing";
      default:
        return "";
    }
  };

  return (
    <div className="container-resourceCategories">
      {categoriesData.map((categoryArr, i) => (
        <button
          type="button"
          key={i}
          className={`resource-category ${getCategoryStyle(categoryArr)} ${
            categoryArr === category && "active"
          }`}
          onClick={() => setCategory(categoryArr)}
        >
          <p>{categoryArr}</p>
        </button>
      ))}
    </div>
  );
};
