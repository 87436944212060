import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import { Field, ErrorMessage, Form as FormFormik, Formik } from "formik";
import * as Yup from "yup";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Row, Col, Form } from "react-bootstrap";
import StepsContainer from "./StepsContainer";
import { MainTitle } from "../../assets/styles/pages/general/GeneralStyles";

import api from "../../utils/axiosUsage";
import { getCurrentUser } from "../../utils/auth";
import { TextRich } from "../../Components/tactic/TextRich";

import AccordionEdit from "../../Components/tactic/AccordionEdit";
import FormEditStep from "../../Components/tactic/FormEditStep";
import ModalStep from "../../Components/tactic/ModalStep";
import ToolIcon from "../../assets/images/icons/ToolStep.png";
import LinkIcon from "../../assets/images/icons/LinkStep.png";
import TooltipFile from "../../Components/Atoms/TooltipFile";
import { ModalTemp } from "../../Components/ModalTemp/ModalTemp";
import { Button } from "@logra/component-library";
import { useHistory } from "react-router-dom";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";

const Stage2 = ({
  stage,
  tacticInfo,
  editMode,
  nextStage,
  idTactic,
  setTacticData,
}) => {
  // const history = useHistory();
  const [loggedUser, setLoggedUser] = useState({});
  const [counterStep, setCounterStep] = useState(1);
  const [otherStep, setOtherStep] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [showConffeti, setShowConffeti] = useState(false);
  const { width, height } = useWindowSize();

  const [isEditingStep, setIsEditingStep] = useState({
    active: false,
    step: {},
  });

  const [showModalTool, setShowModalTool] = useState(false);
  const [tools, setTools] = useState([]);
  const [totalTools, setTotalTools] = useState(1000);
  const [isEditingTool, setIsEditingTool] = useState({
    active: false,
    tool: {},
  });
  const history = useHistory();
  const tacticId = 2;

  useEffect(() => {
    (async () => {
      const userData = await getCurrentUser();
      setLoggedUser(userData);
    })();
  }, []);

  const createStep = async (values) => {
    const dataWithoutFile = tools.map((tool) => {
      const { file, step, ...rest } = tool;
      return { ...rest };
    });
    const formData = {
      title: values.title,
      description: values.description,
      hours_to_implement: values.timeApproximate || 0,
      tactic: idTactic?.id,
      objective: values.objective,
      // step_tools: tools,
      step_tools: dataWithoutFile,
    };

    try {
      await api.axiosAuthPost("/cms/api/v2/steps/", loggedUser.token, formData);
      // filterToolWithFiles(data.step_tools);

      setTools([]);
    } catch (error) {
      console.log(error);
    }
  };

  const editTool = (tool) => {
    setShowModalTool(true);
    setIsEditingTool({
      active: true,
      tool,
    });
  };

  const validateTypeTool = (tool) => {
    const fileUpload =
      typeof tool.file === "string" && tool.file.includes("https");
    if (tool.url !== "") {
      return (
        <>
          <a href={tool.url} target="_blank" rel="noreferrer">
            <img src={LinkIcon} alt="Link de la herramienta" />
          </a>
        </>
      );
    } else if (fileUpload) {
      return (
        <>
          <a href={tool.file} target="_blank" rel="noreferrer">
            <img src={LinkIcon} alt="Link de la herramienta" />
          </a>
        </>
      );
    } else if (typeof tool.file == "object") {
      return (
        <TooltipFile id={tool.idFront} msg={tool.file?.name}>
          <img src={LinkIcon} alt="Link de la herramienta" />
        </TooltipFile>
      );
    }
  };

  const validateTitleLength = (title) => {
    if (title.length > 15) {
      return title.slice(0, 15).concat("...");
    } else {
      return title;
    }
  };

  return (
    <>
      <div>
        {!editMode ? (
          <Formik
            enableReinitialize
            initialValues={{
              title: "",
              description: "",
              timeApproximate: "",
              objective: "",
            }}
            onSubmit={(values, actions) => {
              createStep(values);
              if (otherStep) {
                setCounterStep((prev) => prev + 1);
                actions.resetForm();
              } else {
                if (values.action !== "saveDraft") {
                  // nextStage();
                  console.log("enviado");
                  setModalShow(true);
                  setShowConffeti(true);
                }
              }
            }}
            validationSchema={Yup.object({
              title: Yup.string()
                .required("Este campo es obligatorio")
                .max(255, "Máximo 255 caracteres"),
              description: Yup.string().required("Este campo es obligatorio"),
              objective: Yup.string().max(255, "Máximo 255 caracteres"),
              timeApproximate: Yup.number().positive(
                "Debe ser un número válido"
              ),
            })}
          >
            {(formik) => (
              <FormFormik className="container-tactic">
                <ModalTemp
                  title={`🎉 Felicidades ${loggedUser.name} 🎉`}
                  subTitle={`Creaste y publicaste exitosamente esta táctica. ¿Quieres ver cómo se ve? 👌`}
                  disableCloseBtn={true}
                  content={
                    <div
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        textAlign: "center",
                        marginTop: "2rem",
                      }}
                    >
                      <Button
                        label="Ver táctica"
                        primary={true}
                        size={"large"}
                        onClick={() =>
                          history.push(`/tactic/view/${idTactic.id}`)
                        }
                        className={{ color: "white" }}
                      />
                      <br />
                      <button
                        className="modalCongrats-ReturnBtn"
                        onClick={() => history.push("/library")}
                      >
                        Regresar a mi biblioteca
                      </button>
                    </div>
                  }
                  showModal={modalShow}
                />
                <div className="container-tacticForm">
                  <Row className="rowLimit rowWidth rowDashboard">
                    <Col lg={10}>
                      <MainTitle style={{ marginBottom: 50 }}>
                        Creación de táctica
                      </MainTitle>
                    </Col>
                    <Col lg={2}></Col>
                  </Row>
                  <Row className="rowLimit rowWidth rowDashboard">
                    <Col md={10}>
                      <h1 className="number-step-tactic">
                        Paso {counterStep}.
                      </h1>
                      <Form.Group>
                        <label>¿Cuál es el título de este paso?</label>
                        <Field
                          name="title"
                          type="text"
                          className="inputNewBrand main-btn-align form-control"
                          placeholder="Elije un título que deja claro el objetivo"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="frmErrorCategory"
                        />
                      </Form.Group>
                      {/* <Row>
                        <Form.Group as={Col} md={8} lg={5}>
                          <label>Tiempo aproximado (horas)</label>
                          <Field
                            name="timeApproximate"
                            type="number"
                            className="inputNewBrand main-btn-align form-control"
                            placeholder="0 horas"
                          />
                          <ErrorMessage
                            name="timeApproximate"
                            component="div"
                            className="frmErrorCategory"
                          />
                        </Form.Group>
                      </Row>
                      <Form.Group>
                        <label>Objetivo del paso</label>
                        <Field
                          name="objective"
                          type="text"
                          className="inputNewBrand main-btn-align form-control"
                          placeholder="¿Qué se va a lograr?"
                        />
                        <ErrorMessage
                          name="objective"
                          component="div"
                          className="frmErrorCategory"
                        />
                      </Form.Group> */}
                      <Form.Group>
                        <label>Descripción del paso</label>
                        <TextRich
                          step={formik.values.description}
                          change={formik.setFieldValue}
                        />
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="frmErrorCategory"
                        />
                      </Form.Group>

                      {tools.length > 0 && (
                        <>
                          <div className="container-titleTool">
                            <img src={ToolIcon} alt="Herramientas" />
                            <p>Videos, archivos y url agregados</p>
                          </div>
                          {tools.map((tool, i) => (
                            <div
                              className="container-tool"
                              key={i}
                              onClick={() => editTool(tool)}
                            >
                              <TooltipFile id={i} msg={tool.title}>
                                <p>{validateTitleLength(tool.title)}</p>
                              </TooltipFile>
                              <div>
                                <b>{tool.tool_type}</b>

                                {validateTypeTool(tool)}
                              </div>
                            </div>
                          ))}
                        </>
                      )}

                      <p
                        className="tactic-addTool"
                        onClick={() => {
                          setShowModalTool(true);
                          setIsEditingTool({ active: false, tool: {} });
                        }}
                      >
                        + Agregar videos, archivos o url
                      </p>

                      <button
                        type="submit"
                        className="btn-addStep-style"
                        onClick={() => setOtherStep(true)}
                      >
                        + Agregar otro paso
                      </button>
                    </Col>
                  </Row>
                </div>
                <div className="container-tacticBtn">
                  <StepsContainer
                    stage={stage}
                    formik={formik}
                    setOtherStep={setOtherStep}
                    editMode={editMode}
                  />
                </div>
                {/**here it is */}
                {showConffeti && <Confetti width={width} height={height} />}
              </FormFormik>
            )}
          </Formik>
        ) : !isEditingStep.active ? (
          <Formik
            initialValues={{}}
            onSubmit={(values, actions) => {
              // nextStage();
              setModalShow(true);
              setShowConffeti(true);
            }}
          >
            {(formik) => (
              <FormFormik className="container-tactic">
                <ModalTemp
                  title={`🎉 Felicidades ${loggedUser.name} 🎉`}
                  subTitle={`Editaste
                  y publicaste exitosamente esta táctica. ¿Quieres ver cómo se ve? 👌`}
                  disableCloseBtn={true}
                  content={
                    <div
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        textAlign: "center",
                        marginTop: "2rem",
                      }}
                    >
                      <Button
                        label="Ver táctica"
                        primary={true}
                        size={"large"}
                        onClick={
                          () => history.push(`/tactic/view/${idTactic}`)
                        }
                        className={{ color: "white" }}
                      />
                      <br />
                      <button
                        className="modalCongrats-ReturnBtn"
                        onClick={() => history.push("/library")}
                      >
                        Regresar a mi biblioteca
                      </button>
                    </div>
                  }
                  showModal={modalShow}
                />
                <div className="container-tacticForm">
                  <Row className="rowLimit rowWidth rowDashboard">
                    <Col lg={10}>
                      <MainTitle style={{ marginBottom: 50 }}>
                        Lista de pasos
                      </MainTitle>
                    </Col>
                    <Col lg={2}></Col>
                  </Row>
                  <Row className="rowLimit rowWidth rowDashboard">
                    <Col md={10}>
                      {tacticInfo.steps.map((step, index) => (
                        <AccordionEdit
                          key={`tacticStep-${index}`}
                          title={step.title}
                          step={index + 1}
                          stepInfo={step}
                          setIsEditingStep={setIsEditingStep}
                        />
                      ))}
                      <button
                        type="button"
                        className="btn-addStep-style"
                        onClick={() =>
                          setIsEditingStep({
                            active: true,
                            step: {
                              index: tacticInfo.steps.length + 1,
                            },
                            newStep: true,
                          })
                        }
                      >
                        + Agregar otro paso
                      </button>
                    </Col>
                  </Row>
                </div>
                <div className="container-tacticBtn">
                  <StepsContainer
                    stage={stage}
                    formik={formik}
                    editSteps={true}
                    editMode={editMode}
                  />
                </div>
                {showConffeti && <Confetti width={width} height={height} />}
              </FormFormik>
            )}
          </Formik>
        ) : (
          <FormEditStep
            stage={stage}
            isEditingStep={isEditingStep}
            setIsEditingStep={setIsEditingStep}
            nextStage={nextStage}
            setTacticData={setTacticData}
            tacticInfo={tacticInfo}
            loggedUser={loggedUser}
            tools={tools}
          />
        )}
      </div>
      <ModalStep
        isEditingTool={isEditingTool}
        setShowModalTool={setShowModalTool}
        setTools={setTools}
        setTotalTools={setTotalTools}
        showModalTool={showModalTool}
        tools={tools}
        totalTools={totalTools}
        setIsEditingTool={setIsEditingTool}
      />
    </>
  );
};

export default Stage2;
