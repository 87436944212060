import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@logra/component-library";
import Stage1Resource from "../../Components/resource/Stage1Resource";
import Stage2Resource from "../../Components/resource/Stage2Resource";

import { getCurrentUser } from "../../utils/auth";
import api from "../../utils/axiosUsage";
import "../../assets/styles/pages/resources/index.scss";

const CreateResource = () => {
  const [stage, setStage] = useState(1);
  const [loggedUser, setLoggedUser] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [contentResource, setContentResource] = useState({});
  const [idContent, setIdContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUser = async () => {
    try {
      const userData = await getCurrentUser();
      getContent(userData.token);
      setLoggedUser(userData);
    } catch (error) {
      console.log(error);
    }
  };

  const getContent = async (token) => {
    if (id !== undefined) {
      try {
        setIsLoading(true);
        const { data } = await api.axiosAuthGet(
          `/cms/api/v2/indexed-content-crud/${id}/`,
          token
        );
        setContentResource(data);
        setIsEditing(true);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const nextStage = () => {
    setStage(stage + 1);
  };

  const stages = [
    {
      frm: (
        <Stage1Resource
          nextStage={nextStage}
          stage={stage}
          loggedUser={loggedUser}
          setStage={setStage}
          setIdContent={setIdContent}
          contentResource={contentResource}
          isEditing={isEditing}
        />
      ),
    },
    {
      frm: (
        <Stage2Resource
          nextStage={nextStage}
          stage={stage}
          loggedUser={loggedUser}
          idContent={idContent}
          setStage={setStage}
          isEditing={isEditing}
          contentResource={contentResource}
        />
      ),
    },
  ];

  return (
    <div>
      {isLoading ? (
        <div className="container-resourceLoading">
          <div>
            <Loading />
          </div>
        </div>
      ) : (
        stages[stage - 1].frm
      )}
    </div>
  );
};

export default CreateResource;
