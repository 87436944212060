import React, { useReducer } from "react";
import { AuthContext } from "./AuthContext";
import { authReducer } from "./authReducer";

export const AuthProvider = ({ children }) => {
  const [user, dispatch] = useReducer(authReducer, {
    isLogged: false,
    user: "",
  });

  const login = (user) => {
    dispatch({ type: "login", payload: user });
  };
  const logout = () => {
    dispatch({ type: "logout" });
    localStorage.clear();
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
