import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Lodash from "lodash";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Container, Row, Col, Form, Spinner } from "react-bootstrap";

import api from "../../utils/axiosUsage";
import { getCurrentUser } from "../../utils/auth";
import { handleLogin } from "../../utils/auth";
import { LograLink } from "../../assets/styles/pages/general/GeneralStyles";

const FrmAccount = (props) => {
  const history = useHistory();
  const {
    userEmail,
    changeStep,
    step,
    changePersonName,
    editMode,
    profileData,
    setProfileData,
    setAlertMessage,
    setShowAlert,
    is_oauth_account = true,
  } = props;

  const [isDisabled, setIsDisabled] = useState(editMode ? false : true);
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const createNewAccount = async (form) => {
    const name = Lodash.get(form, "firstname");
    const lastname = Lodash.get(form, "lastname");
    const email = userEmail;
    const password = Lodash.get(form, "password");

    const credentials = { name, lastname, email, password };
    if (editMode) {
      setIsProcessing(true);
      setIsDisabled(true);
      const userData = await getCurrentUser();
      const formData = {
        name,
        first_lastname: lastname,
      };
      api
        .axiosAuthPatch(
          `/api/v1/profile-crud/${userData.id}/`,
          userData.token,
          formData
        )
        .then((response) => {
          setShowAlert(true);
          if (response.status === 200) {
            const newProfileData = { ...profileData };
            newProfileData.first_lastname = response.data.first_lastname;
            newProfileData.name = response.data.name;
            setProfileData(newProfileData);

            setAlertMessage(
              "✅ La Información Básica se actualizó correctamente"
            );
            setTimeout(() => {
              setShowAlert(false);
              setAlertMessage("");
              setIsProcessing(false);
              setIsDisabled(false);
            }, 2500);
          } else {
            setAlertMessage("❌ La Información Básica NO pudo ser actualizada");
            setTimeout(() => {
              setShowAlert(false);
              setAlertMessage("");
              setIsProcessing(false);
              setIsDisabled(false);
            }, 2500);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      api
        .axiosPost("/api/v1/signup", credentials)
        .then(async (response) => {
          if (response.status === 201) {
            const loginCredentials = { email, password };
            const signIn = await api.axiosPost(
              "/api/v1/login/",
              loginCredentials
            );
            if (signIn.status === 200) {
              let accountInfo = await api.axiosAuthGet(
                `/api/v1/account`,
                signIn.data.access
              );
              if (accountInfo.status === 200) {
                accountInfo.data.user_token = signIn.data.access;
                accountInfo.data.refreshToken = signIn.data.refresh;
                handleLogin(accountInfo);

                window.localStorage.isAuthenticaded = JSON.stringify(true);
                changePersonName(signIn.data.name);
                changeStep(step + 1);
              } else {
                setError(true);
              }
            } else {
              setError(true);
            }
          } else {
            console.log("El Usuario no pudo ser Creado!");
            setError(true);
          }
        })
        .catch((error) => {
          setError(true);
          console.log(error);
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      firstname: profileData ? profileData.name : "",
      lastname: profileData ? profileData.first_lastname : "",
      password: "",
      passwordConfirm: "",
    },

    validationSchema: editMode
      ? Yup.object({
          firstname: Yup.string().required("Este campo es obligatorio"),
          lastname: Yup.string().required("Este campo es obligatorio"),
        })
      : Yup.object({
          firstname: Yup.string().required("Este campo es obligatorio"),
          lastname: Yup.string().required("Este campo es obligatorio"),
          password: Yup.string()
            .required("Este campo es obligatorio")
            .min(8, "La contraseña requiere 8 caracteres"),
          passwordConfirm: Yup.string()
            .oneOf([Yup.ref("password"), null], "Las contraseñas NO coinciden")
            .required("Este campo es obligatorio"),
        }),

    onSubmit: (values) => {
      createNewAccount(values);
    },
  });

  return (
    <>
      {!editMode && (
        <>
          <h2 className="onSubTitle">Bienvenido en Logra 👋</h2>
        </>
      )}
      {!editMode ? (
        <h1 className="onTitle">Termina de crear tu cuenta.</h1>
      ) : (
        <h1 className="onTitle">Información básica.</h1>
      )}
      <div className="stepBoxForm">
        <form onSubmit={formik.handleSubmit}>
          <Container fluid>
            <Row>
              <Col md={6} style={{ paddingLeft: 8, paddingRight: 0 }}>
                <Form.Group>
                  <label>¿Cuál es tu nombre?</label>
                  <Form.Control
                    id="firstname"
                    name="firstname"
                    className="inputNewBrand main-btn-align"
                    type="text"
                    placeholder="Tu nombre"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstname}
                  />
                </Form.Group>
                {formik.touched.firstname && formik.errors.firstname ? (
                  <div className="frmError">{formik.errors.firstname}</div>
                ) : null}
              </Col>
              <Col md={6} style={{ paddingLeft: 8, paddingRight: 0 }}>
                <Form.Group>
                  <label>¿Y tu apellido?</label>
                  <Form.Control
                    id="lastname"
                    name="lastname"
                    className="inputNewBrand main-btn-align"
                    type="text"
                    placeholder="Tu apellido"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastname}
                  />
                </Form.Group>
                {formik.touched.lastname && formik.errors.lastname ? (
                  <div className="frmError">{formik.errors.lastname}</div>
                ) : null}
              </Col>
            </Row>
            {localStorage.getItem("is_oauth_account") ? (
              <></>
            ) : (
              <>
                <Row>
                  <Col md={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
                    <Form.Group>
                      <label>
                        Elige una contraseña <span>Minimo 8 caracteres</span>
                      </label>
                      <Form.Control
                        id="password"
                        name="password"
                        className="inputNewBrand main-btn-align"
                        type="password"
                        placeholder="Contraseña"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                    </Form.Group>
                    {formik.touched.password && formik.errors.password ? (
                      <div className="frmError">{formik.errors.password}</div>
                    ) : null}
                  </Col>
                </Row>

                <Row>
                  <Col md={12} style={{ paddingLeft: 8, paddingRight: 0 }}>
                    <Form.Group>
                      <label>Confirma tu contraseña</label>
                      <Form.Control
                        id="passwordConfirm"
                        name="passwordConfirm"
                        className="inputNewBrand main-btn-align"
                        type="password"
                        placeholder="Confirma tu contraseña"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.passwordConfirm}
                      />
                    </Form.Group>
                    {formik.touched.passwordConfirm &&
                    formik.errors.passwordConfirm ? (
                      <div className="frmError">
                        {formik.errors.passwordConfirm}
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </>
            )}

            {!editMode && (
              <Form.Group className="mb-3 termAndCondition">
                <Form.Check
                  type="checkbox"
                  onChange={(event) => {
                    setIsChecked(event.currentTarget.checked);
                    isChecked ? setIsDisabled(true) : setIsDisabled(false);
                  }}
                  checked={isChecked}
                />
                <span>
                  Acepto los{" "}
                  <a href="https://logra.io/terminos-y-condiciones">
                    Términos y Condiciones
                  </a>{" "}
                  y el{" "}
                  <a href="https://logra.io/aviso-de-privacidad-de-la-plataforma-logra">
                    Aviso de privacidad
                  </a>
                </span>
              </Form.Group>
            )}
            <Row style={{ marginTop: "20px" }}>
              <Col md={12} style={{ padding: "30px 0px", paddingLeft: 8 }}>
                <div
                  style={{
                    display: error ? "block" : "none",
                    // display: 'block',
                    padding: "10px",
                    // marginLeft: '8px',
                    marginBottom: 15,
                    fontWeight: "bold",
                    color: "#CC3333",
                    backgroundColor: "#FFEEEE",
                  }}
                >
                  {/* {errorMessage} */}
                  El usuario ya está registrado
                </div>
                {/* {error && <LograLink href="/">Regresar al Sitio</LograLink>} */}
                {error && (
                  <LograLink href="/start">Regresar al Sitio</LograLink>
                )}
                <button
                  type="submit"
                  className={isDisabled ? "frmButtonDisabled" : "frmButton"}
                  disabled={isDisabled}
                >
                  {isProcessing ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : !editMode ? (
                    "Continuar"
                  ) : (
                    "Guardar"
                  )}
                </button>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
    </>
  );
};

export default FrmAccount;
